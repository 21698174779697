import React, { useState, useEffect } from 'react';
import {
    Container, Box, Stack, Typography, Grid, Card,
    CardActions,
    CardContent,
    CardMedia,
} from '@mui/material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import list from '../../vt_test_page_header/List';
import '../sass/styles.css';
import ListLoader from '../components/ListLoader';
import { Link } from 'react-router-dom';

function SingleVizLayout({ children, title, id, description, categoryId }) {

    const [related, setRelated] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const filteredList = list.filter(
            item => item.category === categoryId && item.id !== id
        );
        console.log(filteredList);
        setRelated(filteredList);
    }, [categoryId, id]);

    return (
        <div>
            <Header />
            {/* Visualization component */}
            {children}
            {/* End Visualization component */}

            {/* Title & description of the visualization */}
            
            {/* Title & description of the visualization */}


            {/* Results */}
            {/* <Container maxWidth={false} sx={{ mt: 8, mb: 8 }}>
                <Grid container spacing={8}>
                    <Grid item xs={12} md={6} lg={4}>
                        <Box sx={{
                            textAlign: 'left',
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            justifyContent: 'flex-start',
                        }}>
                            <Typography variant="h4" className="header-4" gutterBottom sx={{mb:4}}>
                                Similar Visualizations
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                {loading ? (
                    <ListLoader number={12} />
                ) : (
                    <Grid container spacing={4}>
                        {related.slice(0, 4).map((record) => (
                            <Grid item xs={12} md={6} lg={3} key={record.id}>
                                <Link to={record.path} className='card-link'>
                                    <Card sx={{ height: '100%', boxShadow: 'none' }}>
                                        <CardMedia
                                            sx={{ height: 240 }}
                                            image={record.image}
                                            title={record.name}
                                        />
                                        <CardContent>
                                            <Typography sx={{ textAlign: 'left' }} gutterBottom variant="body" component="p" className='header-4'>
                                                {record.name}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Link>

                            </Grid>
                        ))}
                    </Grid>
                )}
            </Container> */}
            {/* End Results */}
            <Footer />
        </div>
    )
}

export default SingleVizLayout;