import React, { useState, useEffect } from 'react';
import {
    Container, Box, Stack, Typography, Grid, Chip, Card,
    CardContent,
} from '@mui/material';
import { Link } from 'react-router-dom';

import MainLayout from './Layouts/MainLayout';
import ListLoader from './components/ListLoader';

import list from '../vt_test_page_header/List';

function Category() {
    const [searchResults, setSearchResults] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Initially display all items
        setSearchResults(list);
    }, []);

    const handleSearch = (results) => {
        setSearchResults(results);
    };

    const handleCategorySelection = (category) => {
        setSelectedCategory(category);
        setLoading(true);

        setTimeout(() => {
            if (category === 0) {
                // Show all items if category is 0
                setSearchResults(list);
            } else {
                // Filter items by selected category
                const filteredResults = list.filter(item => item.category === category);
                setSearchResults(filteredResults);
            }
            setLoading(false);
        }, 2000); // Simulate a loading delay
    };

    return (
        <div>
            <MainLayout>
                {/* Title section */}
                <Container disableGutters maxWidth={false}>
                    <Stack spacing={2} mt={8} mb={8}>
                        <Box sx={{ width: { xs: '95%', md: '90%', lg: '70%' }, margin: 'auto !important' }}>
                            <Typography variant="h1" className="headerxl" gutterBottom>
                                See the Story in Every Data Point.
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="h2" className="header-3" gutterBottom>
                                A beautiful visual collection of everyday data
                            </Typography>
                        </Box>
                    </Stack>
                </Container>
                {/* End title section */}

                {/* Filter Section */}
                <Stack direction="row" spacing={1} sx={{ justifyContent: 'center' }}>
                    <Chip label="All Vizualizations" variant={selectedCategory === 0 ? "filled" : "outlined"} onClick={() => handleCategorySelection(0)} />
                    <Chip label="Economy" variant={selectedCategory === 1 ? "filled" : "outlined"} onClick={() => handleCategorySelection(1)} />
                    <Chip label="Entertainment" variant={selectedCategory === 2 ? "filled" : "outlined"} onClick={() => handleCategorySelection(2)} />
                    <Chip label="Social Media" variant={selectedCategory === 3 ? "filled" : "outlined"} onClick={() => handleCategorySelection(3)} />
                    <Chip label="Sports" variant={selectedCategory === 4 ? "filled" : "outlined"} onClick={() => handleCategorySelection(4)} />
                    <Chip label="Technology" variant={selectedCategory === 5 ? "filled" : "outlined"} onClick={() => handleCategorySelection(5)} />
                    <Chip label="Other" variant={selectedCategory === 6 ? "filled" : "outlined"} onClick={() => handleCategorySelection(6)} />
                </Stack>
                {/* End Filter Section */}

                {/* Results */}
                <Container maxWidth={false} sx={{ mt: 8, mb: 8 }}>
                    {loading ? (
                        <ListLoader number={12} />
                    ) : (
                        <Grid container spacing={4}>
                            {searchResults.map((record) => (
                                <Grid item xs={12} md={6} lg={3} key={record.id}>
                                    <Link to={record.path} className='card-link'>
                                        <Card sx={{ height: '100%', boxShadow: 'none' }}>
                                            <CardContent>
                                                <div>
                                                    <record.component value={0} />
                                                </div>
                                                <Typography sx={{ textAlign: 'left' }} gutterBottom variant="body" component="p" className='header-link'>
                                                    {record.name}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Link>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </Container>
                {/* End Results */}
            </MainLayout>
        </div>
    );
}

export default Category;