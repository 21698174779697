import * as d3 from 'd3'
import * as fn from './simple_funs.js'
import csvFile from './data1.csv'

export default class Chart {

	constructor(g, rect, t) {
		this.rect = rect
		this.outerg = g
		this.g = g.append('g')
		this.margin = 50
		this.ymax = 10
		this.xmax = 10
		this.data = []
		this.rect.attr('opacity', 0)
		this.totalDuration = t
		this.runState = true
	}

	initiate() {
		console.log("initiate animation")
	}

	async bindData() {
		const data = await d3.csv(csvFile)
		this.data = data.map((d) => ({
			y: d["Country"],
			x: Number(d["Year"]),
			col: Number(d["Inflation"])
		}));
	}

	calculateMinMaxInData() {
		this.xmax = d3.max(this.data, d => d.x)
		this.xmin = d3.min(this.data, d => d.x)
		this.ymax = this.distinctYValues.length
		//this.ymin = d3.min(this.data, d => d.y)
	}

	addHeaders() {
		this.Yheaders = this.outerg
			.append('g').attr('id', 'g_Yheaders')
			.selectAll('text')
			.data(this.distinctYValues)
			.enter()
			.append('text')
			.attr('id', (d, i) => 'headerY' + i)
			.attr('x', this.margin)
			.attr('y', (d, i) => this.yScale(d) + (this.oneRectHeight / 2) + this.margin)
			.text((d, i) => d)
			.attr('fill', 'black')
			.attr('font-size', '12px')
			.attr('alignment-baseline', 'middle')
			.attr('text-anchor', 'right')
			.attr('opacity', 1)

		this.Xheaders = this.outerg
			.append('g').attr('id', 'g_Xheaders')
			.selectAll('text')
			.data(this.distinctXValues)
			.enter()
			.append('text')
			.attr('id', (d, i) => 'headerX' + i)
			.attr('x', (d, i) => this.xScale(d) + (this.oneRectWidth / 2) + this.margin)
			.attr('y', this.margin + 150)
			.text((d, i) => d)
			.attr('fill', 'black')
			.attr('font-size', '12px')
			.attr('alignment-baseline', 'middle')
			.attr('text-anchor', 'middle')
			.attr('opacity', 1)
			.attr("transform", "rotate(-90)")
			.attr("x", -this.margin)
			.attr("y", (d, i) => this.xScale(d) + (this.oneRectWidth / 2) + this.margin);

	}

	getDistinctValues() {
		this.distinctXValues = Array.from(new Set(this.data.map(d => d.x)));
		this.distinctYValues = Array.from(new Set(this.data.map(d => d.y)));
	}

	calculateMaxMinValues() {
		this.maxHeight = parseFloat(this.rect.attr('height')) - (this.margin) * 2
		this.maxWidth = parseFloat(this.rect.attr('width')) - (this.margin)
		this.oneRectWidth = (this.maxWidth / this.distinctXValues.length) * 0.8
		this.oneRectHeight = (this.maxHeight / this.ymax) * 0.8
	}

	getRectangelInfo() {
		this.x = parseFloat(this.rect.attr('x'))
		this.y = parseFloat(this.rect.attr('y'))
		this.rectFillColor = this.rect.attr('fill')
	}

	adjustInngerG() {
		this.outerg.call(fn.translate, this.x, this.y)
	}

	adjustMargin() {
		this.g.call(fn.translate, this.margin, this.margin)

	}

	addAxis() {
		this.yScale = d3.scaleBand().domain(this.data.map((d) => d.y)).range([0, this.maxHeight]).padding(1);

		this.xScale = d3.scaleLinear().domain([this.xmin, this.xmax]).range([0, this.maxWidth]).nice();

	}

	hideAxis() {
		this.gX.attr('opacity', 0)
		this.gY.attr('opacity', 0)
	}

	getRandomData() {
		for (let x = 1; x <= this.xmax; x++) {
			for (let y = 1; y <= this.ymax; y++) {
				const randomColValue = d3.randomInt(10, 100)()
				this.data.push({ 'x': x, 'y': y, 'col': randomColValue });
			}
		}
	}

	createOpacityList() {
		this.opacityList = []

		for (let i = 0; i < this.data.length; i++) {
			var opacityValue = this.data[i].col / 100
			this.opacityList.push(opacityValue)
		}

		this.length = this.opacityList.length
	}

	addColourScale() {
		var colMin = d3.min(this.data, d => d.col)
		var colMax = d3.max(this.data, d => d.col)
		this.colorScale = d3.scaleLinear()
			.domain([colMin, colMax])  // Input domain
			.range(['#BCF1ED', '#FF634D']);
	}

	conditionalColurScale(value) {
		if (value == 0) {
			return 'grey'
		}
		else if (value === "N/A"){
			return 'grey'
		}
		else if (value < 0) {
			return 'yellow'
		}
		else if (value < 10) {
			return 'green'
		}
		else if (value < 500) {
			return 'pink'
		}
		else {
			return 'purple'
		}
	}

	addChart() {

		this.background_rects = this.g
			.append('g').attr('id', 'background_rects')
			.selectAll('rect')
			.data(this.data)
			.enter()
			.append('rect')
			.attr('x', (d, i) => (this.xScale(d.x)))
			.attr('y', (d, i) => (this.yScale(d.y)))
			.attr('height', this.oneRectHeight)
			.attr('width', this.oneRectWidth)
			.attr('stroke-width', 0)
			.attr('opacity', 1)
			.attr('stroke', 'white')
			.attr('fill', (d, i) => this.conditionalColurScale(d.col))//this.colorScale(d.col))
			.attr('class', (d, i) => 'backRectClass' + (Math.floor(i / this.ymax)));

		/*this.rects = this.g
			.append('g').attr('id', 'main_rects')
			.selectAll('rect')
			.data(this.data)
			.enter()
			.append('rect')
			.attr('id', (d, i) => 'rect' + i)
			.attr('x', (d, i) => (this.xScale(d.x)))
			.attr('y', (d, i) => (this.yScale(d.y)))
			.attr('fill', 'white')
			.attr('height', this.oneRectHeight)
			.attr('width', this.oneRectWidth)
			.attr('stroke-width', 0)
			.attr('stroke', 'white')
			.attr('opacity', 1)
			.attr('class', (d, i) => 'class' + (Math.floor(i / this.ymax)));*/

	}

	addLabels() {
		this.labels = this.g
			.append('g').attr('id', 'g_labels')
			.selectAll('text')
			.data(this.data)
			.enter()
			.append('text')
			.attr('id', (d, i) => 'label' + i)
			.attr('x', (d, i) => (this.xScale(d.x) + (this.oneRectWidth / 2)))
			.attr('y', (d, i) => (this.yScale(d.y) + (this.oneRectHeight / 2)))
			.text((d, i) => d.col)
			.attr('fill', 'black')
			.attr('font-size', '8px')
			.attr('alignment-baseline', 'middle')
			.attr('text-anchor', 'middle')
			.attr('opacity', 1)
			.attr('class', (d, i) => 'txtClass' + (Math.floor(i / this.ymax)))
	}

	animateOneColumnOpacity(t, i) {
		var g = this.g
		const rectClass = g.selectAll('.class' + i)
		const textClass = g.selectAll('.txtClass' + i)

		rectClass.call(fn.anim, t, 'opacity', 0)
		textClass.call(fn.anim, t, 'opacity', 1)
	}

	animateOneRect(t, i) {
		console.log('start to animate one rectangle')
		var g = this.g
		const rect = g.selectAll('#rect' + i)

		rect.call(fn.anim, t, 'opacity', 0)
	}

	animateOneColumnWidth(t, i) {
		console.log('start to animate one column width')
		var g = this.g
		const bRectClass = g.selectAll('.backRectClass' + i)
		const textClass = g.selectAll('.txtClass' + i)

		textClass.call(fn.anim, t, 'opacity', 1)
		bRectClass.call(fn.anim, t, 'width', this.oneRectWidth)

	}

	async animateColumnWidth(totalDuration, i) {
		var durationForOneColumn = totalDuration / this.xmax
		if (i === 0) {
			var g1 = this.g.selectAll('#main_rects')
			g1.selectAll('rect').attr('width', 0)

			var g2 = this.g.selectAll('#background_rects')
			g2.selectAll('rect').attr('width', 0)
		}

		if (i < this.xmax) {
			await this.wrapper(this.animateOneColumnWidth, durationForOneColumn, i)
			i = i + 1
			await this.animateColumnWidth(totalDuration, i)
		}
	}

	async animateColumnOpacity(totalDuration, i) {
		var durationForOneColumn = totalDuration / this.xmax
		if (i < this.xmax) {
			await this.wrapper(this.animateOneColumnOpacity, durationForOneColumn, i)
			i = i + 1
			await this.animateColumnOpacity(totalDuration, i)
		}
	}

	async animateRect(totalDuration, i) {
		var durationForOneRect = totalDuration / this.length
		if (i < this.length) {
			await this.wrapper(this.animateOneRect, durationForOneRect, i)
			i = i + 1
			await this.animateRect(totalDuration, i)
		}
	}

	dividedIntoClasses(){		
        for (let i = 1; i < 55; i++) { 
			var x = i
            for (let j = 1; j < 15; j++) {
                var rect = this.g_rects.select('#rect-' + x)
                rect.attr('class', 'col' + i)
                x += 54
            }
        }
	}

	async animateColumnsRandomly(duration, i) {
        var durationForOneColumn = duration / 55
        this.timeoutID = []

        if (i < 55 && this.runState) {
            var rects = this.g_rects.selectAll('.col' + i)
            await this.wrapper(this.animateOneColumnRandomly, durationForOneColumn, rects)
            i += 1
            this.animateColumnsRandomly(duration, i)
        }
    }

	animateOneColumnRandomly(durationForOneColumn, rects) {
        var duration = (durationForOneColumn - (30 * 14)) / 14
		var base = this
        
		var shuffledRects = rects.nodes().sort(function () {
            return Math.random() - 0.5;
        });

        shuffledRects.forEach(function (rect, index) {
            setTimeout(() => {
				if(base.runState){
					d3.select(rect)
                    .transition()
                    .duration(duration)
                    .attr("opacity", 1);
				}
            }, 30 * index);
        });

    }

	move(){
		if(this.runState){
			console.log('running inflation')
			this.animateColumnsRandomly(this.totalDuration, 1)
		}		
	}

	stop(){
		this.runState = false
	}

	reset(){
		this.hideChart()
		this.runState = true
	}

	changeDuration(value){
		this.totalDuration = value
	}


	wrapper(f, t, a) {
		return new Promise((res, rej) => {
			f.call(this, t, a)
			setTimeout(() => { res('done') }, t)
		})
	}

	async seq() {
		await this.wrapper(this.initiate, 100)
		await this.wrapper(this.bindData, 100)
		await this.wrapper(this.getDistinctValues, 100)
		await this.wrapper(this.getRectangelInfo, 100)
		await this.wrapper(this.adjustInngerG, 10)
		//await this.wrapper(this.getRandomData, 100)
		await this.wrapper(this.calculateMinMaxInData, 100)
		await this.wrapper(this.calculateMaxMinValues, 100)
		await this.wrapper(this.createOpacityList, 100)
		await this.wrapper(this.adjustMargin, 100)
		await this.wrapper(this.addAxis, 100)
		await this.wrapper(this.addHeaders, 100)
		await this.wrapper(this.hideAxis, 100)
		await this.wrapper(this.addColourScale, 100)
		await this.wrapper(this.addChart, 100)
		await this.wrapper(this.addLabels, 100)
		await this.animateColumnWidth(this.totalDuration, 0)
	}

	async build() {
		await this.wrapper(this.initiate, 100)
		await this.wrapper(this.bindData, 500)
		await this.wrapper(this.getDistinctValues, 100)
		await this.wrapper(this.getRectangelInfo, 100)
		await this.wrapper(this.adjustInngerG, 10)
		await this.wrapper(this.calculateMinMaxInData, 100)
		await this.wrapper(this.calculateMaxMinValues, 100)
		await this.wrapper(this.adjustMargin, 100)
		await this.wrapper(this.addAxis, 100)
		await this.wrapper(this.addHeaders, 100)
		//await this.wrapper(this.addColourScale, 100)
		await this.wrapper(this.addChart, 100)
		//await this.wrapper(this.addLabels, 100)
	}

	extract(){
		this.g_rects = this.outerg.select('#background_Rects')
		this.dividedIntoClasses()
	}

	hideChart(){
		this.g_rects.selectAll('path').attr('opacity',0)
	}

}