import React from 'react';
import { Container, Typography, Box, Stack } from '@mui/material';
import MainLayout from './Layouts/MainLayout';

function CopyrightNotice() {
    return (
        <MainLayout>
            {/* Copyright Notice Page */}
            <Container disableGutters maxWidth={false} sx={{ mt: 8, mb: 8 }}>
                <Box sx={{ width: { xs: '95%', md: '90%', lg: '70%' }, margin: 'auto' }}>
                    <Stack spacing={3} sx={{ textAlign:'left' }}>

                        {/* Title Section */}
                        <Typography variant="h1" className="header-large" gutterBottom>
                            Copyright Notice
                        </Typography>

                        {/* Section 1 - Copyright Statement */}
                        <Typography variant="body1" gutterBottom>
                            © 2024 Datagraphy Studio. All rights reserved.
                        </Typography>
                        <Typography variant="body1">
                            All content, including but not limited to text, images, graphics, data visualizations, logos, and software, featured on this website, <strong>[Website URL]</strong>, is the property of Datagraphy Studio and is protected by international copyright laws and other intellectual property laws.
                        </Typography>

                        {/* Section 2 - Usage Restrictions */}
                        <Typography variant="body1">
                            No part of this website or its content may be reproduced, distributed, modified, republished, or transmitted in any form or by any means, including photocopying, recording, or other electronic or mechanical methods, without the prior written permission of Datagraphy Studio.
                        </Typography>

                        {/* Section 3 - Permission for Limited Use */}
                        <Typography variant="h2" className="header-3" gutterBottom>
                            Permission for Limited Use
                        </Typography>
                        <Typography variant="body1">
                            Permission may be granted for limited use under the following circumstances:
                        </Typography>
                        <ul>
                            <li><Typography variant="body1">The use is for personal, non-commercial purposes.</Typography></li>
                            <li><Typography variant="body1">Proper credit is given to Datagraphy Studio as the source of the content.</Typography></li>
                            <li><Typography variant="body1">A written request for permission is submitted to and approved by Datagraphy Studio.</Typography></li>
                        </ul>

                        {/* Section 4 - Inquiries */}
                        <Typography variant="h2" className="header-3" gutterBottom>
                            Inquiries
                        </Typography>
                        <Typography variant="body1">
                            For inquiries regarding the use of our content, please contact us at:
                        </Typography>
                        <Typography variant="body1">
                            <strong>Datagraphy Studio</strong><br />
                            <a href="mailto:info@datagraphy.studio">info@datagraphy.studio</a>
                        </Typography>

                        {/* Section 5 - Unauthorized Use */}
                        <Typography variant="body1" gutterBottom>
                            Unauthorized use of the content may result in legal action.
                        </Typography>

                    </Stack>
                </Box>
            </Container>
        </MainLayout>
    );
}

export default CopyrightNotice;
