import FinalPage from "./FinalPage";
import ThumbnailPage from "./ThumbnailPage";

export default function WealthiestFamilies(props) {

	if(props.value == 0) {
		return (
			<section>
			{
				<ThumbnailPage />
			}
			</section>
		)
	} else {
		return (
			<section>
			{
                <FinalPage />
			}
			</section>
		);
	}

}