import * as d3 from 'd3';
import Country from './Country.js';
//import ProgressBar from './progressbarComponent/Chart.js'

export default class Vis {
    constructor(svg, duration, div) {
        this.div = div
        this.svg = svg;
        this.series = d3.range(9); // need to get the count of animatables from the svg
        this.list = [];
        this.totalDuration = duration
        this.runStateForAll = true
    }

    fitToScreen(){
        const height = '75vh'
        const width = '100vw'
        const div = this.div
        const svg = this.svg
    
        div.style('height', height).style('width', width)
        svg.select('rect').remove()
    
        const outer_svg = div.append('svg').attr('id', 'outer_svg')
    
        outer_svg
          .style("height", height)
          .style("width", width);
    
        svg
          .attr("width", '100%')
          .attr("height", '100%');
    
        const g_bkg = svg.select('#g_bkg');
        const rect = g_bkg.select('rect')
        const g_svg = svg.select('#g_svg');
        const g_main = svg.select('#g_main');
    
        outer_svg.append(p => g_bkg.node())
        outer_svg.append(p => g_svg.node())
        svg.append(p => g_main.node())
        g_svg.append(p => svg.node())
        
    
        rect
          .style("height", height)
          .style("width", width);
      }

    build_list() {
        this.series.forEach((j) => {
            const ind = j + 1;
            const country = new Country(this.svg,  ind);

            this.list.push(country);
        });
    }

    hide_all() {
        this.list.forEach((singleCountryObj) => {
            singleCountryObj.hide();
        });
    }

    run(t, i) {
        this.list[i].seq(this.totalDuration);
    }

    wrapper(func, time, arg) {
        return new Promise((res, rej) => {
            func.call(this, time, arg);

            setTimeout(() => {
                res('done');
            }, time);
        });
    }

    async animate(t, i) {
        if (i < this.list.length) {
            await this.wrapper(this.run, 0, i);
            i = i + 1;
            this.animate(t, i);
        }

    }

    async seq() {
        await this.wrapper(this.build_list, 100);
        await this.wrapper(this.animate, this.totalDuration, 0);
        if(this.runStateForAll){
            this.rerun()
          }
    }

    async rerun(){
        await this.wrapper(this.reset, 10000);
        await this.wrapper(this.animate, this.totalDuration, 0);
        if(this.runStateForAll){
            this.rerun()
          }
    }

    stopAll(){
        this.runStateForAll = false
        this.stop()
      }

    stop(){
        this.list.forEach((list)=>{
            list.stop()
        })
    }

    async reset(){
        await this.wrapper(this.stop, 10000);
        this.list.forEach((list)=>{
            list.reset()
        })
    }

    changeDuration(value){
        this.totalDuration = value
    }

   
}