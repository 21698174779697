// Header.js
import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Drawer,
    List,
    ListItem,
    Box,
    Stack,
    useMediaQuery,
    useTheme,
    Button
} from '@mui/material';
import logoImg from '../images/logo.png';
import MenuIcon from '@mui/icons-material/Menu';
import Subscription from '../Subscription';

const Header = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const headerContent = (
        <Toolbar>
            {/* Logo on the left */}

            <Box sx={{ flexGrow: 1, display: 'inline-flex' }}>
                <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                    <a href='/' style={{ display:'flex', alignItems: 'center', textDecoration: 'none' }}>
                        <img src={logoImg} className='header-logo-img' />
                        <Typography variant="h5" className='logo-text' component="div" sx={{ marginLeft: '0px', flexGrow: 1, display: 'inline-flex' }}>
                            Datagraphy
                        </Typography>
                    </a>
                </Stack>
            </Box>


            {/* Links on the right */}
            {!isSmallScreen ? (
                <>
                    <NavLink to="/" className='header-link' activeClassName='active-link'>
                        Home
                    </NavLink>
                    <NavLink to="/collection" className='header-link' activeClassName='active-link'>
                        Our Collection
                    </NavLink>
                    <NavLink to="/services" className='header-link' activeClassName='active-link'>
                        Our Services
                    </NavLink>
                    <NavLink to="/contact" className='header-link' activeClassName='active-link'>
                        Contact Us
                    </NavLink>
                    <Subscription/>
                </>
            ) : (
                // Menu icon for small screens
                <IconButton onClick={handleDrawerOpen} color="inherit">
                    <MenuIcon />
                </IconButton>
            )}
        </Toolbar>
    );

    const drawerContent = (
        <List>
            <ListItem sx={{ justifyContent: 'center' }}>
                <Link to="/collection" className='header-link'>
                    Our Collection
                </Link>
            </ListItem>
            <ListItem sx={{ justifyContent: 'center' }}>
                <Link to="/about" className='header-link'>
                    About Us
                </Link>
            </ListItem>
            <ListItem sx={{ justifyContent: 'center' }}>
                <Link to="/contact" className='header-link'>
                    Contact Us
                </Link>
            </ListItem>
            <ListItem sx={{ justifyContent: 'center' }}>
                <Subscription/>
            </ListItem>
        </List>
    );

    return (
        <div>
            <AppBar color="inherit" position="static" sx={{
                '&.MuiAppBar-root': {
                    color: '#000000',
                    boxShadow: 'none'
                }
            }}>
                {headerContent}
            </AppBar>

            {/* Drawer for small screens */}
            <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerClose}>
                {drawerContent}
            </Drawer>
        </div>
    );
};

export default Header;
