export default function Description() {
    return( 
        <div>  
            <h1 style={{ color: 'black',marginTop: '20px', textAlign: 'left', marginLeft: '35px'}}>World Tallest Buildings</h1>   
            <p style={{ marginLeft: '35px', marginRight: '35px', textAlign: 'left' }}>
            The world's tallest buildings are more than just enormous constructions; they 
            are representations of technological progress, human ambition, and masterful 
            architecture. These buildings are symbols of urban progress and frequently 
            reshape city skylines. These structures, which range from the towering Burj 
            Khalifa in Dubai to the cutting-edge Merdeka 118 in Malaysia, feature state-of-
            the-art engineering and architecture. They frequently include sustainable 
            technologies and multipurpose areas that combine office, residential, and 
            commercial functions. In addition to being functional buildings, these 
            skyscrapers serve as tourist attractions and a reflection of the nation’s cultural 
            and economic ambitions, even beyond their striking heights. Every new building 
            that soars to new heights not only breaks records but also motivates upcoming 
            generations of engineers and architects to keep expanding the realm of what is 
            conceivable.
            </p>  
        </div>     
    )
}