import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import {
    Container, Box, Stack, Typography,Grid
} from '@mui/material';
import MainLayout from './Layouts/MainLayout';
import {useGsapAnimation} from './hooks/useGsapAnimation';

import svgIcon from './images/svg.png';
import jpgIcon from './images/jpg.png';
import pngIcon from './images/png.png';
import movIcon from './images/mov.png';
import mp4Icon from './images/mp4.png';
import aviIcon from './images/avi.png';
import wmvIcon from './images/wmv.png';
import flvIcon from './images/flv.png';

import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import RedditIcon from '@mui/icons-material/Reddit';
import PinterestIcon from '@mui/icons-material/Pinterest';
import logoImg from './images/white-logo.png';
import tiktokImg from './images/tiktok-black.png';
import xImg from './images/x-black.png';
import threadsImg from './images/threads-black.png';

gsap.registerPlugin(ScrollTrigger);

function Services() {

    useGsapAnimation('animate-on-scroll');
    
    const processSteps = [
        "Research & Concept Development",
        "Data search",
        "Design and build",
        "Delivered on desired file format",
    ];

    useEffect(() => {
        processSteps.forEach((_, index) => {
            const timeline = gsap.timeline({
                scrollTrigger: {
                    trigger: `.line-${index}`,
                    start: 'top 75%',
                    end: 'bottom top',
                    scrub: true,
                },
            });

            timeline
                .fromTo(`.line-${index}`, { height: 0 }, { height: '200px', duration: 1 })
                .fromTo(`.circle-${index}`, { scale: 0 }, { scale: 1, duration: 0.5 }, "-=0.5")
                .fromTo(`.step-${index}`, { opacity: 0 }, { opacity: 1, duration: 0.5 });
        });

        // Staggered animation for icons triggered when the container is in the viewport
        gsap.fromTo(
            ".file-format-icon",
            { opacity: 0, y: 50 },
            {
                opacity: 1,
                y: 0,
                duration: 0.8,
                stagger: 0.2,
                ease: "power3.out",
                scrollTrigger: {
                    trigger: ".icon-container",
                    start: "top 85%",
                    toggleActions: "play none none reset",
                }
            }
        );

    }, [processSteps]);

    return (
        <div>
            <MainLayout>
                <Container disableGutters maxWidth={false}>
                    <Stack spacing={2} mt={8} mb={8}>
                        {/* Header Section */}
                        <Box sx={{ width: { xs: '95%', md: '90%', lg: '70%' }, margin: 'auto !important' }}>
                            <Typography variant="h1" className="headerxl" gutterBottom>
                                Our Services
                            </Typography>
                        </Box>

                        {/* Description Section */}
                        <Box sx={{ width: { xs: '95%', md: '90%', lg: '800px' }, margin: 'auto !important' }}>
                            <Typography variant="p" className="body-text" gutterBottom>
                                We specialize in transforming complex data into visually stunning stories, delivering meaningful insights through customized charts and data-driven analytics. From concept development to data search and licensing, we handle everything to ensure your infographic perfectly aligns with your needs. Whether you need a detailed SVG, JPG, PNG, or TIF for static use, or a dynamic MOV, MP4, WMV, AVI, or FLV for motion graphics, we provide the formats that best suit your project. Ideal for data journalists, content creators, and bloggers, our infographics turn raw data into art, making your content both informative and visually captivating.
                            </Typography>
                        </Box>

                        {/* main paragraph */}
                        <Box sx={{ width: { xs: '95%', md: '90%', lg: '70%' }, margin: 'auto !important', marginTop: '64px !important' }}>
                            <Typography variant="h2" className="header-3" gutterBottom>
                                We provide beautiful static or motion graphic design with data visualization built based on your requirement.
                            </Typography>
                        </Box>

                        {/* Our Process */}
                        <Box sx={{ width: { xs: '95%', md: '90%', lg: '70%' }, margin: 'auto !important', marginTop: '64px !important' }}>
                            <Typography variant="h2" className="header-3" gutterBottom>
                                Our Process
                            </Typography>
                        </Box>


                        {/* Process Steps Section */}
                        <Box sx={{ width: '70%', margin: 'auto !important', marginTop: '32px !important' }}>
                            {processSteps.map((step, index) => (
                                <Box key={index} sx={{ position: 'relative', height: '300px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    {/* Line */}
                                    <Box className={`line line-${index}`} sx={{ width: '2px', height: '200px', backgroundColor: '#000' }}></Box>

                                    {/* Circle */}
                                    <Box className={`circle circle-${index}`} sx={{ width: '24px', height: '24px', backgroundColor: '#000', borderRadius: '50%', marginTop: '-20px' }}></Box>

                                    {/* Step Text */}
                                    <Typography variant="h2" className={`step step-${index} header-2`} sx={{ marginTop: '16px', opacity: 0 }}>
                                        {step}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>

                        {/* Icons */}
                        <Box sx={{ width: { xs: '95%', md: '90%', lg: '70%' }, margin: 'auto !important', marginTop: '48px !important' }} className="icon-container">
                            <Stack direction="row" spacing={4} justifyContent="center">
                                <img src={svgIcon} alt="SVG Icon" className="file-format-icon" />
                                <img src={jpgIcon} alt="JPG Icon" className="file-format-icon" />
                                <img src={pngIcon} alt="PNG Icon" className="file-format-icon" />
                                <img src={movIcon} alt="MOV Icon" className="file-format-icon" />
                                <img src={mp4Icon} alt="MP4 Icon" className="file-format-icon" />
                                <img src={aviIcon} alt="AVI Icon" className="file-format-icon" />
                                <img src={wmvIcon} alt="WMV Icon" className="file-format-icon" />
                                <img src={flvIcon} alt="FLV Icon" className="file-format-icon" />
                            </Stack>
                        </Box>


                        {/* User groups */}
                        <Box sx={{ width: { xs: '95%', md: '90%', lg: '70%' }, margin: 'auto !important', marginTop: '64px !important' }}>
                            <Typography variant="h2" className="header-3" gutterBottom>
                                Our infographics are designed to empower data-driven storytelling.
                            </Typography>
                        </Box>
                        <Box sx={{ width: { xs: '95%', md: '90%', lg: '800px' }, margin: 'auto !important' }}>
                            <Typography variant="p" className="body-text" gutterBottom>
                                Data journalists can leverage our service to transform complex data into engaging visuals that captivate audiences and illuminate key trends. Content creators can enhance their material with striking graphics that complement their narratives. Bloggers can boost readership by presenting information in a visually appealing and easily digestible format. By turning data into art, we help you communicate your message effectively, increase audience engagement, and establish yourself as a thought leader in your field.
                            </Typography>
                        </Box>

                    </Stack>

                    {/* Contact */}
                    <Stack spacing={2} mt={8} mb={8}>
                        <Box sx={{ width: { xs: '95%', md: '90%', lg: '70%' }, margin: 'auto !important' }}>
                            <Typography variant="h1" className="headerxl animate-on-scroll" gutterBottom>
                                Let's connect.
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="h2" className="header-3 animate-on-scroll" gutterBottom>
                                Together, let's create something amazing!
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="body" className="body-text animate-on-scroll" gutterBottom>
                                Reach out to us on info@datagraphy.studio
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="body" className="body-text animate-on-scroll" gutterBottom>
                                Checkout our social media channels
                            </Typography>
                        </Box>
                    </Stack>

                    <Grid item lg={4} md={6} xs={12} className='animate-on-scroll'>
                        <Stack direction="row" spacing={{ lg: 2, md: 1 }} sx={{ alignItems: 'center', justifyContent: 'center', mt: { xs: 2, sm: 2, md: 2, lg: 0 } }}>
                            <a className='body-link' href="https://www.youtube.com/channel/UCtdot1Qj3PdymFTgJ50oaig"><YouTubeIcon /></a>
                            <a className='body-link' href="https://web.facebook.com/profile.php?id=61558191435562"> <FacebookIcon /> </a>
                            <a className='body-link' href="https://twitter.com/datagraphyS"><img src={xImg} className='footer-social-img' /></a>
                            <a className='body-link' href="https://www.instagram.com/datagraphystudio/"><InstagramIcon /></a>
                            <a className='body-link' href="https://www.tiktok.com/@datagraphy.studio"> <img src={tiktokImg} className='footer-social-img' /></a>
                            <a className='body-link' href="https://www.linkedin.com/in/datagraphy-studio-764158283/"> <LinkedInIcon /> </a>
                            <a className='body-link' href="https://www.reddit.com/user/datagraphy_studio/"><RedditIcon /></a>
                            <a className='body-link' href="https://www.pinterest.com/datagraphystudio/_created/"><PinterestIcon /></a>
                            <a className='body-link' href="https://www.threads.net/@datagraphy_studio"><img src={threadsImg} className='footer-social-img' /></a>
                        </Stack>
                    </Grid>
                </Container>
            </MainLayout>
        </div>
    );
}

export default Services;
