import { useEffect, useRef } from "react";
import BuildPage from "./BuildPage.js";
import ThumbnailPage from "./ThumbnailPage.js";
import FinalPage from "./FinalPage.js";

export default function TiktokUserGrowth(props) {
	const divRef = useRef();
	if (props.value == 0) {
		return (
			<section>
				{
					<ThumbnailPage />
				}
			</section>
		)
	} else {
		return (
			<section>

				{
					<FinalPage />
				}
			</section>
		);
	}

}