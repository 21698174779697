import * as d3 from 'd3';
import { useEffect, useRef, useState } from "react";
import { ReactComponent as BaseSvg } from "./finalSvg.svg";
import Vis from "./Vis";
import Description from "./Description";
import "./style.css";

export default function FinalPage() {
    const divRef = useRef();
    const [visState, setVisState] = useState(0);
    const [duration, setDuration] = useState(22000);
    const [fitToScreenCalled, setFitToScreenCalled] = useState(false);
    const visRef = useRef(null);

    useEffect(() => {
        draw();

        if (!fitToScreenCalled && visRef.current) {
            visRef.current.fitToScreen();
            setFitToScreenCalled(true);
        }

        return () => {
            if (visRef.current) {
                visRef.current.stopAll();
            }
        };
    }, [visState]);

    function draw() {
        const div = d3.select(divRef.current);
        const svg = div.select('svg');
        visRef.current = new Vis(svg, duration, div);
        visRef.current.seq();
    }

    /*const handleReset = () => {
        setVisState(visState + 1);
    };

    const handleStart = () => {
        if (visRef.current) {
            visRef.current.move();
        }
    };

    const handleInput = (event) => {
        const newDuration = event.target.value;
        setDuration(newDuration);
        if (visRef.current) {
            visRef.current.changeDuration(newDuration);
        }
    };*/

    return (
        <div>
            <div ref={divRef}> <BaseSvg /> </div>
            <div id="div_description">
                <Description />
            </div>
        </div>
    );
}
