import React from 'react';
import MainLayout from './Layouts/MainLayout';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function StyleTest() {
    return (
        <div>
            <MainLayout>

                <Box sx={{ width: '100%', textAlign:'left' }}>
                    <Typography variant="h1" gutterBottom className="headerxl">
                        Datagraphy Studio
                    </Typography>
                    <Typography variant="h1" gutterBottom className="header-large">
                        Datagraphy Studio
                    </Typography>
                    <Typography variant="h2" gutterBottom sx={{ color: 'black', textAlign:'left' }} className="header-1-2">
                        Datagraphy Studio
                    </Typography>
                    <Typography variant="h3" gutterBottom className="header-3">
                        Datagraphy Studio
                    </Typography>
                    <Typography variant="h4" gutterBottom className="header-4">
                        Datagraphy Studio
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                        Datagraphy Studio
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        Datagraphy Studio
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
                        blanditiis tenetur
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>
                        subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
                        blanditiis tenetur
                    </Typography>
                    <Typography variant="body1" gutterBottom className="body-text">
                        body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
                        blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur,
                        neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
                        quasi quidem quibusdam.
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
                        blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur,
                        neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
                        quasi quidem quibusdam.
                    </Typography>
                    <Typography variant="button" display="block" gutterBottom>
                        button text
                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom>
                        caption text
                    </Typography>
                    <Typography variant="overline" display="block" gutterBottom>
                        overline text
                    </Typography>
                </Box>

            </MainLayout>
        </div>
    )
}

export default StyleTest;