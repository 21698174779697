// import * as fn from "../simple_funs";
import * as d3 from "d3";
import * as fn from "./vt_gen_functions/simple_funs.js";
import * as fn_txt from "./vt_gen_functions/text_functions.js";
import lineChart from "./comp_line_chart/Vis.js"

export default class Vis {
  constructor(svg, duration, div) {
    this.div = div
    this.svg = svg;
    this.g = this.svg.select("#g_info");
    this.g_chart = this.svg.select('#g_chart')
    this.g_header = this.svg.select('#g_header')
    this.g_description = this.svg.select('#g_description')
    this.totalDuration = duration
    this.runStateForInfo = true
    this.runStateForChart = true
    this.runStateForCities = true
    this.runStateForAll = true
  }

  fitToScreen(){
    const height = '75vh'
    const width = '100vw'
    const div = this.div
    const svg = this.svg

    div.style('height', height).style('width', width)
    svg.select('rect').remove()

    const outer_svg = div.append('svg').attr('id', 'outer_svg')

    outer_svg
      .style("height", height)
      .style("width", width);

    svg
      .attr("width", '100%')
      .attr("height", '100%');

    const g_bkg = svg.select('#g_bkg');
    const rect = g_bkg.select('rect')
    const g_svg = svg.select('#g_svg');
    const g_main = svg.select('#g_main');

    outer_svg.append(p => g_bkg.node())
    outer_svg.append(p => g_svg.node())
    svg.append(p => g_main.node())
    g_svg.append(p => svg.node())
    

    rect
      .style("height", height)
      .style("width", width);
  }

  build() {
    const g_lineChart = fn.add_g(this.g_chart,"lineChart")
    const lineChartRect = this.g_chart.select('rect')

    var chart = new lineChart(g_lineChart,lineChartRect,0)
    chart.build()
  }

  async move() {
    
    var duration = this.totalDuration / 10

    await this.wrapper(this.initiate, 10);
    await this.wrapper(this.typeHeader, 3300);
    await this.wrapper(this.showPointers, 10);
    if(this.runStateForCities){
      await this.wrapper(this.showCityLines, duration);
      await this.wrapper(this.showCityNames, (2*duration));
    }
    if(this.runStateForChart){
      this.chart.move(duration * 5)
      await this.wrapper(this.animateMapOpacity,(duration * 5) + 500)
    }    
    if(this.runStateForInfo){
      this.showInfo(duration * 2)
    }
  }
 
  extract(){
    this.g_chart.select('#Line').attr('opacity',0)
    var g = this.g_chart.select('#g_lineChart')
    var rect = g.select('rect')
    rect.attr('opacity', 0)
    this.chart = new lineChart(g, rect, this.totalDuration)
    this.chart.seq()

    this.titleEle = this.g_header.select('text').select('tspan')
    this.title = this.titleEle.text()
    this.title = this.title.replace(/&#39;/g, "'");
    this.titleEle.text(this.title)

    this.map = this.svg.select('#Map_Part')
    this.map.attr('opacity',0)

    this.mapOutline = this.svg.select('#Map_Outline')
    this.mapOutline.attr('opacity',1)

    this.cityNames = this.svg.select('#Citiy_Names').selectAll('text')
    this.cityNames.attr('opacity',0)

    this.cityLines = this.svg.select('#Lines').selectAll('path')
    this.cityLines.attr('opacity',0)

    this.cityPointers = this.svg.select('#Pointer_Dots')
    this.cityPointers.attr('opacity',0)

    this.chartIntro = this.g_chart.select('#chart_intro').selectAll('tspan')
    this.cleanChartIntro()
    

    this.inforGroup = this.svg.select('#InfoGroup')
    this.line = this.inforGroup.select('path')
    this.inforGroup.selectAll('rect').attr('opacity',0)
    this.inforGroup.selectAll('tspan').attr('opacity',0)
    this.inforGroup.selectAll('path').attr('opacity',0)

    this.desc = this.g_description.select('text').selectAll('tspan')
  }

  cleanChartIntro(){
    this.chartIntro.each(function () {
      const tspan = d3.select(this);
      const cleanedText = fn_txt.cleanText(tspan.text())
      tspan.text(cleanedText)
      console.log(cleanedText)
    })
  }

  typeHeader(t) {
    this.titleEle.text(this.title)
    this.titleObj = fn_txt.typeTextWithDuration(t , this.titleEle)
  }

  initiate(){
    var textEle = fn.add_text(this.g,'',0,0)
    this.cityObj = fn_txt.typeTextWithDuration(0,textEle)
    this.descTopic = fn_txt.typeTextWithDuration(0,textEle)
    this.descTxt = fn_txt.typeTextWithDuration(0,textEle)
  }


  animateMapOpacity(t){
    this.map
      .transition()
      .duration(t)
      .attr('opacity', 1)
  }

  showMapOutline(){
    this.mapOutline.attr('opacity',1)
  }

  showPointers(){
    this.cityPointers.attr('opacity',1)
  }

  showCityLines(t){
      for (let i = 1; i <= 31;i++){
        this.line = this.svg.select('#l'+i)
        this.line.attr('opacity',1)
        fn.draw_path(this.line,t)
    }   
  }

  showCityNames(t){
    for (let i = 1; i <= 31;i++){
      this.svg.select('#Text_'+i).select('text').attr('opacity',1)
      var city = this.svg.select('#Text_'+i).select('text tspan')
      this.cityObj = fn_txt.typeTextWithDuration(t,city)
  }   
}

async showInfo(t) {

  var duration = t / 4

  this.inforGroup.select('#TopRect').attr('opacity', 1);
  const headerTextEle = this.inforGroup.select('#HeaderText').select('tspan');
  headerTextEle.attr('opacity', 1);
  await this.wrapper(fn_txt.typeTextWithDuration, duration, headerTextEle);

  for (let i = 1; i <= 5; i++) {
    if(this.runStateForInfo){
      const group = this.inforGroup.select(`#TextGroup${i}`);
      group.select(`#Icon${i}`).selectAll('path').attr('opacity', 1);
      group.select(`#Rect${i}`).attr('opacity', 1);
      
      const topic = group.select(`#Topic${i}`).select('tspan');
      topic.attr('opacity', 1);
      await this.wrapper(fn_txt.typeTextWithDuration, 500, topic);
      
      const desc = group.select(`#Des${i}`).selectAll('tspan');
      const durationForOneLine = duration * 4 / desc.size();
      
      for (const tspan of desc.nodes()) {
        if(this.runStateForInfo){
          const tspanSelection = d3.select(tspan);
          tspanSelection.text(tspanSelection.text().replace(/&#39;/g, "'")
                                              .replace(/&#34;/g, '"')
                                              .replace(/&#x2019;/g, "'")
                                              .replace(/&#xa0;|&#10;/g, ''));
          tspanSelection.attr('opacity', 1);
          await this.wrapper( fn_txt.typeTextWithDuration, durationForOneLine, tspanSelection);
        }
      }
    }
  }
}

  wrapper(f, t, a) {
    return new Promise((res, rej) => {
      f.call(this, t, a);
      setTimeout(() => {
        res("done");
      }, t);
    });
  }

  stopAll(){
    this.runStateForAll = false
    this.stop()
  }

  stop() {
    this.line.interrupt()
    if(this.cityObj){
      this.cityObj.stop()
    }
    if(this.titleObj){
      this.titleObj.stop()
    }   
    this.chart.stop()
    this.map.interrupt()
    this.runStateForInfo = false
    this.runStateForChart = false
    this.runStateForCities = false
  }

  async reset() {
    await this.wrapper(this.stop,10000)
    //this.mapOutline.attr('opacity',0)
    this.cityNames.attr('opacity',0)
    this.cityPointers.attr('opacity',0)
    this.cityLines.attr('opacity',0)
    this.inforGroup.selectAll('rect').attr('opacity',0)
    this.inforGroup.selectAll('tspan').attr('opacity',0)
    this.inforGroup.selectAll('path').attr('opacity',0)
    this.map.attr('opacity',0)
    this.g_chart.select('#Line').attr('opacity',0)
    this.chart.hideLabels()

    this.runStateForInfo = true
    this.runStateForChart = true
    this.runStateForCities = true
    
  }

  async rerun() {
    await this.wrapper(this.reset,10000)
    await this.wrapper(this.move,this.totalDuration + 5000)
    if(this.runStateForAll){
      this.rerun()
    }
  }

  async seq(){
    await this.wrapper(this.extract,100)
    await this.wrapper(this.move,this.totalDuration + 15000)
    if(this.runStateForAll){
      this.rerun()
    }
  }

  changeDuration(value){
    this.totalDuration = +value
    this.chart.totalDuration = value
  }
}
