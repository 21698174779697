export default function Description() {
    return( 
        <div>  
            <h1 style={{ color: 'black',marginTop: '20px', textAlign: 'left', marginLeft: '35px'}}>Twitter</h1>   
            <p style={{ marginLeft: '35px', marginRight: '35px', textAlign: 'left' }}>
            Twitter is a popular social media platform that allows users to share short messages, known as tweets, with a global 
            audience. With a character limit of 280 characters per tweet, users can express their thoughts, opinions, and updates on 
            various topics ranging from current events to personal interests. Twitter enables real-time conversations, where users can 
            engage with others by liking, retweeting, or replying to tweets. It's also a platform for news dissemination, networking, 
            and connecting with celebrities, brands, and influencers. Twitter's unique features, such as hashtags and trending topics, 
            facilitate the discovery of relevant content and the formation of online communities.
            Twitter is utilized by individuals and communities worldwide as a platform for sharing thoughts, engaging in conversations,
            and staying informed about local and global events. Different countries have unique patterns of Twitter usage, with some 
            nations embracing it as a primary social media tool for political discourse, activism, and news dissemination, while others 
            use it predominantly for entertainment, networking, or business purposes. Cultural norms, language preferences, and societal 
            trends influence how Twitter is utilized in each country, shaping the types of content shared, the frequency of interactions, 
            and the overall dynamics of the platform within specific regions.
            </p>  
        </div>     
    )
}