export default function Description() {
    return( 
        <div>  
            <h1 style={{ color: 'black',marginTop: '20px', textAlign: 'left', marginLeft: '35px'}}>India and China Population Growth</h1>   
            <p style={{ marginLeft: '35px', marginRight: '35px', textAlign: 'left' }}>
                India and China, the world's most populous nations, are experiencing 
                contrasting demographic trends with significant global implications. While 
                India's population continues to grow, albeit at a slowing rate, China faces its 
                first population decline in decades. Understanding the key factors driving 
                these divergences is crucial for analyzing their future trajectories and 
                potential social and economic consequences. A critical factor is fertility. 
                China's infamous one-child policy (1979-2016) dramatically reduced birth 
                rates, while India's larger, younger population contributed to higher fertility. 
                Despite some relaxation in Chinese policy, cultural preferences and economic 
                pressures still favor smaller families. Conversely, India's fertility decline, 
                though steady, lags behind China due to factors like rural poverty, lower 
                female education, and limited access to family planning services. 
                Improvements in healthcare and living standards have led to declining 
                mortality rates in both countries. However, China's aging population, with a 
                larger share reaching reproductive age during the one-child policy, amplifies 
                the fertility decline's impact. India's younger population mitigates this effect, 
                but ongoing challenges in healthcare access and sanitation create disparities. 
                In conclusion, India and China's diverging population trajectories stem from a 
                complex interplay of historical policies, evolving demographics, and socio-
                economic factors. Understanding these intricate threads is crucial for 
                anticipating their future paths and navigating the global implications of their 
                demographic transitions.
            </p>  
        </div>     
    )
}