import * as d3 from "d3";
import * as fn_txt from './gen_functions/text_functions'

export default class incident {
    constructor(id, svg) {
        this.id = id;
        this.svg = svg;
        this.g = this.svg.select('#g' + this.id);
        this.icon = null;
        this.date = null;
        this.description = null;
        this.runState = true
    }

    hide() {
        this.hideIcon()
        this.hideDot()
        this.hideDate()
        this.hideDiscription()
    }

    hideIcon() {
        this.g.select('#Icon' + this.id).attr('opacity', '0')
    }

    hideDot() {
        this.svg.select('#Dot' + this.id).attr('opacity', '0')
    }

    hideDate() {
        this.g.select('#Date' + this.id).select('tspan').attr('opacity', '0')
    }

    captureDate() {
        //this will capture the text which will goes as inner text 
        this.date_ele = this.g.select('#Date' + this.id).select('tspan')
        this.date_inner = this.g.select('#Date' + this.id).select('tspan').text()

    }

    hideDiscription() {
        this.g.select('#Dis' + this.id).selectAll('text').select('tspan').attr('opacity', 0)
    }

    glowIcon(t) {
        this.icon = this.g.select('#Icon' + this.id)
        this.icon.transition().duration(t).attr('opacity', 1)
    }

    glowDot(t) {
        this.dot = this.svg.select('#Dot' + this.id)
        this.dot.transition().duration(t).attr('opacity', 1)
    }

    glowDate(t) {
        this.date = this.g.select('#Date' + this.id)
        this.date.transition().duration(t).attr('opacity', 1)
    }

    glowDiscription(t) {
        this.dis = this.g.select('#Dis' + this.id)
        this.dis.selectAll('text').select('tspan').transition().duration(t).attr('opacity', 1)
    }

    typeDate(t) {
        var new_txt = ' ';
        var txt = this.date_inner;
        this.date_ele.text('').attr('opacity', 1);
        var l = 0;
        var list = [...txt];
        var t1 = t / list.length

        list.forEach((i, j) => {

            setTimeout(() => {
                new_txt = new_txt + i;
                this.date_ele.text(new_txt);
            }, t1 * j)
        })
    }

    async typeDis(t) {
        var desc = this.g.select('#Dis' + this.id).selectAll('text');
        var timeForOneLine = t / desc.size()
        for (const text of desc.nodes()) {
            if (this.runState) {
                const tspan = d3.select(text).select('tspan');
                tspan.attr('opacity', 1)
                await this.wrapper(this.textLine = fn_txt.typeTextWithDuration, timeForOneLine, tspan);
            }
        }
    }

    async disAnimation(t) {
        this.allDescriptionLines = this.g.select('#Dis' + this.id).selectAll('text');
        var t1 = t / (this.allDescriptionLines.size() + 1);

        for (let i = 1; i <= this.allDescriptionLines.size(); i++) {
            if (this.runState) {
                //await this.wrapper(this.captureDiscription, 10,i)
                await this.wrapper(this.typeDis, t1)
            }
        }
    }

    captureDiscription(t, i) {
        //this will capture the text which will goes as inner text 
        this.dis_ele = this.g.select('#Dis' + this.id + 'Text' + i).select('tspan')
        this.dis_inner = this.g.select('#Dis' + this.id + 'Text' + i).select('tspan').text()
    }

    wrapper(f, t, a) {
        return new Promise((res, rej) => {
            f.call(this, t, a)
            setTimeout(() => { res('done') }, t)
        })
    }

    async seq(t) {

        const remainTime = (t - 10) / 6

        await this.wrapper(this.captureDate, 10)
        await this.wrapper(this.glowIcon, remainTime)
        await this.wrapper(this.typeDate, remainTime)
        await this.wrapper(this.glowDot, remainTime)
        await this.wrapper(this.typeDis, remainTime * 3)
    }

}
