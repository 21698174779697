import * as d3 from 'd3'
import * as fn from './simple_funs.js'

export default class Rect {
    constructor(svg,g,col,row,value,xLocation,yLocation,width,height,max,min) {
        this.svg = svg
        this.g = g
        this.col = col
        this.row = row
        this.id = 'rect'+ this.col + this.row
        this.value = value
        this.xLocation = xLocation
        this.yLocation = yLocation
        this.width = width
        this.height = height
        this.rectClass = 'class'+this.col
        this.colour = 'white'
        this.max = max
        this.min = min
    }

    setColour(){
        if(this.value <= -1 ){
            this.colour = 'rgb(255,99,71)'
        }
        else if(this.value <= 1){
            this.colour = 'rgb(255,255,153)'
        }
        else{
            this.colour = 'rgb(144, 238, 144)'
        }
    }

    setColourFromScale(){
        var colorScale = d3.scaleLinear()
        .domain([this.min, this.max]) // Set the domain to the min and max values in your data
        .range(["blue", "red"]);

        this.colour = colorScale(this.value)
    }


    addRect(){

			this.g.append('rect')
            .attr('id', this.id)
			.attr('x', this.xLocation)
			.attr('y', this.yLocation)
			.attr('fill', this.colour)
			.attr('height', this.height)
			.attr('width', this.width)
			.attr('stroke-width',1 )
			.attr('stroke', 'black')
            .attr('class',this.rectClass)

    }

    wrapper(f, t, a) {
        return new Promise((res, rej) => {
            f.call(this, t, a)
            setTimeout(() => { res('done') }, t)
        })
    }

    async seq(){
        await this.wrapper(this.setColour, 100)
        await this.wrapper(this.addRect, 100)
    }

}