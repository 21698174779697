import * as d3 from "d3";
import * as fn from "./vt_gen_functions/simple_funs.js";
import * as fn_txt from "./vt_gen_functions/text_functions.js";
import lineChart from "./comp_line_chart/Vis.js"
import areaChart from "./comp_area_chart/Chart.js"

export default class Vis {
  constructor(svg, duration, div) {
    this.div = div
    this.svg = svg;
    this.g = this.svg.select("#g_info");
    this.g_chart = this.svg.select('#g_chart')
    this.g_header = this.svg.select('#g_header')
    this.g_description = this.svg.select('#g_description')
    this.totalDuration = duration
    this.runStateForAll = true
  }

  fitToScreen(){
    const height = '75vh'
    const width = '100vw'
    const div = this.div
    const svg = this.svg

    div.style('height', height).style('width', width)
    svg.select('rect').remove()

    const outer_svg = div.append('svg').attr('id', 'outer_svg')

    outer_svg
      .style("height", height)
      .style("width", width);

    svg
      .attr("width", '100%')
      .attr("height", '100%');

    const g_bkg = svg.select('#g_bkg');
    const rect = g_bkg.select('rect')
    const g_svg = svg.select('#g_svg');
    const g_main = svg.select('#g_main');

    outer_svg.append(p => g_bkg.node())
    outer_svg.append(p => g_svg.node())
    svg.append(p => g_main.node())
    g_svg.append(p => svg.node())
    

    rect
      .style("height", height)
      .style("width", width);
  }

  build() {
    const g_areaChart = fn.add_g(this.g_chart, "areaChart")
    const areaChartRect = this.g_chart.select('rect')

    var chart = new areaChart(g_areaChart, areaChartRect, 0)
    chart.build()
  }

  async move() {
    await this.wrapper(this.typeHeader, 3300);
    this.chart.move()
  }


  initiate() {
    console.log("initiate");

  }

  extract() {
    var g = this.g_chart.select('#g_areaChart')
    var rect = g.select('rect')
    rect.attr('opacity', 0)
    this.chart = new areaChart(g, rect, this.totalDuration)
    this.chart.seq()

    this.titleEle = this.g_header.select('text').select('tspan')
    this.title = this.titleEle.text()

    this.desc = this.g_description.select('text').selectAll('tspan')
  }

  typeHeader() {
    fn_txt.typeText(this.titleEle)
  }

  wrapper(f, t, a) {
    return new Promise((res, rej) => {
      f.call(this, t, a);
      setTimeout(() => {
        res("done");
      }, t);
    });
  }

  async seq() {
    await this.wrapper(this.extract, 1000)
    await this.wrapper(this.move,this.totalDuration + 4300)
    if(this.runStateForAll){
      this.rerun()
    }
  }

  stopAll(){
    this.runStateForAll = false
    this.stop()
  }

  stop() {
    this.chart.stop()
  }

  async reset() {
    console.log('reset')
    await this.wrapper(this.stop, 5000)
    this.chart.reset()
  }

  async rerun() {
    await this.wrapper(this.reset, 5000)
    await this.wrapper(this.move,this.totalDuration + 4300)
    if(this.runStateForAll){
      this.rerun()
    }
  }

}