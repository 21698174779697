import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useEffect } from 'react';

gsap.registerPlugin(ScrollTrigger);

export const useGsapAnimation = (className) => {
    useEffect(() => {
        gsap.utils.toArray(`.${className}`).forEach((element) => {
            gsap.fromTo(
                element,
                { y: 100, opacity: 0 },
                {
                    y: 0,
                    opacity: 1,
                    duration: 1,
                    ease: "power4.out",
                    scrollTrigger: {
                        trigger: element,
                        start: "top 90%", // Adjust start position to control when the animation starts
                        end: "bottom 20%", // Adjust end position if needed
                        toggleActions: "play none play none",
                    },
                }
            );
        });
    }, [className]);
};
