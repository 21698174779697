import { BrowserRouter, Route, Routes, Link } from "react-router-dom";
//routes
import list from "../vt_test_page_header/List";
//pages
import ScrollToTop from "./components/ScrollToTop";
import Home from './Home';
import About from './About';
import Category from './Category';
import Contact from './Contact';
import SingleVizLayout from "./Layouts/SingleVizLayout";
import Services from './Services';
import Subscription from './Subscription';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import CopyrightNotice from './CopyrightNotice';
//experimental - delete on production
//styling
import StyleTest from "./StyleTest";

function RouteIndex() {
	return (
		<div >
			<BrowserRouter>
				<ScrollToTop />
				<div>
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/collection" element={<Category />} />
						<Route path="/about" element={<About />} />
						<Route path="/contact" element={<Contact />} />
						<Route path="/services" element={<Services />} />
						<Route path="/Subscribe" element={<Subscription />} />
						<Route path="/privacy" element={<PrivacyPolicy />} />
						<Route path="/terms" element={<TermsOfService />} />
						<Route path="/copyright" element={<CopyrightNotice />} />
						{/* //experimental remove on production */}
						<Route path="/style-test" element={<StyleTest />} />
						{
							list.map((route) => (
								<Route
									key={route.id}
									path={route.path}
									element={<SingleVizLayout
										title={route.name}
										categoryId={route.category}
										id={route.id} >
										<route.component />
									</SingleVizLayout>}
								/>
							))
						}
					</Routes>
				</div>
			</BrowserRouter>

			{/* <div className='header-page-div-footer'> </div> */}
		</div>
	);
}

export default RouteIndex;