// import * as fn from "../simple_funs";
import * as d3 from "d3";
import Incident from "./Incident.js";
import MainLine from "./MainLine.js";
import * as txt_functions from './gen_functions/text_functions'

export default class Vis {
  constructor(svg, duration, div) {
    this.div = div
    this.svg = svg;
    this.g_info = this.svg.select("#g_info");
    this.g = this.svg.select("#g_info");
    this.g_header = this.svg.select("#g_header");
    this.rect = null;
    this.start = [100, 0];
    this.end = [500, 0];
    this.attr = [];
    this.runstate = true;
    this.totalDuration = duration;
    this.incidentList = [];
    this.mainLineList = [];
    this.titleBackgroundBox = null;
    this.title_ele = null;
    this.title_inner = null;
    this.titlex = null;
    this.titley = null;
  }

  fitToScreen(){
    const height = '75vh'
    const width = '100vw'
    const div = this.div
    const svg = this.svg

    div.style('height', height).style('width', width)
    svg.select('rect').remove()

    const outer_svg = div.append('svg').attr('id', 'outer_svg')

    outer_svg
      .style("height", height)
      .style("width", width);

    svg
      .attr("width", '100%')
      .attr("height", '100%');

    const g_bkg = svg.select('#g_bkg');
    const rect = g_bkg.select('rect')
    const g_svg = svg.select('#g_svg');
    const g_main = svg.select('#g_main');

    outer_svg.append(p => g_bkg.node())
    outer_svg.append(p => g_svg.node())
    svg.append(p => g_main.node())
    g_svg.append(p => svg.node())
    

    rect
      .style("height", height)
      .style("width", width);
  }

  cleanText(){
    const details = this.svg.select('#g_info')
    for(let i=0;i<23;i++){
      const paragraph = details.select('#g'+(i+1)).select('#Dis'+(i+1)).selectAll('text').selectAll('tspan')
      paragraph.each(function (d, index){
        const text = d3.select(this)
        text.each(function(d, index) {
          const tspan = d3.select(this);
          const cleanText = txt_functions.cleanText(tspan.text())
          tspan.text(cleanText)
        })
      })
      

  }
}

  extract() {
    this.titleEle = this.g_header.select("#Title").select("tspan");
    this.title = this.titleEle.text();

    // this.desc = this.g.select('#Description').selectAll("text");
    this.desc = this.svg.select("#description").select("#Group_3");
  }

  // Extract all the incidents details from the svg
  build_the_incident_list() {
    this.incidentList = [];

    for (let i = 1; i < 24; i++) {
      var incident = new Incident(i, this.svg);
      this.incidentList.push(incident);
    }
  }

  // Extract all the lines from the svg
  build_the_mainLine_list() {
    this.mainLineList = [];

    for (let i = 1; i < 23; i++) {
      var a = new MainLine(i, this.svg);
      this.mainLineList.push(a);
    }
  }

  // Hide all the incident svgs, mainline svgs and title before anmiate
  hide_all() {
    this.incidentList.forEach((j) => {
      j.hide();
    });
    this.mainLineList.forEach((j) => {
      j.hide();
    });
    // this.hideTitle();
  }

  // Hide all the incident svgs, mainline svgs and title before anmiate
  show_all() {
    this.incidentList.forEach((j) => {
      j.show();
    });
    this.mainLineList.forEach((j) => {
      j.show();
    });
  }

  // Hide the title
  hideTitle() {
    this.hideTitleBackgroundBox();
    this.svg.select("#Title1").select("tspan").attr("opacity", 0);
  }

  hideTitleBackgroundBox() {
    this.titleBackgroundBox = this.svg.select("#TitleRect");
    this.titleBackgroundBox.attr("opacity", 0);
  }

  // Type write the Title
  typeTitle(t) {
    this.captureTitle(10);
    this.calculateTitleLocation(10);
    var t1 = t - 20;
    var new_txt = " ";
    var txt = this.title_inner;
    this.title_ele
      .attr("x", this.titlex)
      .attr("y", this.titley)
      .text("")
      .attr("opacity", 1);
    var l = 0;
    var list = [...txt];
    var t2 = t1 / list.length;

    list.forEach((i, j) => {
      setTimeout(() => {
        new_txt = new_txt + i;
        this.title_ele.text(new_txt);
      }, j * t2);
    });
  }

  // Caputre the Title
  captureTitle() {
    //this will capture the text which will goes as inner text
    this.title_ele = this.svg.select("#Title1").select("tspan");
    this.title_inner = this.svg.select("#Title1").select("tspan").text();
  }

  // Capture Title x, y positions
  calculateTitleLocation() {
    this.titlex = +this.titleBackgroundBox.attr("x");
    this.titley = +this.titleBackgroundBox.attr("y");
  }

  // animate each incident and mainlines
  async animate(t) {
    let i = 0;
    while (i < 23 && this.runstate) {
      var t1 = 500
      var t2 = (this.totalDuration - t1 * 22) / 23;
      await this.wrapper(this.animate_one_incident, t2, i);
      if (i != 22) {
        await this.wrapper(this.animate_one_mainLine_phase, t1, i);
      }
      i++;
    }
  }

  // animate a single incident
  animate_one_incident(t, list_id) {
    this.incidentList[list_id].seq(t);
  }

  // animate a single mainLine
  animate_one_mainLine_phase(t, list_id) {
    this.mainLineList[list_id].seq(t);
  }

  wrapper(f, t, a) {
    return new Promise((res, rej) => {
      f.call(this, t, a);
      setTimeout(() => {
        res("done");
      }, t);
    });
  }

  async seq() {
    //await this.wrapper(this.build_the_incident_list, 10);
    //await this.wrapper(this.build_the_mainLine_list, 10);
    //await this.wrapper(this.hide_all, 10);
    // await this.wrapper(this.typeTitle, 2000);
    //await this.wrapper(this.animate, this.totalDuration);
    this.cleanText()
  }

  // stop animation
  async stop() {
    this.runstate = false;
    this.mainLineList.forEach((line) => {
      line.stop();
    });
  }

  // reset animation
  async reset() {
    await this.stop();
    this.hide_all();
    setTimeout(() => {
      this.show_all();
    }, 1000);
  }

  // rerun animation
  async rerun() {
    await this.stop();
    setTimeout(() => {
      this.runstate = true;
      this.seq();
    }, 1500);
  }

}
