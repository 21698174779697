import * as d3 from 'd3';
import Country from './Country.js';
import ProgressBar from './progressbarComponent/Chart.js'

export default class Vis {
    constructor(svg, duration, div) {
        this.div = div
        this.svg = svg;
        this.series = d3.range(9); // need to get the count of animatables from the svg
        this.list = [];
        this.totalDuration = duration
        this.flag = false
    }

    fitToScreen(){
        const height = '75vh'
        const width = '100vw'
        const div = this.div
        const svg = this.svg
    
        div.style('height', height).style('width', width)
        svg.select('rect').remove()
    
        const outer_svg = div.append('svg').attr('id', 'outer_svg')
    
        outer_svg
          .style("height", height)
          .style("width", width);
    
        svg
          .attr("width", '100%')
          .attr("height", '100%');
    
        const g_bkg = svg.select('#g_bkg');
        const rect = g_bkg.select('rect')
        const g_svg = svg.select('#g_svg');
        const g_main = svg.select('#g_main');
    
        outer_svg.append(p => g_bkg.node())
        outer_svg.append(p => g_svg.node())
        svg.append(p => g_main.node())
        g_svg.append(p => svg.node())
        
    
        rect
          .style("height", height)
          .style("width", width);
      }

    test() {
        //this.build_list()
        //this.hide_all()
        this.list[0].run();
    }

    build_list() {
        this.series.forEach((j) => {
            const ind = j + 1;
            const country = new Country(this.svg, 'g' + ind);

            this.list.push(country);
        });
    }

    hide_all() {
        this.list.forEach((singleCountryObj) => {
            singleCountryObj.hide();
        });
    }

    init_all() {
        this.list.forEach((singleCountryObj) => {
            singleCountryObj.seq();
        });
    }

    addProgressbar() {
        this.progressbarGroup = this.svg.select('#ProgressbarGroup')
        this.progressbarRect = this.progressbarGroup.select('rect')
        this.progressbar = new ProgressBar(this.progressbarGroup, this.progressbarRect, this.totalDuration)
        this.progressbar.seq()
    }

    readdProgressbar() {
        this.progressbar.reseq()
    }

    run(t, i) {
        this.list[i].seq(this.totalDuration);

    }

    replay(t, i) {
        this.list[i].reseq(this.totalDuration)
    }

    wrapper(func, time, arg) {
        return new Promise((res, rej) => {
            func.call(this, time, arg);

            setTimeout(() => {
                res('done');
            }, time);
        });
    }

    async animate(t, i) {
        if (i === 0 && !this.flag) {
            this.addProgressbar()
        }
        if (i < this.list.length && !this.flag) {
            await this.wrapper(this.run, 0, i);
            i = i + 1;
            await this.animate(t, i);
        }

    }

    async reanimate(t, i) {
        if (i === 0 && !this.flag) {
            this.readdProgressbar()
        }
        if (i < this.list.length && !this.flag) {
            await this.wrapper(this.replay, 0, i);
            i = i + 1;
            await this.reanimate(t, i);
        }

    }

    async reset() {
        await this.wrapper(this.stop, 5000)
        this.progressbar.reset()
        this.list.forEach((singleCountryObj) => {
            singleCountryObj.reset();
        });       
        this.flag = false
    }

    async rerun() {
        await this.wrapper(this.reset, 5000)
        await this.wrapper(this.reanimate, this.totalDuration, 0)
        this.rerun()
    }

    stop() {
        this.list.forEach((singleCountryObj) => {
            singleCountryObj.stop();
        });
        this.progressbar.stop()
        this.flag = true
    }

    changeDuration(value) {
        this.totalDuration = value
        this.progressbar.changeDuration(value)
    }

    changeFactor(value) {
        this.list.forEach((singleCountryObj) => {
            singleCountryObj.changeFactor(value);
        });
    }

    async seq() {
        await this.wrapper(this.build_list, 100);
        await this.animate(this.totalDuration, 0);
        this.rerun()
    }

}
