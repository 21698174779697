import * as d3 from "d3";
import * as fn from "./simple_funs.js";
import * as fn_txt from "../gen_functions/text_functions.js";
import * as fn_move from "./move_functions.js";
import csvFile from "./data.csv";
import csvFile2 from "./data2.csv";

export default class Vis {
  constructor(g, rect, totalDuration) {
    this.rect = rect;
    this.outerg = g;
    this.g = g.append("g");
    this.dataPoints = 6;
    this.margin = 50;
    this.data = [];
    this.totalDuration = totalDuration
    this.rect.attr("opacity", 0);
    this.circleRadius = 10;
    this.lblFontSize = 10;
    this.runState = true
    this.runStateForLabels = true
  }

  async bindData() {
    this.st = [];
    this.st2 = [];
    const data = await d3.csv(csvFile);
    const data2 = await d3.csv(csvFile2);
    this.data = data;
    this.data2 = data2;
    this.st = data.map((d) => [Number(d["x"]), Number(d["y"])]);
    this.st2 = data2.map((d) => [Number(d["x"]), Number(d["y"])]);

  }

  addScale() {
    var st = this.st;

    var height = this.maxHeight;
    var width = this.maxWidth;

    //when giving ycale need to give the minimum otherwise there will be a problem
    var ymin = 0;
    var ymax = d3.max(st, (d) => d[1]);
    var xscale = d3
      .scaleLinear()
      .domain(d3.extent(st, (d) => d[0]))
      .range([0, width])
      .nice();
    var yscale = d3
      .scaleLinear()
      .domain([ymin, ymax])
      .range([height, 0])
      .nice();

    //add the axis
    this.gX = this.g
      .append("g")
      .call(d3.axisBottom(xscale))
      .call(fn.translate, 0, this.maxHeight + 5);

    this.gY = this.g
      .append("g")
      .call(d3.axisLeft(yscale.nice()))
      .call(fn.translate, -5, 0);

    this.xscale = xscale;
    this.yscale = yscale;

  }

  addScaleForAnimation() {
    var xAxisStartPoint = fn.lineGetStart(this.xAxis)
    var xAxisEndPoint = fn.lineGetEnd(this.xAxis)
    this.yAxisStartPoint = fn.lineGetStart(this.yAxis)
    var yAxisEndPoint = fn.lineGetEnd(this.yAxis)

    var st = this.st;

    var ymin = 0;
    var ymax = d3.max(st, (d) => d[1]);

    this.xscale = d3
      .scaleLinear()
      .domain(d3.extent(st, (d) => d[0]))
      .range([xAxisStartPoint[0], xAxisEndPoint[0]])
      .nice();

    this.yscale = d3
      .scaleLinear()
      .domain([ymin, ymax])
      .range([this.yAxisStartPoint[1], yAxisEndPoint[1]])
      .nice();
  }


  addLines() {
    var st = this.st;
    var st2 = this.st2;
    var xscale = this.xscale;
    var yscale = this.yscale;
    var height = this.yAxisStartPoint[1]


    var line_three = d3
      .line()
      .x((d) => xscale(d[0]))
      .y((d) => yscale(d[1]))
      .curve(d3.curveMonotoneX);

    var line_four = d3
      .line()
      .x((d) => xscale(d[0]))
      .y((d) => yscale(d[1]))
      .curve(d3.curveMonotoneX);

    var line_five = d3
      .line()
      .x((d) => xscale(d[0]))
      .y((d) => height - 100)
      .curve(d3.curveMonotoneX);

    this.path_three = this.g
      .append("g")
      .append("path")
      .attr("fill", "none")
      .attr("stroke", "red")
      .attr("opacity", 0)
      .attr("stroke-width", 3)
      .datum(st)
      .attr("d", line_three);

    this.path_four = this.g
      .append("path")
      .attr("fill", "none")
      .attr("stroke", "black")
      .attr("opacity", 0)
      .datum(st2)
      .attr("d", line_four);

    this.path_five = this.g
      .append("path")
      .attr("fill", "none")
      .attr("stroke", "black")
      .attr("opacity", 0)
      .datum(st2)
      .attr("d", line_five);
  }

  adjustInngerG() {
    this.outerg.call(fn.translate, this.x, this.y);
  }

  initiate() {
    this.length = this.data.length;
  }

  calculateMinMaxInData() {
    this.xmax = d3.max(this.data, (d) => d.x);
    this.ymax = d3.max(this.data, (d) => d.y);
  }

  adjustMargin() {
    this.g.call(fn.translate, this.margin * 2, this.margin);
  }

  getRectangelInfo() {
    this.x = parseFloat(this.rect.attr("x"));
    this.y = parseFloat(this.rect.attr("y"));
    this.rectFillColor = this.rect.attr("fill");
  }

  calculateMaxMinValues() {
    this.maxHeight = parseFloat(this.rect.attr("height")) - this.margin * 2;
    this.maxWidth = parseFloat(this.rect.attr("width")) - this.margin * 4;
    this.barWidth = (this.maxWidth / this.dataPoints) * 0.8;
  }

  getRandomData() {
    this.data = [];
    for (let i = 0; i <= this.dataPoints; i++) {
      var randomValue =
        Math.floor(Math.random() * ((100 - 10 + 1) / 10)) * 10 + 10;
      this.data.push({ x: 2017 + i, y: randomValue });
    }
    this.st = this.data.map((d) => [Number(d["x"]), Number(d["y"])]);
    console.log(this.st)
    console.log(this.data)
  }

  addLabels() {
    this.labels = this.g
      .append("g")
      .attr("id", "labels")
      .selectAll("text")
      .data(this.st)
      .enter()
      .append("text")
      .attr("id", (d, i) => "text" + i)
      //.attr("x", (d, i) => this.xscale(d.x))
      .attr("x", (d, i) => this.xscale(d3.timeParse("%Y")(d[0])))
      .attr("y", (d, i) => this.yscale(d[1]))
      .attr("fill", "black")
      .attr("font-size", this.lblFontSize)
      .text((d, i) => d[1] + "B")
      .attr("opacity", 1);
  }

  addCircles() {
    this.circle = this.g
      .append("g")
      .attr("id", "circles")
      .selectAll("circle")
      .data(this.st)
      .enter()
      .append("circle")
      .attr("id", (d, i) => "circle" + i)
      .attr("cx", (d, i) => this.xscale(d3.timeParse("%Y")(d[0])))
      .attr("cy", (d, i) => this.yscale(d[1]))
      .attr("fill", "green")
      .attr("r", 8);
  }

  addEndCircle() {
    this.endCircle1 = fn
      .add_circle2(this.g, 0, 0, 8)
      .attr("fill", "#D83F3F")
      .attr('opacity', 0)

    this.endCircle2 = fn
      .add_circle2(this.g, 0, 0, 8)
      .attr("fill", "#195193")
      .attr('opacity', 0)
  }

  animateOneCircle(durationForOneCircle, i) {
    if (i !== this.circles.selectAll('path').size()) {
      this.circles.select("#value" + i).call(
        fn.anim,
        durationForOneCircle,
        "opacity",
        1
      );

      this.circles.select("#point" + i).call(
        fn.anim,
        durationForOneCircle,
        "opacity",
        1
      );
    }
  }

  async animateOneLabel(durationForOneLbl, i) {
    var g = this.labels.select('#detail' + i)


    const lblIcons = g.select('#Icon' + i).selectAll('path')
    lblIcons.transition().duration(100).attr('opacity', 1)

    const desc = g.select("#TextGroup" + i).selectAll('text');
    const durationForOneLine = (durationForOneLbl - 100) / desc.size();

    for (const text of desc.nodes()) {
      if (this.runState) {
        const tspan = d3.select(text).select('tspan');
        tspan.attr('opacity', 1)
        await this.wrapper(this.textLine = fn_txt.typeTextWithDuration, durationForOneLine, tspan);
      }
    }
  }

  async animateCircles(totalDuration, i) {
    var durationForOneCircle = totalDuration / this.circles.selectAll('path').size()
    if (i <= this.circles.selectAll('path').size() && this.runStateForLabels) {
      await this.wrapper(this.animateOneCircle, durationForOneCircle, i);
      i += 1;
      await this.animateCircles(totalDuration, i);
    }
  }

  async animateLabels(totalDuration, i) {
    var durationForOneLbl = totalDuration / this.circles.selectAll('path').size()
    if (i <= this.circles.selectAll('path').size() && this.runStateForLabels) {
      await this.wrapper(this.animateOneLabel, durationForOneLbl, i);
      i += 1;
      await this.animateLabels(totalDuration, i);
    }
  }

  addInvertYscale() {
    this.yAxisStartPoint = fn.lineGetStart(this.yAxis)
    this.yAxisEndPoint = fn.lineGetEnd(this.yAxis)

    var st = this.st;
    var ymax = d3.max(st, (d) => d[1])

    this.yScale = d3
      .scaleLinear()
      .domain([-30, ymax])
      .range([this.yAxisStartPoint[1], this.yAxisEndPoint[1]])
      .nice();
  }

  addCountdownText() {
    this.countdownText1 = fn.add_text(this.outerg, 0, 0)
    this.countdownText1.attr('font-size', '15px').attr('fill', 'black').attr('id', 'countTxt').attr('opacity', 0)

    this.countdownText2 = fn.add_text(this.outerg, 0, 0)
    this.countdownText2.attr('font-size', '15px').attr('fill', 'black').attr('id', 'countTxt').attr('opacity', 0)
  }

  async animatePath(totalDuration,p1Start,p2Start) {
    var yScale = this.yscale
    var xScale = this.xscale
    var base = this

    var l1 = 0
    var l2 = 0
    var lineArray1 = this.lineArray1
    var lineArray2 = this.lineArray2

    var gap_line = this.g_gap.select('#gap_line')
    var gap_text = this.g_gap.select('#gap_text').select('tspan')
    var g_gap = this.g_gap
    g_gap.attr('opacity',1)
    var g_move = this.g_move
    var move_line = this.move_line
    this.moveLine.attr('opacity',1)
    this.g_move.attr('opacity',1)
    

    var txt1 = this.countdownText1
    var txt2 = this.countdownText2
    var endCircle1 = this.endCircle1
    var endCircle2 = this.endCircle2
    
    var path_one = this.path_one
    var path_three = this.path_three;
    var path_five = this.path_five;
    
    var path_two = this.path_two;
    var path_four = this.path_four;
    var path_five = this.path_five;

    this.countdownText1.attr("opacity", 1)
    this.endCircle1.attr("opacity", 1)

    this.countdownText2.attr("opacity", 1)
    this.endCircle2.attr("opacity", 1)

    var ar1 = d3
      .range(path_three.node().getTotalLength() + 100)
      .map((d) => ({ point: path_three.node().getPointAtLength(d) }))
      .map((p) => ({ x: p.point.x, y: p.point.y }));

    var ar2 = d3
      .range(path_four.node().getTotalLength() + 100)
      .map((d) => ({ point: path_four.node().getPointAtLength(d) }))
      .map((p) => ({ x: p.point.x, y: p.point.y }));


    function x_value(ar, val) {
      var bisect = d3.bisector((d) => d.x).left;
      var ind = bisect(ar, val);
      var p = ar[ind];
      return p.x;
    }

    function y_value(ar, xx) {
      var bisect = d3.bisector((d) => d.x).left;
      var ind = bisect(ar, xx);
      var p = ar[ind];
      return p.y;
    }

    function areNumbersClose(num1, num2, tolerance) {
      return Math.abs(num1 - num2) <= tolerance;
    }

    var mt1 = [[x_value(ar1, 0), y_value(ar1, 0)]];
    var mt2 = [[x_value(ar2, 0), y_value(ar2, 0)]];

    
    
    path_one
      .attr('opacity', 1)
      .transition().duration(totalDuration).ease(d3.easeLinear)
      .tween('count_values', () => {
        var path_five_lenght = path_five.node().getTotalLength();
        var r = d3.interpolate(p1Start, path_five_lenght);
        return async function(t) {
          var val = r(t);
          var nx = path_five.node().getPointAtLength(val).x;
          
          var py1 = y_value(ar1, nx);
          var px1 = x_value(ar1, nx);

          var py2 = y_value(ar2, nx);
          var px2 = x_value(ar2, nx);

          if(areNumbersClose(nx, lineArray1[l1], 2)){
             base.line1label(2000,l1)
             l1++;
          }

          gap_line.datum([{x:px1 + 10,y:py1},{x:px2 + 10,y:py2}])
                  .attr(
                    "d",d3
                    .line()
                    .x(d => d.x)
                    .y(d => d.y)
                    .curve(d3.curveMonotoneX)
                  )
                
          fn.translate(g_gap.select('#arrow'),px1 - 117,(((py2-py1)/2+py1)) - 360)
          fn.translate(g_gap.select('#low'),px1 - 119,fn.lineGetEnd(gap_line)[1] - 380)
          fn.translate(g_gap.select('#upper'),px1 - 120,fn.lineGetStart(gap_line)[1] - 343)
          
          var gap = d3.format('.3f')((yScale.invert(py1) - yScale.invert(py2))/1000000000)
          gap_text.text(gap + ' B').attr('x',px1 + 16).attr('y',((py2-py1)/2+py1)+5)

          move_line.datum([{x:px1,y:yScale(0)},{x:px1,y:py2}])
                                     .attr(
                                      "d",d3
                                      .line()
                                      .x(d => d.x)
                                      .y(d => d.y)
                                      .curve(d3.curveMonotoneX)
                                     )
          fn.translate(g_move, px1 - 126,0)
          g_move.select('text tspan').text(d3.format('.0f')(xScale.invert(px1)))
          
          mt1.push([px1, py1]);
          d3.select(this)
            .datum(mt1)
            .attr(
              "d", d3
                .line()
                .x((d) => d[0])
                .y((d) => d[1])
                .curve(d3.curveMonotoneX)
            );

          var yValue = d3.format('.3f')(yScale.invert(py1)/1000000000)
          txt1.text(yValue + ' B').attr('x', px1 + 8).attr('y', py1 - 19)
          endCircle1.attr('cx', px1).attr('cy', py1)
        }
      })

      path_two
      .attr('opacity', 1)
      .transition().duration(totalDuration).ease(d3.easeLinear)
      .tween('count_values', () => {
        var path_five_lenght = path_five.node().getTotalLength();
        var r = d3.interpolate(0, path_five_lenght);
        return function (t) {
          var val = r(t);
          var nx = path_five.node().getPointAtLength(val).x;
          var py = y_value(ar2, nx);
          var px = x_value(ar2, nx);

          if(areNumbersClose(nx, lineArray2[l2], 3)){
            base.line2label(2000,l2)
            l2++
          }

          mt2.push([px, py]);
          d3.select(this)
            .datum(mt2)
            .attr(
              "d", d3
                .line()
                .x((d) => d[0])
                .y((d) => d[1])
                .curve(d3.curveMonotoneX)
            );

          var yValue = d3.format('.3f')(yScale.invert(py) / 1000000000)
          txt2.text(yValue + ' B').attr('x', px + 8).attr('y', py + 35)
          endCircle2.attr('cx', px).attr('cy', py)
        }
      })

  }

  async animate(totalDuration, i) {
    var duration = totalDuration / 4
    await this.wrapper(this.animatePath, duration * 3,0,0);
  }

  changeDuration(value) {
    this.totalDuration = value
  }

  wrapper(f, t, a) {
    return new Promise((res, rej) => {
      f.call(this, t, a);
      setTimeout(() => {
        res("done");
      }, t);
    });
  }

  removRect() {
    this.rect.attr("height", 0).attr("width", 0);
  }

  removeBarsLabels() {
    this.path_one.attr("opacity", 0);
    this.path_two.attr("opacity", 0);
  }

  line1GroupsArray(){
    this.lineArray1 = []
    for(var i = 0; i < 3; i++){
        const x = this.mainline1Groups.select('#line1_circle'+(i+1)).attr('cx')
        this.lineArray1.push(x)
    }
  }

  line2GroupsArray(){
    this.lineArray2 = []
    for(var i = 0; i < 3; i++){
        const x = this.mainline2Groups.select('#line2_circle'+(i+1)).attr('cx')
        this.lineArray2.push(x)
    }
  }

  async line1label(t,i){
    var sideLine = this.mainline1Groups.select('#side_line1_'+(i+1))
    var sideLineHeight = sideLine.attr('height')
    sideLine.attr('height',0)
    sideLine
      .attr('opacity',1)
      .transition().duration(500).ease(d3.easeLinear)
      .attr('height', sideLineHeight)

    
    this.mainline1Groups.select('#line1_icon'+(i+1)).attr('opacity',1)
    this.mainline1Groups.select('#line1_text'+(i+1)).attr('opacity',1)
    this.mainline1Groups.select('#line1_year'+(i+1)).attr('opacity',1)
    this.mainline1Groups.select('#line1_year'+(i+1)).selectAll('tspan').transition().duration(400).delay(400).attr('opacity',1)
    .on('end', async () => {
      var desc = this.mainline1Groups.select('#line1_text' + (i + 1)).selectAll('text');
      var timeForOne = (t - 500) / desc.size();

      // Sequentially process each text element
      for (const text of desc.nodes()) {
        const tspan = d3.select(text).select('tspan');
        tspan.text(tspan.text().replace(/&#39;/g, "'"));
        tspan.text(tspan.text().replace(/&#x2019;/g, "'"));
        tspan.text(tspan.text().replace(/&#10;/g, ""));
        tspan.attr('opacity', 1);
        await this.wrapper(this.textLine = fn_txt.typeTextWithDuration, timeForOne, tspan);
      }
    });
  }

  async line2label(t,i){
    var sideLine = this.mainline2Groups.select('#side_line2_'+(i+1))
    var sideLineHeight = sideLine.attr('height')
    sideLine.attr('height',0)
    sideLine
      .attr('opacity',1)
      .transition().duration(500).ease(d3.easeLinear)
      .attr('height', sideLineHeight)

    this.mainline2Groups.select('#line2_icon'+(i+1)).attr('opacity',1)
    this.mainline2Groups.select('#line2_text'+(i+1)).attr('opacity',1)
    this.mainline2Groups.select('#line2_year'+(i+1)).attr('opacity',1)
    this.mainline2Groups.select('#line2_year'+(i+1)).selectAll('tspan').transition().duration(400).delay(400).attr('opacity',1)
    .on('end', async () => {
    var desc = this.mainline2Groups.select('#line2_text'+(i+1)).selectAll('text')
    var timeForOne = t / desc.size()
    for (const text of desc.nodes()) {
      const tspan = d3.select(text).select('tspan');     
      tspan.text(tspan.text().replace(/&#39;/g, "'"));
      tspan.text(tspan.text().replace(/&#x2019;/g, "'"));
      tspan.attr('opacity', 1)
      await this.wrapper(this.textLine = fn_txt.typeTextWithDuration, timeForOne, tspan);
    }
  });
  }

  async seq() {
    await this.wrapper(this.getRectangelInfo, 10);
    await this.wrapper(this.adjustInngerG, 10);
    await this.wrapper(this.calculateMaxMinValues, 10);
    await this.wrapper(this.adjustMargin, 10);
    await this.wrapper(this.getRandomData, 10)
    //await this.wrapper(this.bindData, 10);
    await this.wrapper(this.initiate, 10);
    await this.wrapper(this.calculateMinMaxInData, 10);
    await this.wrapper(this.removRect, 10);
    await this.wrapper(this.addScale, 10);
    await this.wrapper(this.addLines, 10);
    await this.wrapper(this.addCircles, 10);
    await this.wrapper(this.addEndCircle, 10);
    await this.wrapper(this.addLabels, 10);
    await this.wrapper(this.animate, this.totalDuration, 0);
    //await this.wrapper(this.moveEndCircle, 10)
  }

  async build() {
    await this.wrapper(this.getRectangelInfo, 10);
    await this.wrapper(this.adjustInngerG, 10);
    await this.wrapper(this.calculateMaxMinValues, 10);
    await this.wrapper(this.adjustMargin, 10);
    //await this.wrapper(this.getRandomData, 10)
    await this.wrapper(this.bindData, 10);
    await this.wrapper(this.initiate, 10);
    await this.wrapper(this.calculateMinMaxInData, 10);
    await this.wrapper(this.removRect, 10);
    await this.wrapper(this.addScale, 10);
    await this.wrapper(this.addLines, 10);
  }

  extract() {
    this.path_one = this.outerg.select('#Mainline1').select('path')
    this.path_two = this.outerg.select('#Mainline2').select('path')
    this.yAxis = this.outerg.select('#g_Yaxis').select('path')
    this.xAxis = this.outerg.select('#g_Xaxis').select('path')
    this.xMoveText = this.outerg.select('#move_text')
    this.moveLine = this.outerg.select('#move_line')
    this.moveLine.attr('opacity',0)
    this.g_gap = this.outerg.select('#g_gap')
    this.g_gap.attr('opacity',0)
    this.g_move = this.outerg.select('#g_move_line')
    this.g_move.attr('opacity',0)
    this.move_line = this.outerg.select('#move_line')
    

    this.mainline1Groups = this.outerg.select('#Mainline1').selectAll('g')
    this.mainline2Groups = this.outerg.select('#Mainline2').selectAll('g')

    this.mainline1Groups.selectAll('g').attr('opacity',0)
    this.mainline1Groups.selectAll('rect').attr('opacity',0)
    this.mainline1Groups.selectAll('tspan').attr('opacity',0)
    this.mainline2Groups.selectAll('g').attr('opacity',0)
    this.mainline2Groups.selectAll('rect').attr('opacity',0)
    this.mainline2Groups.selectAll('tspan').attr('opacity',0)


    this.removeBarsLabels()
    this.addCountdownText()
    this.addEndCircle()
    this.line1GroupsArray()
    this.line2GroupsArray()
  }

  async move() {
    var duration = this.totalDuration - 1000
    this.removeBarsLabels()
    await this.wrapper(this.bindData, 500);
    await this.wrapper(this.addScaleForAnimation, 10);
    await this.wrapper(this.addLines, 10);
    await this.wrapper(this.animate, duration, 1);
  }

  stop() {
    this.path_one.interrupt()
    this.path_two.interrupt()
    
  }

  reset(){
    this.path_one.attr('opacity',0)
    this.path_two.attr('opacity',0)
    this.mainline1Groups.selectAll('g').attr('opacity',0)
    this.mainline1Groups.selectAll('tspan').attr('opacity',0)
    this.mainline2Groups.selectAll('g').attr('opacity',0)
    this.mainline2Groups.selectAll('tspan').attr('opacity',0)
    this.countdownText1.attr('opacity',0)
    this.countdownText2.attr('opacity',0)
    this.endCircle1.attr('opacity',0)
    this.endCircle2.attr('opacity',0)
    this.moveLine.attr('opacity',0)
    this.g_gap.attr('opacity',0)
    this.g_move.attr('opacity',0)
    this.mainline1Groups.selectAll('rect').attr('opacity',0)
    this.mainline2Groups.selectAll('rect').attr('opacity',0)
  }


}
