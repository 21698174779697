//import TestPageHome from './comps/vt_test_page_home/Main.js'
//import TestPageHeader from './comps/vt_test_page_header/Main.js'
//import TestPageMain from './comps/vt_test_page_main/Main.js'
import RouteIndex from './comps/Pages/RouteIndex';

import './App.css'

function App() {
  return (
    <div className="App">
	    <RouteIndex/>
    </div>
  );
}

export default App;
