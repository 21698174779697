import React, { useState } from 'react';
import { Modal, Box, TextField, Button, Typography } from '@mui/material';
import axios from 'axios';
import { Link } from 'react-router-dom';
import newsletterImg from './images/newsletter.jpg';

function SubscriptionPopup({ open, onClose }) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState({ name: false, email: false });

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const isNameValid = name.trim() !== '';
        const isEmailValid = validateEmail(email);

        if (!isNameValid || !isEmailValid) {
            setError({
                name: !isNameValid,
                email: !isEmailValid,
            });
            return;
        }

        // Reset errors
        setError({ name: false, email: false });

        const data = {
            service_id: 'service_ajzpniy', // Replace with your service ID
            template_id: 'template_d5xebpn', // Replace with your template ID
            user_id: 'ouIgMkZO3NhfXmXQN', // Replace with your public key
            template_params: {
                to_name: 'Roshan',
                message: `Name : ${name} | Email : ${email}`
            }
        };

        try {
            const response = await axios.post('https://api.emailjs.com/api/v1.0/email/send', data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                setSubmitted(true);
            }
        } catch (error) {
            console.error('Failed to send email:', error);
            // Handle failure case
        }
    };

    const handleClose = () => {
        setName('');
        setEmail('');
        setSubmitted(false);
        onClose();
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: {
                        xs: '90%',
                        md: '60%',
                        lg: '40%'
                    },
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 0, // Remove padding
                }}
            >
                {/* Banner Section */}
                <Box
                    sx={{
                        backgroundImage: `url(${newsletterImg})`, // Replace with your image URL
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: '25vh',
                        maxHeight: '25vh',
                        width: '100%',
                    }}
                ></Box>

                {/* Content Section */}
                <Box sx={{ p: 4 }}>
                    {submitted ? (
                        <Box textAlign="center">
                            <Typography variant="h6" gutterBottom>
                                Thank you for subscribing!
                            </Typography>
                            <Button
                                className='button-outline'
                                variant="outlined"
                                sx={{
                                    border: 'solid 2px black',
                                    textDecoration: 'none',
                                    padding: '8px 32px 8px 32px',
                                    borderRadius: '32px',
                                    minWidth: '100px',
                                    textAlign: 'center',
                                    marginTop: '16px',
                                    color: 'black',
                                    '&:hover': {
                                        border: 'solid 2px black',
                                        color: 'white',
                                        backgroundColor: 'black'
                                    },
                                    mb: 2,
                                }}
                                onClick={handleClose}>
                                Close
                            </Button>
                        </Box>
                    ) : (
                        <form onSubmit={handleSubmit}>
                            <Typography variant="h6" gutterBottom>
                                Subscribe to our Newsletter
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Subscribe to our newsletter and stay updated with the latest news, offers, and insights delivered straight to your inbox!
                            </Typography>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                error={error.name}
                                helperText={error.name && 'Name is required'}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'black',
                                        },
                                    },
                                }}
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                error={error.email}
                                helperText={error.email && 'Enter a valid email address'}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'black',
                                        },
                                    },
                                }}
                            />
                            <Button
                                className='button-outline'
                                type="submit"
                                variant="outlined"
                                sx={{
                                    border: 'solid 2px black',
                                    textDecoration: 'none',
                                    padding: '8px 32px 8px 32px',
                                    borderRadius: '32px',
                                    minWidth: '100px',
                                    textAlign: 'center',
                                    float: 'right',
                                    marginTop: '16px',
                                    color: 'black',
                                    '&:hover': {
                                        border: 'solid 2px black',
                                        color: 'white',
                                        backgroundColor: 'black'
                                    },
                                    mb: 2,
                                }}
                            >
                                Submit
                            </Button>
                        </form>
                    )}
                </Box>
            </Box>
        </Modal>
    );
}

function Subscription() {
    const [isModalOpen, setModalOpen] = useState(false);

    const handleOpenModal = () => setModalOpen(true);
    const handleCloseModal = () => setModalOpen(false);

    return (
        <div>
            <Link className='button-outline' onClick={handleOpenModal}>
                Subscribe
            </Link>
            <SubscriptionPopup open={isModalOpen} onClose={handleCloseModal} />
        </div>
    );
}

export default Subscription;
