import React from 'react';
import { Container, Box, Stack, Typography, Grid } from '@mui/material';
import { Link } from 'react-router-dom';

import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import RedditIcon from '@mui/icons-material/Reddit';
import PinterestIcon from '@mui/icons-material/Pinterest';
import logoImg from '../images/white-logo.png';
import tiktokImg from '../images/tiktok.png';
import xImg from '../images/x.png';
import threadsImg from '../images/threads.png';


function Footer() {
    return (
        <Container disableGutters maxWidth={false} sx={{ mt: 16, pt: 8, pb: 8 }} className='footer' >
            <Grid container sx={{ textAlign: 'left', pl: 3, pr: 3 }}>
                <Grid item xs={12}>
                    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                        <img src={logoImg} className='header-logo-img' />
                        <Typography variant="h4" className="header-4" gutterBottom>
                            Datagraphy
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
            <Grid container sx={{ textAlign: 'left', pl: 3, pr: 3 }}>
                <Grid item lg={4} md={6} xs={12}>
                    <p style={{ maxWidth: '90%' }}>
                     We specialize in transforming complex data into visually stunning stories, delivering meaningful insights through customized charts and data-driven analytics.
                    </p>
                </Grid>
                <Grid item lg={3} md={6} xs={12}>
                    <Link to="/main" className='footer-link'>
                        Our Collection
                    </Link>
                    <Link to="/services" className='footer-link'>
                        Our Services
                    </Link>
                    <Link to="/contact" className='footer-link'>
                        Contact Us
                    </Link>
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                    <Stack direction="row" spacing={2} sx={{ alignItems: 'center', textAlign: 'left', mt: { xs: 2, sm: 2, md: 2, lg: 0 } }}>
                        <a className='footer-link' href="https://www.youtube.com/channel/UCtdot1Qj3PdymFTgJ50oaig"><YouTubeIcon /></a>
                        <a className='footer-link' href="https://web.facebook.com/profile.php?id=61558191435562"> <FacebookIcon /> </a>
                        <a className='footer-link' href="https://twitter.com/datagraphyS"><img src={xImg} className='footer-social-img' /></a>
                        <a className='footer-link' href="https://www.instagram.com/datagraphystudio/"><InstagramIcon /></a>
                        <a className='footer-link' href="https://www.tiktok.com/@datagraphy.studio"> <img src={tiktokImg} className='footer-social-img' /></a>
                        <a className='footer-link' href="https://www.linkedin.com/in/datagraphy-studio-764158283/"> <LinkedInIcon /> </a>
                        <a className='footer-link' href="https://www.reddit.com/user/datagraphy_studio/"><RedditIcon /></a>
                        <a className='footer-link' href="https://www.pinterest.com/datagraphystudio/_created/"><PinterestIcon /></a>
                        <a className='footer-link' href="https://www.threads.net/@datagraphy_studio"><img src={threadsImg} className='footer-social-img' /></a>
                    </Stack>
                </Grid>
            </Grid>
            <Grid container sx={{ textAlign: 'left', pl: 3, pr: 3, mt: 4 }}>
                <Grid item lg={4} md={6} xs={12}>
                    <Typography variant="body" className="" gutterBottom>
                        Datagraphy | 2023
                    </Typography>
                </Grid>
                <Grid item lg={3} md={6} xs={12}>
                    <Link to="/privacy" className='footer-link'>
                        Privacy
                    </Link>
                    <Link to="/terms" className='footer-link'>
                        Terms of Service
                    </Link>
                    <Link to="/copyright" className='footer-link'>
                        Copyright
                    </Link>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Footer;