import * as d3 from 'd3'
import * as fn from './simple_funs.js'

export function adjustSvgToScreen(div) {
	const screenWidth = window.innerWidth;
	const screenHeight = window.innerHeight;

	const svg = div.select('svg').attr('id', 'g');

	const outer_svg = div.append('svg').attr('id', 'outer_svg')
	const g_bkg = svg.select('#g_bkg');
	const rect = g_bkg.select('rect')

	outer_svg
		.attr("width", screenWidth)
		.attr("height", screenHeight * 0.8);

	outer_svg.append(p => g_bkg.node())
	outer_svg.append(p => svg.node())

	rect
		.attr("width", screenWidth)
		.attr("height", screenHeight * 0.8);

	div.select('#g')
		.attr("width", screenWidth)
		.attr("height", screenHeight * 0.8);

	svg
		.attr("width", screenWidth)

	return svg
}

export function fitToWindow(svg) {

	const screenWidth = window.innerWidth;
	const screenHeight = window.innerHeight;
	svg
		.attr("width", screenWidth)
		.attr("height", screenHeight * 0.8);

	return svg
}

export function fitToDiv(div,svg) {
	const width = '300px'
	const height  = '130px'
	div.style('width',width).style('height',height)
	svg.select('rect').remove()

	const outer_svg = div.append('svg').attr('id', 'outer_svg')

	outer_svg
	  .style("height", height)
	  .style("width", width);

	svg
	  .attr("width", '100%')
	  .attr("height", '100%');

	const g_bkg = svg.select('#g_bkg');
	const rect = g_bkg.select('rect')
	const g_svg = svg.select('#g_svg');
	const g_main = svg.select('#g_main');

	outer_svg.append(p => g_bkg.node())
	outer_svg.append(p => g_svg.node())
	svg.append(p => g_main.node())
	g_svg.append(p => svg.node())
	

	rect
	  .style("height", height)
	  .style("width", width);
}
export function fitToDivMain(div, svg) {
	const height = '85vh'
	const width = '100vw'

	div.style('height', height).style('width', width)

	const outer_svg = div.append('svg').attr('id', 'outer_svg')

	outer_svg
		.style("height", height)
		.style("width", width);

	svg
		.attr("width", '100%')
		.attr("height", '100%');

	const g_bkg = svg.select('#g_bkg');
	const rect = g_bkg.select('rect')
	const g_svg = svg.select('#g_svg');
	const g_main = svg.select('#g_main');

	outer_svg.append(p => g_bkg.node())
	outer_svg.append(p => g_svg.node())
	svg.append(p => g_main.node())
	g_svg.append(p => svg.node())
	

	rect
		.style("height", height)
		.style("width", width);

}

export function fitToWindowSvg(div,svg){

	const height = '5vh'
	const width = '100vw'

	div.style('height', height).style('width', width)

	svg
		.attr("width", '100%')
		.attr("height", '100%');

}
