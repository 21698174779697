import React from 'react';
import { Container, Typography, Box, Stack } from '@mui/material';
import MainLayout from './Layouts/MainLayout';

function PrivacyPolicy() {
    return (
        <MainLayout>
            {/* Privacy Policy Page */}
            <Container disableGutters maxWidth={false} sx={{ mt: 8, mb: 8 }}>
                <Box sx={{ width: { xs: '95%', md: '90%', lg: '70%' }, margin: 'auto' }}>
                    <Stack spacing={3} sx={{ textAlign:'left' }}>

                        {/* Title Section */}
                        <Typography variant="h2" className="header-large" gutterBottom>
                            Privacy Policy
                        </Typography>
                        <Typography variant="body1">
                            Effective Date: September 2024
                        </Typography>

                        {/* Section 1 - Introduction */}
                        <Typography variant="h2" className="header-3" gutterBottom>
                            1. Introduction
                        </Typography>
                        <Typography variant="body1">
                            At Datagraphy Studio, we are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website, <strong>datagraphy.studio</strong>, or engage with our services.
                        </Typography>
                        <Typography variant="body1">
                            By using our website, you agree to the terms of this Privacy Policy. If you do not agree, please do not use our website or services.
                        </Typography>

                        {/* Section 2 - Information We Collect */}
                        <Typography variant="h2" className="header-3" gutterBottom>
                            2. Information We Collect
                        </Typography>
                        <Typography variant="body1">
                            We may collect the following types of information:
                        </Typography>
                        <ul>
                            <li><Typography variant="body1">Personal Information: When you subscribe, make a purchase, or contact us, we may collect your name, email address, and payment details.</Typography></li>
                            <li><Typography variant="body1">Usage Data: We collect information about how you interact with our website, such as your IP address, browser type, pages visited, and the time and date of your visit.</Typography></li>
                            <li><Typography variant="body1">Cookies: Our website may use cookies to improve your browsing experience. These small data files help us understand how visitors use our site and provide personalized content.</Typography></li>
                        </ul>

                        {/* Section 3 - How We Use Your Information */}
                        <Typography variant="h2" className="header-3" gutterBottom>
                            3. How We Use Your Information
                        </Typography>
                        <Typography variant="body1">
                            We use the information we collect to:
                        </Typography>
                        <ul>
                            <li><Typography variant="body1">Provide and improve our services, including visualizations, content purchasing, and custom content creation.</Typography></li>
                            <li><Typography variant="body1">Process transactions and send email notifications regarding your purchases or subscriptions.</Typography></li>
                            <li><Typography variant="body1">Communicate with you about updates, promotions, and new services, if you have opted into our newsletter.</Typography></li>
                            <li><Typography variant="body1">Analyze website usage to enhance user experience and our product offerings.</Typography></li>
                        </ul>

                        {/* Section 4 - Sharing Your Information */}
                        <Typography variant="h2" className="header-3" gutterBottom>
                            4. Sharing Your Information
                        </Typography>
                        <Typography variant="body1">
                            We do not sell, trade, or rent your personal information to third parties. We may share your information in the following limited cases:
                        </Typography>
                        <ul>
                            <li><Typography variant="body1">Service Providers: We may share your information with third-party service providers who assist in operating our website and delivering our services (e.g., payment processors).</Typography></li>
                            <li><Typography variant="body1">Legal Compliance: If required by law or in response to legal requests, we may disclose your personal information to protect our rights or comply with a legal obligation.</Typography></li>
                        </ul>

                        {/* Section 5 - Data Security */}
                        <Typography variant="h2" className="header-3" gutterBottom>
                            5. Data Security
                        </Typography>
                        <Typography variant="body1">
                            We take appropriate measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the Internet or electronic storage is 100% secure, so we cannot guarantee its absolute security.
                        </Typography>

                        {/* Section 6 - Your Rights */}
                        <Typography variant="h2" className="header-3" gutterBottom>
                            6. Your Rights
                        </Typography>
                        <Typography variant="body1">
                            You have the following rights regarding your personal information:
                        </Typography>
                        <ul>
                            <li><Typography variant="body1">Access: You can request a copy of the personal information we hold about you.</Typography></li>
                            <li><Typography variant="body1">Correction: You can ask us to update or correct inaccurate information.</Typography></li>
                            <li><Typography variant="body1">Deletion: You can request that we delete your personal data, subject to legal obligations.</Typography></li>
                            <li><Typography variant="body1">Opt-Out: You can opt out of marketing communications by following the unsubscribe instructions included in our emails.</Typography></li>
                        </ul>
                        <Typography variant="body1">
                            To exercise these rights, please contact us at [Email Address].
                        </Typography>

                        {/* Section 7 - Third-Party Links */}
                        <Typography variant="h2" className="header-3" gutterBottom>
                            7. Third-Party Links
                        </Typography>
                        <Typography variant="body1">
                            Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these sites. Please review the privacy policies of any third-party websites you visit.
                        </Typography>

                        {/* Section 8 - Changes to This Policy */}
                        <Typography variant="h2" className="header-3" gutterBottom>
                            8. Changes to This Policy
                        </Typography>
                        <Typography variant="body1">
                            We reserve the right to update this Privacy Policy at any time. Changes will be posted on this page with the "Effective Date" updated accordingly. We encourage you to review this policy periodically for any changes.
                        </Typography>

                        {/* Section 9 - Contact Us */}
                        <Typography variant="h2" className="header-3" gutterBottom>
                            9. Contact Us
                        </Typography>
                        <Typography variant="body1">
                            If you have any questions or concerns about this Privacy Policy, please contact us at:
                        </Typography>
                        <Typography variant="body1">
                            <strong>Datagraphy</strong><br />
                            <a href="mailto:info@datagraphy.studio">info@datagraphy.studio</a>
                        </Typography>
                    </Stack>
                </Box>
            </Container>
        </MainLayout>
    );
}

export default PrivacyPolicy;
