export default function Description() {
    return( 
        <div>  
            <h1 style={{ color: 'black',marginTop: '20px', textAlign: 'left', marginLeft: '35px'}}>Currency Fluctuations in Major Nations: 2020-2022</h1>   
            <p style={{ marginLeft: '35px', marginRight: '35px', textAlign: 'left' }}>
                The period from 2020 to 2022 was marked by significant currency fluctuations in major 
                nations, largely driven by a series of global shocks. These included the COVID-19 
                pandemic and geopolitical events, which led to substantial adjustments in exchange 
                rates. The US dollar, in particular, strengthened against most currencies. This was a 
                reflection of the differences in shock exposure across countries and the pace of 
                monetary tightening. The broad US dollar index has appreciated by around 10% since 
                the beginning of 2022, reaching its highest level in almost four decades. This 
                appreciation of the US dollar had significant macro-financial consequences. It led to a 
                rise in foreign import prices and, coupled with a surge in commodity prices, 
                compounded the impact on inflation. The dollar appreciation also resulted in a 
                tightening of global financial conditions.
            </p>  
        </div>     
    )
}