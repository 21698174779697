export default function Description() {
    return( 
        <div>  
            <h1 style={{ color: 'black',marginTop: '20px', textAlign: 'left', marginLeft: '35px'}}>China's GDP Growth: A Journey from 1960 to 2023</h1>   
            <p style={{ marginLeft: '35px', marginRight: '35px', textAlign: 'left' }}>
            China's Gross Domestic Product (GDP) stands as one of the largest in the world, reflecting the country's immense economic 
            growth and development over recent decades. With a diverse economy spanning manufacturing, technology, services, and 
            agriculture, China has consistently recorded impressive GDP figures, driven by factors such as urbanization, 
            industrialization, and export-led growth. As a key player in the global economy, China's GDP growth rates have significant 
            implications for international trade, investment, and economic stability. While facing challenges such as debt accumulation,
             demographic shifts, and trade tensions, China continues to pursue economic reforms and innovation strategies to sustain 
             its growth trajectory and ensure long-term prosperity. 
            </p>  
        </div>     
    )
}