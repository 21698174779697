export default function Description() {
    return( 
        <div>  
            <h1 style={{ color: 'black',marginTop: '20px', textAlign: 'left', marginLeft: '35px'}}>Twitter's Journey 2022 </h1>   
            <p style={{ marginLeft: '35px', marginRight: '35px', textAlign: 'left' }}>
                Twitter Acquisition by Musk Twitter is a widely used social media 
                platform renowned for its microblogging format, allowing users to share thoughts, opinions, 
                and updates in short, 280-character messages called tweets. Launched in 2006, Twitter has 
                evolved into a global communication tool, connecting millions of users worldwide in real-time 
                conversations on diverse topics ranging from news, politics, entertainment, technology, and 
                more. With features like hashtags, retweets, and mentions, Twitter facilitates engagement and 
                enables users to interact with each other, public figures, brands, and organizations. Its influence 
                extends beyond individual expression, often serving as a catalyst for social movements, 
                breaking news dissemination, and cultural conversations. Elon Musk started acquiring Twitter 
                on January 2022 concluded it in October 2022. The process started with buying the shares and 
                placing the offer to buy Twitter. At the final stage of this process, change company as X. The 
                above timeline clearly explains the major incidents that drastically changed Twitter, one of the 
                social media giants. 
            </p>  
        </div>     
    )
}