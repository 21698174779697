import * as d3 from 'd3';
import { useEffect, useRef, useState } from "react";
//import { ReactComponent as BaseSvg } from "./svg.svg";
import NvidiaGIF from './nvidia.gif'
import Vis from "./Vis";
import Description from "./Description";
//import "./style.css";

export default function FinalPage() {
    const divRef = useRef();
    const [visState, setVisState] = useState(0);
    const [duration, setDuration] = useState(17000);
    const [fitToScreenCalled, setFitToScreenCalled] = useState(false);
    const visRef = useRef(null);

    useEffect(() => {
        draw();

        if (!fitToScreenCalled && visRef.current) {
            visRef.current.fitToScreen();
           // setFitToScreenCalled(true);
        }

        return () => {
            if (visRef.current) {
                //visRef.current.stop();
            }
        };
    }, [visState]);

    function draw() {
        const div = d3.select(divRef.current);
        //const svg = div.select('svg');
        //visRef.current = new Vis(svg, div);
        //visRef.current.seq();
    }

    return (
        <div>
            <div ref={divRef} style={{width: '100vw',height: '75vh',overflow: 'hidden',backgroundColor: 'white'}}> 
                <img src={NvidiaGIF} alt="Nvidia GIF" style={{ width: '100%',height: '100%',objectFit: 'contain'}}/>
            </div>
            {/*<div id="div_button" className="div_button">
                <button onClick={handleReset}>Reset</button>
                <button onClick={handleStart}>Start</button>
                <label>Duration: </label>
                <input
                    type="number"
                    id="input_value"
                    min="15000"
                    max="30000"
                    step="1000"
                    value={duration}
                    onChange={handleInput}
                />
            </div>*/}
            <div id="div_description">
                <Description />
            </div>
        </div>
    );
}
