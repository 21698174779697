// import * as fn from "../simple_funs";
import * as d3 from "d3";
import * as txt_functions from './gen_functions/text_functions'

export default class Vis {
  constructor(svg, div) {
    this.div = div
    this.svg = svg;
  }

  fitToScreen(){
    const height = '75vh'
    const width = '100vw'
    const div = this.div
    const svg = this.svg

    div.style('height', height).style('width', width)
    svg.select('rect').remove()

    const outer_svg = div.append('svg').attr('id', 'outer_svg')

    outer_svg
      .style("height", height)
      .style("width", width);

    svg
      .attr("width", '100%')
      .attr("height", '100%');

    const g_bkg = svg.select('#g_bkg');
    const rect = g_bkg.select('rect')
    const g_svg = svg.select('#g_svg');
    const g_main = svg.select('#g_main');

    outer_svg.append(p => g_bkg.node())
    outer_svg.append(p => g_svg.node())
    svg.append(p => g_main.node())
    g_svg.append(p => svg.node())
    

    rect
      .style("height", height)
      .style("width", width);
  }  
}
