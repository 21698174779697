import * as d3 from "d3";
import * as fn from "./gen_functions/simple_funs.js";
import * as fn_txt from "./gen_functions/text_functions.js";
import Chart from "./comp_heat_map/Chart.js";

export default class Vis {
  constructor(svg, duration, div) {
    this.div = div
    this.svg = svg;
    this.g = this.svg.select("#g_chart2");
    this.g_header = this.svg.select("#g_header");
    this.g_description = this.svg.select("#g_description");
    this.totalDuration = duration;
    this.runState = true;
    this.title = null;
    this.desc = null;
    this.runStateForAll = true
  }

  fitToScreen(){
    const height = '75vh'
    const width = '100vw'
    const div = this.div
    const svg = this.svg

    div.style('height', height).style('width', width)
    svg.select('rect').remove()

    const outer_svg = div.append('svg').attr('id', 'outer_svg')

    outer_svg
      .style("height", height)
      .style("width", width);

    svg
      .attr("width", '100%')
      .attr("height", '100%');

    const g_bkg = svg.select('#g_bkg');
    const rect = g_bkg.select('rect')
    const g_svg = svg.select('#g_svg');
    const g_main = svg.select('#g_main');

    outer_svg.append(p => g_bkg.node())
    outer_svg.append(p => g_svg.node())
    svg.append(p => g_main.node())
    g_svg.append(p => svg.node())
    

    rect
      .style("height", height)
      .style("width", width);
  }

  build() {
    var g = fn.add_g(this.g, "heatMap");
    var rect = this.g.select("rect");

    var chart = new Chart(g, rect);
    chart.build();
  }

  extract() {
    this.titleEle = this.g_header.select("text").select("tspan");
    this.title = this.titleEle.text();

    var rect = this.g.select("rect");
    this.chart = new Chart(this.g, rect, this.totalDuration - 2000);
    this.chart.extract();
  }

  hideAll(){
    this.chart.hideChart()
  }

  async move() {
    await this.wrapper(this.typeHeader, 2000);
    if (this.runState) {
      this.chart.move();
    }
  }

  typeHeader() {
    this.titleEle.text(this.title);
    this.typeObj = fn_txt.typeTextWithDuration(2000, this.titleEle);
  }

  stopAll(){
    this.runStateForAll = false
    this.stop()
  }

  stop(){
    this.runState = false
    this.chart.stop()
  }

  async reset(){
    await this.wrapper(this.stop, 10000);
    this.runState = true
    this.chart.reset()
  }

  async rerun(){
    await this.wrapper(this.reset, 10000);
    await this.wrapper(this.move, this.totalDuration);
    if(this.runStateForAll){
      this.rerun()
    }
  }

  changeDuration(value){
    this.chart.changeDuration(value)
  }

  wrapper(f, t, a) {
    return new Promise((res, rej) => {
      f.call(this, t, a);
      setTimeout(() => {
        res("done");
      }, t);
    });
  }

  async seq() {
    await this.wrapper(this.extract, 100);
    await this.wrapper(this.hideAll, 100);
    await this.wrapper(this.move, this.totalDuration);
    if(this.runStateForAll){
      this.rerun()
    }
  }
}
