import * as fn from './simple_funs.js'

export default class MainLine {

    constructor(id,svg) {
        this.id = id
        this.svg = svg
    }

    hide(){
        this.getPath()
        this.path.attr('opacity','0')
    }

    getPath() {
        this.path = this.svg.select('#Mainline' + this.id)
    }

    drawLine(t){
        fn.draw_path(this.path,t)
        this.path.attr('opacity',1)
    }

    wrapper(f,t,a){
		return new Promise((res,rej)=>{ 
		f.call(this,t,a)
		setTimeout(()=>{ res('done') } , t ) }) 
	}

    async seq(t) {
        await this.wrapper(this.drawLine,t)
    }

    stop(){
        this.path.interrupt()
    }
}