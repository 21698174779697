import * as d3 from "d3";

export function typeText(textEle){
	var new_txt = '';
    const innerText = textEle.text()
    textEle.text('');
		var l = 0;
		var list = [...innerText];

		list.forEach((i, j) => {

			setTimeout(() => {
				new_txt = new_txt + i;
				textEle.text(new_txt);
			}, 100 * j)
		})

}

export function countUp(duration, num, elementToUpdate , specialChr) {
	const f = duration / (num + 1);
	var p = 0;
	var runState = true
  
	const intervalId = setInterval(() => {
	  if (p >= num || !runState) {
		clearInterval(intervalId);
		return;
	  }
	  p = p + 1;
	  elementToUpdate.text(p + specialChr);
	}, f);
  
	return {
	  stopCount() {
		runState = false;
	  }
	};
  }