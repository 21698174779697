export default function Description() {
    return( 
        <div>  
            <h1 style={{ color: 'black',marginTop: '20px', textAlign: 'left', marginLeft: '35px'}}>Apple Stock Movements</h1>   
            <p style={{ marginLeft: '35px', marginRight: '35px', textAlign: 'left' }}>
            Apple's stock has seen a significant upward climb, fueled by successful product launches like the iPhone and strong brand loyalty. New product releases, iPhone sales (their biggest revenue generator), and the growth of their service sector all significantly impact the stock price. A company's financial health, product launches, and future prospects significantly impact its stock price. Positive news often leads to price increases, while negative news can trigger a sell-off and decrease the price. Broad economic factors like interest rates, inflation, and recessions can affect all stocks, including Apple. A strong economy generally lifts stock prices, while a weak one can cause them to decline.
            </p>
             
        </div>     
    )
}