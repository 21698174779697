import * as d3 from "d3";

export function moveWithOjbect(
  g_base,
  g_usedForMove,
  proX,
  propY,
  scaleX,
  scaleY
) {
  //this funciton make sure that the given object will move  with the element
  // baseElement = this will be the base element to move with
  // gBase  = this will be the g wich is included in the base
  // moveObject = obejct that will move with  the base element
  // baesProp   = will property will use to move with the base element
  // g_UsedForMove = this g will use for the movement
}

export function translate(selection, x, y) {
  selection.attr("transform", `translate(${x},${y})`);
}

export function move_with_circle(selection, g, startx, starty) {
  translate(g, startx, starty);
  selection.tween("chng", function () {
    return function (t) {
      const x = parseFloat(d3.select(this).attr("cx"));
      const y = parseFloat(d3.select(this).attr("cy"));
      translate(g, x, y);
    };
  });
}

export function move_with_rect(selection, g, startx, starty) {
  translate(g, startx, starty);

  selection.tween("change", function () {
    return function (t) {
      const w = parseFloat(d3.select(this).attr("width"));
      translate(g, startx + w, starty);
    };
  });
}

export function move_with_rect_height(selection, g, startx, starty) {
  translate(g, startx, starty);

  selection.tween("change", function () {
    return function (t) {
      const h = parseFloat(d3.select(this).attr("height"));
      translate(g, startx, starty - h);
    };
  });
}

export function move_with_line(selection, g, x, y) {
  translate(g, x, y);
  selection.tween("test_tween", function () {
    var l = selection.node().getTotalLength();
    var r = d3.interpolate(0, l);

    return function (t) {
      const point = selection.node().getPointAtLength(r(t));
      translate(g, point.x, point.y);
    };
  });
}

export function move_with_line2(selection, g, x, y) {
  // Assuming g is a D3 selection, you can use .call to set the context
  translate(g, x, y);

  selection.transition().duration(5000)
    .ease(d3.easeLinear)
    .tween("test_tween", function () {
      var l = this.getTotalLength();
      console.log('length: ', l)
      var r = d3.interpolate(0, l);

      return function (t) {
        const point = this.getPointAtLength(r(t));
        translate(g, point.x, point.y);
      };
    });
}

export function rep_move_around_circle(selection, g, x, y, t) {
  selection
    .transition()
    .duration(t)
    .ease(d3.easeLinear)
    .tween("test_tween", function () {
      var l = selection.node().getTotalLength();
      var r = d3.interpolate(0, l);

      return function (t) {
        const point = selection.node().getPointAtLength(r(t));
        translate(g, point.x, point.y);
      };
    })
    .on("end", function () {
      selection.call(rep_move_around_circle, g, x, y, t);
    });
}

export function test_tween_function_a(selection, x) {
  selection.tween("test_tween", function () {
    return function (t) {
      console.log(x);
    };
  });
}



