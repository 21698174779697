import React from 'react';
import MainLayout from './Layouts/MainLayout';

function About() {
    return (
        <div>
            <MainLayout>
                <h1>This is About Page</h1>
            </MainLayout>
        </div>
    )
}

export default About;