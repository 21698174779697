import { useState, useRef } from "react";
import ThumbnailPage from "./ThumbnailPage.js";
import FinalPage from "./FinalPage.js";
import BuildPage from "./BuildPage.js"

export default function InflationNearUSA(props) {
    const divRef = useRef();

    if (props.value == 0) {
        return (
            <section>
                <ThumbnailPage />
            </section>
        );
    } else {
        return (
            <section>
                <FinalPage/> 
            </section>
        );
    }
}
