import { useEffect, useRef } from "react";
import BuildPage from './BuildPage.js'
import DesignPage from './DesignPage.js'
import FinalPage from "./FinalPage.js";
import ThumbnailPage from "./ThumbnailPage.js";
import ExtractCSS from "./ExtractCSS.js";

export default function PageChina(props) {
  const divRef = useRef();
  if(props.value == 0) {
	return (
		<section>
		{
			<ThumbnailPage/>
		}
		</section>
	)
} else {

  return (
	  <section>
	 
	  {
	  	<FinalPage/>
	  }
	
	  </section>
  );
	}
}
