import * as d3 from "d3";
import * as fn from "./simple_functions";
import * as fn_txt from "./gen_functions/text_functions";
import Device from "./Device";

export default class VisNew {
  constructor(svg, div) {
    this.div = div
    this.svg = svg;
    this.device_list = [];
  }

  fitToScreen(){
    const height = '75vh'
    const width = '100vw'
    const div = this.div
    const svg = this.svg

    div.style('height', height).style('width', width)
    svg.select('rect').remove()

    const outer_svg = div.append('svg').attr('id', 'outer_svg')

    outer_svg
      .style("height", height)
      .style("width", width);

    svg
      .attr("width", '100%')
      .attr("height", '100%');

    const g_bkg = svg.select('#g_bkg');
    const rect = g_bkg.select('rect')
    const g_svg = svg.select('#g_svg');
    const g_main = svg.select('#g_main');

    outer_svg.append(p => g_bkg.node())
    outer_svg.append(p => g_svg.node())
    svg.append(p => g_main.node())
    g_svg.append(p => svg.node())
    

    rect
      .style("height", height)
      .style("width", width);
  }

  create_device_list() {
    let deviceNames = [
      "Mobile", "Laptop", "SmartTV", "InternetCafe", "SmartWearable", "SeatbackTV",
      "SignBoard", "GameConsole", "HotelTV", "Kiosk", "Tablet", "POS", "ATM",
      "PublicLibrary", "Desktop", "VR", "SmartMirrors"
    ];

    deviceNames.forEach(element => {
        let object = this.svg.select("#" + element);
        this.device_list.push(object);
    });
  }

  extract() {
    this.description = this.svg.select("#description")

    this.titleEle = this.description.select("#Title").select("tspan");
    this.title = this.titleEle.text();
    this.desc = this.description.select("#Paragraphs");
  }

  animate_devices() {
    this.device1 = new Device(this.device_list[0],"Mobile", "vertical",[50, 0], [0,85], [0,74], false);
    this.device1.seq();

    this.device2 = new Device(this.device_list[1],"Laptop", "horizontal",[30, 32], [110,0], [110,0], false);
    this.device2.seq();

    this.device3 = new Device(this.device_list[2],"SmartTV", "horizontal",[66, 28], [20,0], [20,0,], true);
    this.device3.seq();

    this.device4 = new Device(this.device_list[3],"InternetCafe", "vertical",[50, 52], [0,-37], [0,-24], false);
    this.device4.seq();
    
    this.device5 = new Device(this.device_list[4],"SmartWearable", "vertical",[50, 0], [0,80], [0,68], false);
    this.device5.seq();

    this.device6 = new Device(this.device_list[5],"SeatbackTV", "vertical",[50, 0], [0,76], [0,60], false);
    this.device6.seq();

    this.device7 = new Device(this.device_list[6],"SignBoard", "horizontal",[42, 18], [115,0], [110,0], false);
    this.device7.seq();

    this.device8 = new Device(this.device_list[7],"GameConsole", "cross",[62, 10], [25,60], [15,45], false);
    this.device8.seq();

    this.device9 = new Device(this.device_list[8],"HotelTV", "cross",[60, 12], [20,60], [20,45], true);
    this.device9.seq();

    this.device10 = new Device(this.device_list[9],"Kiosk", "cross",[61.5, 37], [20,10], [25,-5], true);
    this.device10.seq();

    this.device11 = new Device(this.device_list[10],"Tablet", "cross",[62, 55.4], [20,0], [20,-10], false);
    this.device11.seq();

    this.device12 = new Device(this.device_list[11],"POS", "cross",[55, 29], [25,-20], [25,-35], false);
    this.device12.seq();

    this.device13 = new Device(this.device_list[12],"ATM", "cross",[45, 32], [75,-30], [75,-43], false);
    this.device13.seq();

    this.device14 = new Device(this.device_list[13],"PublicLibrary", "cross",[40, 10], [110,60], [100,50], false);
    this.device14.seq();

    this.device15 = new Device(this.device_list[14],"Desktop", "cross",[39, 56], [100,-10], [100,-20], false);
    this.device15.seq();

    this.device16 = new Device(this.device_list[15],"VR", "cross",[38, 43], [120,10], [110,-5], false);
    this.device16.seq();

    this.device17 = new Device(this.device_list[16],"SmartMirrors", "cross",[45, 30], [110,-30], [110,-53], false);
    this.device17.seq();
  }

  wrapper(f, t, a) {
    return new Promise((res, rej) => {
      f.call(this, t, a);
      setTimeout(() => {
        res("done");
      }, t);
    });
  }

  async seq() {
    await this.wrapper(this.create_device_list, 10);
    await this.wrapper(this.animate_devices, 10);
  }

  // stop animation
  stop() {
    this.device1.stopTransition()
    this.device2.stopTransition()
    this.device3.stopTransition()
    this.device4.stopTransition()
    this.device5.stopTransition()
    this.device6.stopTransition()
    this.device7.stopTransition()
    this.device8.stopTransition()
    this.device9.stopTransition()
    this.device10.stopTransition()
    this.device11.stopTransition()
    this.device11.stopTransition()
    this.device12.stopTransition()
    this.device13.stopTransition()
    this.device14.stopTransition()
    this.device15.stopTransition()
    this.device16.stopTransition()
    this.device17.stopTransition()
  }
        
  // reset animation
  async reset() {
    this.stop();
  }
        
  // rerun animation
  rerun() {
    this.stop();
    this.seq()
  }

  addDescription(div) {
    const descriptionDiv = div.append("div")
    .style("max-width", "1200px")
    .style("margin", "auto")
    .style("text-align", "left");

    // Add heading
    descriptionDiv.append("h3").style("color", "black").text(this.title);
    // Add paragraphs individually
    this.desc.selectAll("text").each(function () {
      const paragraphText = d3.select(this).text();
      descriptionDiv.append("p").text(paragraphText);
    });

    this.svg.select("#g_description").attr("opacity", 0);
  }
}