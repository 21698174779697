import * as d3 from "d3";
import * as fn from "./simple_functions";
import * as fn_txt from "./gen_functions/text_functions"

export default class Device {
  constructor(
    group,
    device,
    angle,
    dashLinePoint,
    textPosition,
    numberPosition,
    togglePathEnd
  ) {
    this.Group = group;
    this.angle = angle;
    this.name = device;
    this.dashLinePoint = dashLinePoint;
    this.textPositionX = textPosition[0];
    this.textPositionY = textPosition[1];
    this.numberPositionX = numberPosition[0];
    this.numberPositionY = numberPosition[1];
    this.togglePathEnd = togglePathEnd;
    this.countText = null;
    this.device_count = null;
    this.duration = 10000;
    this.countupDuration = 6000;
  }

  getDeviceCount() {
    this.countText  = this.Group.select('#DeviceCount' + this.name).select("tspan");
    const numericPart = this.countText.text().match(/\d+/); 

    if (numericPart) {
      this.device_count = parseInt(numericPart[0], 10);
    } else {
      console.log('No numeric part found in the text.');
    }  
  }

  draw() {
    var angle = this.angle;
    var dashLinePointX = this.dashLinePoint[0];
    var dashLinePointY = this.dashLinePoint[1];

    var textPositionX = this.textPositionX;
    var textPositionY = this.textPositionY;
    var numberPositionX = this.numberPositionX;
    var numberPositionY = this.numberPositionY;
    var togglePathEnd = this.togglePathEnd;
    const device = this.Group.select("#Device" + this.name);
    const million = this.Group.select("#DeviceCount" + this.name);
    const redCircle = this.Group.select("#redCircle" + this.name);
    const icon = this.Group.select("#icon" + this.name);

    var tspan = million.select("tspan");
    var tspanText = tspan.text();
    var numericPart = tspanText.match(/\d+/)[0];
    console.log("numericPart: ", numericPart);

    redCircle.attr("stroke", "transparent");

    const r = parseFloat(redCircle.attr("r"));
    const svgY = parseFloat(redCircle.attr("cy"));
    const svgX = parseFloat(redCircle.attr("cx"));
    const centerX = svgX;
    const centerY = svgY;

    const ystart = 0;
    const yend = 100;
    const xstart = 0;
    const xend = 100;

    const maxHeight = svgY + r;
    const minHeight = svgY - r - 100;
    const maxWidth = svgX + r + 150;
    const minWidth = svgX - r - 150;

    var yscale = d3
      .scaleLinear()
      .domain([ystart, yend])
      .range([maxHeight, minHeight]);
    var xscale = d3
      .scaleLinear()
      .domain([xstart, xend])
      .range([maxWidth, minWidth]);

    // //adding the scale
    var gyaxis = this.Group.append("g")
      .attr("transform", "translate(200,0)")
      .call(d3.axisLeft(yscale));
    var gxaxis = this.Group.append("g")
      .attr("transform", "translate(0,100)")
      .call(d3.axisBottom(xscale));

    // make scales invisible
    gyaxis.selectAll("text").style("opacity", 0);
    gyaxis.selectAll("path").style("opacity", 0);
    gyaxis.selectAll("line").style("stroke", "none");

    gxaxis.selectAll("text").style("opacity", 0);
    gxaxis.selectAll("path").style("opacity", 0);
    gxaxis.selectAll("line").style("stroke", "none");

    // //adding dots
    // const dot2 = fn.add_circle(this.Group, xscale(50), yscale(85), 5).attr("fill", "red").attr("opacity", 0.5);

    // //adding dots
    // const dot3 = fn.add_circle(this.Group, xscale(50), yscale(75), 5).attr("fill", "green").attr("opacity", 0.5);

    //path info
    const pathElement = this.Group.select("#path" + this.name);
    const pathStart = fn.lineGetStart(pathElement);
    const pathEnd = fn.lineGetEnd(pathElement);

    //adding dots
    // const dot1 = fn.add_circle(icon, xscale(dashLinePointX),yscale(dashLinePointY) , 3).attr("fill", "blue").attr("opacity", 0.5);

    // //create new path
    const path = d3.path();
    // path.moveTo(pathEnd[0], pathEnd[1]);
    // path.moveTo(pathStart[0], pathStart[1]);
    // path.lineTo(xscale(this.dashLinePointX), yscale(this.dashLinePointY));

    // //adding new path
    const pathElementB = this.Group.append("g")
      .append("path")
      .attr("fill", "none")
      .attr("stroke", "#00293F")
      .attr("d", path)
      .attr("stroke-dasharray", "6, 6")
      .attr("stroke-width", "0.7844");

    var tspanIndex = 0;
    var device_name = device.select(
      "tspan:nth-child(" + (tspanIndex + 1) + ")"
    );
    var device_count = million.select(
      "tspan:nth-child(" + (tspanIndex + 1) + ")"
    );
    const zoom = d3.zoom().on("zoom", updateChart);

    function updateChart(event) {
      var trs = event.transform;
      icon.attr("transform", trs);

      const newyscale = trs.rescaleY(yscale);
      const newxscale = trs.rescaleX(xscale);

      // gyaxis.call(d3.axisLeft(newyscale));
      // gxaxis.call(d3.axisBottom(newxscale));

      // dot1.attr("cy", newyscale(Yvalue));
      // device_name.attr("y", newyscale(85));
      // device_count.attr("y", newyscale(75));

      //drawing path
      var p = d3.path();
      if (togglePathEnd == true) {
        p.moveTo(pathEnd[0], pathEnd[1]);
      } else {
        p.moveTo(pathStart[0], pathStart[1]);
      }
      if (angle === "horizontal") {
        p.lineTo(newxscale(dashLinePointX), yscale(dashLinePointY));
      } else if (angle == "vertical") {
        p.lineTo(xscale(dashLinePointX), newyscale(dashLinePointY));
      } else {
        p.lineTo(newxscale(dashLinePointX), newyscale(dashLinePointY));
      }
      pathElementB.attr("d", p);

      if (angle === "horizontal") {
        device_name.attr("x", newxscale(textPositionX));
        device_count.attr("x", newxscale(numberPositionX));
      } else if (angle === "vertical") {
        device_name.attr("y", newyscale(textPositionY));
        device_count.attr("y", newyscale(numberPositionY));
      } else {
        device_name.attr("x", newxscale(textPositionX));
        device_name.attr("y", newyscale(textPositionY));
        device_count.attr("x", newxscale(numberPositionX));
        device_count.attr("y", newyscale(numberPositionY));
      }
      // dot2.attr("cy", newyscale(80));
    }

    icon
      .transition()
      .duration(1000)
      .ease(d3.easeLinear)
      .call(zoom.scaleBy, 0.5, [centerX, centerY])
      .transition()
      .duration(5000)
      .ease(d3.easeLinear)
      .call(zoom.transform, d3.zoomIdentity, [centerX, centerY]);

      this.countingObj = fn_txt.countUp(this.countupDuration, this.device_count, this.countText," Million");
    }

  initiate() {
    console.log("initiate the required things before the main animation");
    console.log("Group", this.Group);
    console.log("angle", this.angle);
    console.log("redCircle", this.redCircle);
  }

  wrapper(f, t, a) {
    return new Promise((res, rej) => {
      f.call(this, t, a);
      setTimeout(() => res("done"), t);
    });
  }

  async seq() {
    await this.wrapper(this.getDeviceCount, 100);
    await this.wrapper(this.draw, 100);
  }

  stopTransition() {
    this.Group.select("#icon" + this.name).interrupt();

    if (this.countingObj) {
      // If countingObj is defined, stop the countUp function
      this.countingObj.stopCount();
    }
  }
}
