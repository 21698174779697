import * as d3 from "d3";

export function typeText(textEle) {
	var new_txt = '';
	const innerText = textEle.text()
	textEle.text('');
	var l = 0;
	var list = [...innerText];

	list.forEach((i, j) => {

		setTimeout(() => {
			new_txt = new_txt + i;
			textEle.text(new_txt);
		}, 100 * j)
	})

}

export function typeTextWithDuration(duration, textEle) {
	var new_txt = '';
	const innerText = textEle.text()
	const durationForOneLetter = duration / innerText.length
	textEle.text('');
	var timeouts = [];

	var list = [...innerText];

	list.forEach((i, j) => {
		var timeoutId = setTimeout(() => {
			new_txt = new_txt + i;
			textEle.text(new_txt);
		}, durationForOneLetter * j);

		timeouts.push(timeoutId);
	});

	return {
		stop() {
			timeouts.forEach(timeoutId => {
				clearTimeout(timeoutId);
			});
		}
	};
}

export function countUp(duration, num, elementToUpdate, specialChr) {
	const f = duration / (num + 1);
	var p = 0;
	var runState = true

	const intervalId = setInterval(() => {
		if (p >= num || !runState) {
			clearInterval(intervalId);
			return;
		}
		p = p + 1;
		elementToUpdate.text(p + specialChr);
	}, f);

	return {
		stopCount() {
			runState = false;
		}
	};
}


export function cleanText(text) {
	var cleanText = text.replace(/&#xa0;|&#10;/g, '');
	cleanText = cleanText.replace(/&#39;/g, "'")
	cleanText = cleanText.replace(/&#x2019;/g, "'");
	cleanText = cleanText.replace(/&#xe3;/g, "ã");
	cleanText = cleanText.replace(/&#xe1;/g, "á");
	cleanText = cleanText.replace(/&#x2028;/g, "");

	return cleanText;
}