import React, { useState } from "react";
import * as d3 from "d3";
import { useEffect, useRef } from "react";
import Vis from "./vt_page_sat_emission/Vis.js";
import { ReactComponent as BaseSvg } from './vt_page_sat_emission/svg.svg'
import HomePageGif from './site_home_chart.gif'

import * as fn_svg from "./vt_gen_functions/svg_functions.js";

export default function HomePageChart() {
  const divRef = useRef();
  let vis

  async function draw() {

    const div = d3.select(divRef.current);
   
    const svg = div.select("svg");
    svg.select('rect').remove()

    if (!svg.empty()) {
      fn_svg.fitToHomePageDiv(div, svg);
      vis = new Vis(svg);
      await vis.seq();
    } else {
      console.error("SVG element not found.");
    }
  }


  useEffect(() => {
    /*draw();

    const cleanup = () => {
			stopAnimation();
		};

    return cleanup;*/
  }, []);

  // Stop animation function
	const stopAnimation = () => {
		if (vis) {
			vis.stop();
		}
	};

  return( 
      <div ref={divRef} style={{width: '100vw',height: '60vh',overflow: 'hidden',backgroundColor: 'black'}}>
          {/*<BaseSvg/>*/}
          <img src={HomePageGif} alt="My Gif" style={{ width: '100%',height: '100%',objectFit: 'contain'}} />
      </div>
    );
  
}

