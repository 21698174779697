import * as d3 from 'd3'
import * as fn from './simple_funs.js'
import * as sfn from './special_funs.js'
import * as fn_txt from './gen_functions/text_functions.js'

export default class Country {

    constructor(g, id) {
        this.id = id
        this.g = g.select('#' + 'g' + id)
        this.numberOfDotsMoveTOTwiitter = 20
        this.runState = true
    }
    setup() {
        this.get_path()
        this.change_path_col()
        this.get_start()
        this.get_end()
        this.getCountry()
    }

    get_path() {
        this.path = this.g.select('#path' + this.id)
    }

    change_path_col() {
        this.path.attr('stroke', 'white').attr('opacity', 0.3)
    }

    get_end() {
        this.end = fn.lineGetEnd(this.path)
    }

    get_start() {
        this.start = fn.lineGetStart(this.path)

    }

    move_to_end(cl) {
        this.cl.call(fn.move_circle_to_point_with_duration, this.end, 2000)

    }

    move_along_the_path(cl) {
        this.cl.call(fn.move_along_path_back, this.path, 2000)
    }

    get_small_paths() {
        this.small_paths = this.g.select('#g' + this.id + 'c').selectAll('path')
            .filter((d, i) => { return i < 50 })
    }

    make_dots() {
        var g = this.g.append('g')
        this.g_dot = g
        var id = this.id
        var main_path = this.path

        this.small_paths.each(function (d, i) {
            var small_curve_path = d3.select(this)
            var small_curve_path_start = fn.lineGetStart(small_curve_path)
            var c = fn.add_circle(g, small_curve_path_start).attr('r', 1).attr('id', 'g' + id + 'dots' + i).attr('fill', 'white').attr('class', 'main')
        })

    }

    animate_one_batch(dotMoveToEndDuration) {
        var end = this.end
        this.g_dot.selectAll('.main').each(

            function (i) {
                var t = d3.randomInt(1, dotMoveToEndDuration)
                var cl = d3.select(this).clone().attr('r', 1).attr('fill', 'white').attr('class', 'sub')
                cl.call(sfn.seq, end, t)
            })
    }

    async dot_move(waitTime, i) {
        if (i < 10 && this.runState) {
            console.log(i)
            await this.wrapper(this.animate_one_batch, this.dotMoveToEndDuration)
            i += 1
            this.dot_move(waitTime, i)
        }
    }

    move_to_twitter(duration) {
        var gap = this.gap
        var duration = duration
        var runState = this.runState

        var g = this.g.append('g').attr('id', "g_circlesToTwitter")
        var p = this.start
        var e = this.end
        var p = this.path

        d3.range(this.numberOfDotsMoveTOTwiitter).map((i) => {
            setTimeout(() => {
                if(runState){
                    var cl = fn.add_circle(g, e).attr('r', 1.5).attr('fill', 'white');
                    cl.call(fn.move_along_path_back, p, duration);
                }              
            }, gap * i);
        });

    }

    async repeat_dot_move_and_move_to_twitter() {

        var dotMoveToTwitterDuration = (this.totalDuration - 500) / 2
        this.dotMoveToEndDuration = (this.totalDuration) / 10
        this.gap = dotMoveToTwitterDuration / this.numberOfDotsMoveTOTwiitter
        this.countUp(this.totalDuration)
        this.typeCountry()
        await this.wrapper(this.dot_move, 500, 0)
        this.move_to_twitter(dotMoveToTwitterDuration)

    }

    countUp(duration) {

        this.numEle = this.g.select('#g' + this.id + 'n').select('#number' + this.id).select('text').select('tspan')
        this.number = parseInt(this.numEle.text())

        this.countingObj = fn_txt.countUp(duration, this.number, this.numEle, "");
    }

    getCountry() {
        this.countryTxt = this.g.select('#country' + this.id).select('text').select('tspan')
    }

    typeCountry() {
        fn_txt.typeText(this.countryTxt)
    }

    wrapper(f, t, a) {
        return new Promise((res, rej) => {
            f.call(this, t, a)
            setTimeout(() => { res('done') }, t)
        })
    }

    async seq(duration) {
        this.totalDuration = duration - 1000
        await this.wrapper(this.setup, 10)
        await this.wrapper(this.get_small_paths, 290)
        await this.wrapper(this.make_dots, 200)
        this.repeat_dot_move_and_move_to_twitter()

    }

    stop() {
        if(this.path){
            this.g.select('#g_circlesToTwitter').selectAll('circle').interrupt()
            this.g_dot.selectAll('circle').interrupt()
            this.countingObj.stopCount();
            this.runState = false
        }      
    }

    reset() {
        this.g.select('#g_circlesToTwitter').remove()
        this.g_dot.remove()
        this.path.attr('stroke', 'white').attr('opacity', 1)
        this.numEle.text(this.number + '')
        this.runState = true
    }

}
