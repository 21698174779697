import { useEffect, useRef } from "react";
import { ReactComponent as BaseSvg } from "./thumbnailSvg.svg";
import * as d3 from "d3";

import * as fn_svg from "./gen_functions/svg_functions.js";

export default function ThumbnailPage() {
  const divRef = useRef();

  function draw() {
    const div = d3.select(divRef.current);
    const svg = div.select("svg");
    
    fn_svg.fitToDiv(div,svg);
  }

  useEffect(() => {
    draw();
  }, []);

  return (
    <div ref={divRef}>
      <BaseSvg />
    </div>
  );
}
