import { useEffect, useRef } from "react";
import { ReactComponent as BaseSvg } from "./finalSvg.svg";
import Vis from "./Vis";
import * as d3 from 'd3';

import * as fn_svg from './vt_gen_functions/svg_functions.js'
import * as fn_layout from './vt_gen_functions/layout_functions.js'
import * as fn_controls from './vt_gen_functions/chartcontrol_functions.js'
import * as fn from './vt_gen_functions/simple_funs.js'

export default function SmallDivPage() {
  const divRef = useRef();

  function draw() {

    const div = d3.select(divRef.current);
    const svg = div.select('svg');

	  fn_svg.fitToDiv(div,svg)
    
  }

  useEffect(() => {
    draw()
  }, []);

  return (

	    <div ref={divRef}> <BaseSvg /> </div>
  );
}
