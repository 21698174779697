import * as d3 from "d3";
import * as fn from "./simple_funs.js";
import * as fn_txt from "../vt_gen_functions/text_functions.js";
import * as fn_move from "./move_functions.js";
import csvFile from "./data.csv";

export default class Vis {
  constructor(g, rect, totalDuration) {
    this.rect = rect;
    this.outerg = g;
    this.g = g.append("g");
    this.dataPoints = 6;
    this.margin = 50;
    this.data = [];
    this.totalDuration = totalDuration
    this.rect.attr("opacity", 0);
    this.circleRadius = 10;
    this.lblFontSize = 10;
    this.runState = true
    this.runStateForLabels = true
  }

  async bindData() {
    this.st = [];

    const data = await d3.csv(csvFile);
    this.data = data;

    // Convert GDP values to numbers and round to two decimal places
    this.st = data.map((d) => [d["year"], parseFloat((Number(d["GDP growth(annual %)"])))]);
  }

  addScale() {
    var st = this.st;

    var height = this.maxHeight;
    var width = this.maxWidth;

    // Assuming that the first element in each data point is a year
    var parseTime = d3.timeParse("%Y");


    this.xscale = d3
      .scaleTime()
      .domain(d3.extent(st, (d) => parseTime(d[0])))
      .range([0, width]);

    this.yscale = d3
      .scaleLinear()
      .domain([-30, d3.max(st, (d) => d[1])])
      .range([height, 0])
      .nice();

    this.gX = this.g
      .append("g")
      .attr('id', 'g_xaxis')
      .call(d3.axisBottom(this.xscale).ticks(d3.timeYear.every(10)))
      .call(fn.translate, 0, this.maxHeight + 5)
      .selectAll("text")
      .style("font-size", "15px");

    this.gY = this.g
      .append("g")
      .attr('id', 'g_yaxis')
      .call(d3.axisLeft(this.yscale.nice()))
      .selectAll("text")
      .style("font-size", "15px");
  }

  addScaleForAnimation() {
    var xAxisStartPoint = fn.lineGetStart(this.xAxis)
    var xAxisEndPoint = fn.lineGetEnd(this.xAxis)
    this.yAxisStartPoint = fn.lineGetStart(this.yAxis)
    var yAxisEndPoint = fn.lineGetEnd(this.yAxis)

    var st = this.st;

    // Assuming that the first element in each data point is a year
    var parseTime = d3.timeParse("%Y");

    this.xscale = d3
      .scaleTime()
      .domain(d3.extent(st, (d) => parseTime(d[0])))
      .range([xAxisStartPoint[0], xAxisEndPoint[0]]);

    this.yscale = d3
      .scaleLinear()
      .domain([-30, d3.max(st, (d) => d[1])])
      .range([this.yAxisStartPoint[1], yAxisEndPoint[1]])
      .nice();
  }


  addLines() {
    var st = this.st;
    var xscale = this.xscale;
    var yscale = this.yscale;
    var height = this.yAxisStartPoint[1]


    var line_two = d3
      .line()
      .x((d) => xscale(d3.timeParse("%Y")(d[0])))
      .y((d) => yscale(d[1]))
      .curve(d3.curveMonotoneX);

    var line_three = d3
      .line()
      .x((d) => xscale(d3.timeParse("%Y")(d[0])))
      .y((d) => height - 100)
      .curve(d3.curveMonotoneX);

    this.path_two = this.g
      .append("g")
      .append("path")
      .attr("fill", "none")
      .attr("stroke", "red")
      .attr("opacity", 0)
      .attr("stroke-width", 3)
      .datum(st)
      .attr("d", line_two);

    this.path_three = this.g
      .append("path")
      .attr("fill", "none")
      .attr("stroke", "black")
      .attr("opacity", 0)
      .datum(st)
      .attr("d", line_three);
  }

  adjustInngerG() {
    this.outerg.call(fn.translate, this.x, this.y);
  }

  initiate() {
    this.length = this.data.length;
  }

  calculateMinMaxInData() {
    this.xmax = d3.max(this.data, (d) => d.x);
    this.ymax = d3.max(this.data, (d) => d.y);
  }

  adjustMargin() {
    this.g.call(fn.translate, this.margin * 2, this.margin);
  }

  getRectangelInfo() {
    this.x = parseFloat(this.rect.attr("x"));
    this.y = parseFloat(this.rect.attr("y"));
    this.rectFillColor = this.rect.attr("fill");
  }

  calculateMaxMinValues() {
    this.maxHeight = parseFloat(this.rect.attr("height")) - this.margin * 2;
    this.maxWidth = parseFloat(this.rect.attr("width")) - this.margin * 4;
    this.barWidth = (this.maxWidth / this.dataPoints) * 0.8;
  }

  getRandomData() {
    this.data = [];
    for (let i = 0; i <= this.dataPoints; i++) {
      var randomValue =
        Math.floor(Math.random() * ((100 - 10 + 1) / 10)) * 10 + 10;
      this.data.push({ x: 2017 + i, y: randomValue });
    }
    this.st = this.data.map((d) => [Number(d["x"]), Number(d["y"])]);
    console.log(this.st)
    console.log(this.data)
  }

  addLabels() {
    this.labels = this.g
      .append("g")
      .attr("id", "labels")
      .selectAll("text")
      .data(this.st)
      .enter()
      .append("text")
      .attr("id", (d, i) => "text" + i)
      //.attr("x", (d, i) => this.xscale(d.x))
      .attr("x", (d, i) => this.xscale(d3.timeParse("%Y")(d[0])))
      .attr("y", (d, i) => this.yscale(d[1]))
      .attr("fill", "black")
      .attr("font-size", this.lblFontSize)
      .text((d, i) => d[1] + "B")
      .attr("opacity", 1);
  }

  addCircles() {
    this.circle = this.g
      .append("g")
      .attr("id", "circles")
      .selectAll("circle")
      .data(this.st)
      .enter()
      .append("circle")
      .attr("id", (d, i) => "circle" + i)
      .attr("cx", (d, i) => this.xscale(d3.timeParse("%Y")(d[0])))
      .attr("cy", (d, i) => this.yscale(d[1]))
      .attr("fill", "green")
      .attr("r", 8);
  }

  addEndCircle() {
    this.endCircle = fn
      .add_circle2(this.g, 0, 0, 8)
      .attr("fill", "#E56565")
      .attr('opacity', 0)
  }

  animateOneCircle(durationForOneCircle, i) {
    if (i !== this.circles.selectAll('path').size()) {
      this.circles.select("#value" + i).call(
        fn.anim,
        durationForOneCircle,
        "opacity",
        1
      );

      this.circles.select("#point" + i).call(
        fn.anim,
        durationForOneCircle,
        "opacity",
        1
      );
    }
  }

  async animateOneLabel(durationForOneLbl, i) {
    var g = this.labels.select('#detail' + i)


    const lblIcons = g.select('#Icon' + i).selectAll('path')
    lblIcons.transition().duration(100).attr('opacity', 1)

    const desc = g.select("#TextGroup" + i).selectAll('text');
    const durationForOneLine = (durationForOneLbl - 100) / desc.size();

    for (const text of desc.nodes()) {
      if (this.runState) {
        const tspan = d3.select(text).select('tspan');
        tspan.attr('opacity', 1)
        await this.wrapper(this.textLine = fn_txt.typeTextWithDuration, durationForOneLine, tspan);
      }
    }
  }

  async animateCircles(totalDuration, i) {
    var durationForOneCircle = totalDuration / this.circles.selectAll('path').size()
    if (i <= this.circles.selectAll('path').size() && this.runStateForLabels) {
      await this.wrapper(this.animateOneCircle, durationForOneCircle, i);
      i += 1;
      await this.animateCircles(totalDuration, i);
    }
  }

  async animateLabels(totalDuration, i) {
    var durationForOneLbl = totalDuration / this.circles.selectAll('path').size()
    if (i <= this.circles.selectAll('path').size() && this.runStateForLabels) {
      await this.wrapper(this.animateOneLabel, durationForOneLbl, i);
      i += 1;
      await this.animateLabels(totalDuration, i);
    }
  }

  addInvertYscale() {
    this.yAxisStartPoint = fn.lineGetStart(this.yAxis)
    this.yAxisEndPoint = fn.lineGetEnd(this.yAxis)

    var st = this.st;
    var ymax = d3.max(st, (d) => d[1])

    this.yScale = d3
      .scaleLinear()
      .domain([-30, ymax])
      .range([this.yAxisStartPoint[1], this.yAxisEndPoint[1]])
      .nice();
  }

  addCountdownText() {
    this.countdownText = fn.add_text(this.outerg, 0, 0)
    this.countdownText.attr('font-size', '20px').attr('fill', 'black').attr('id', 'countTxt').attr('opacity', 0)
  }

  animatePath(totalDuration) {
    var yScale = this.yscale
    var txt = this.countdownText
    var endCircle = this.endCircle
    var path_one = this.path_one
    var path_two = this.path_two;
    var path_three = this.path_three;
    var height = this.yAxisStartPoint[1]

    this.countdownText.attr("opacity", 1)
    this.endCircle.attr("opacity", 1)

    var ar = d3
      .range(path_two.node().getTotalLength() + 100)
      .map((d) => ({ point: path_two.node().getPointAtLength(d) }))
      .map((p) => ({ x: p.point.x, y: p.point.y }));


    function x_value(ar, val) {
      var bisect = d3.bisector((d) => d.x).left;
      var ind = bisect(ar, val);
      var p = ar[ind];
      return p.x;
    }

    function y_value(ar, xx) {
      var bisect = d3.bisector((d) => d.x).left;
      var ind = bisect(ar, xx);
      var p = ar[ind];
      return p.y;
    }

    var mt = [[x_value(ar, 0), y_value(ar, 0)]];

    path_one
      .attr('opacity', 1)
      .transition().duration(totalDuration).ease(d3.easeLinear)
      .tween('count_values', () => {
        var path_three_lenght = path_three.node().getTotalLength();
        var r = d3.interpolate(0, path_three_lenght);
        return function (t) {
          var val = r(t);
          var nx = path_three.node().getPointAtLength(val).x;
          var py = y_value(ar, nx);
          var px = x_value(ar, nx);

          mt.push([px, py]);
          d3.select(this)
            .datum(mt)
            .attr(
              "d", d3
                .line()
                .x((d) => d[0])
                .y((d) => d[1])
                .curve(d3.curveMonotoneX)
            );

          var yValue = d3.format('.0f')(yScale.invert(py))
          txt.text(yValue + '  %').attr('x', px - 5).attr('y', py - 10)
          endCircle.attr('cx', px).attr('cy', py)
        }
      })

  }

  async animate(totalDuration, i) {
    var duration = totalDuration / 6
    await this.wrapper(this.animatePath, duration * 3);
    if (this.runStateForLabels) {
      this.animateLabels(duration * 2, i)
      this.animateCircles(duration * 2, i)
    }
  }

  changeDuration(value) {
    this.totalDuration = value
  }

  wrapper(f, t, a) {
    return new Promise((res, rej) => {
      f.call(this, t, a);
      setTimeout(() => {
        res("done");
      }, t);
    });
  }

  removRect() {
    this.rect.attr("height", 0).attr("width", 0);
  }

  removeBarsLabels() {
    this.circles.selectAll('path').attr('opacity', 0)
    this.circles.selectAll('text').attr('opacity', 0)
    this.labels.selectAll('tspan').attr('opacity', 0)
    this.labels.selectAll('path').attr('opacity', 0)
    this.path_one.attr("opacity", 0);
    this.endCircle.attr('opacity', 0)
    this.countdownText.attr('opacity', 0)
  }

  async seq() {
    await this.wrapper(this.getRectangelInfo, 10);
    await this.wrapper(this.adjustInngerG, 10);
    await this.wrapper(this.calculateMaxMinValues, 10);
    await this.wrapper(this.adjustMargin, 10);
    await this.wrapper(this.getRandomData, 10)
    //await this.wrapper(this.bindData, 10);
    await this.wrapper(this.initiate, 10);
    await this.wrapper(this.calculateMinMaxInData, 10);
    await this.wrapper(this.removRect, 10);
    await this.wrapper(this.addScale, 10);
    await this.wrapper(this.addLines, 10);
    await this.wrapper(this.addCircles, 10);
    await this.wrapper(this.addEndCircle, 10);
    await this.wrapper(this.addLabels, 10);
    await this.wrapper(this.animate, this.totalDuration, 0);
    //await this.wrapper(this.moveEndCircle, 10)
  }

  async build() {
    await this.wrapper(this.getRectangelInfo, 10);
    await this.wrapper(this.adjustInngerG, 10);
    await this.wrapper(this.calculateMaxMinValues, 10);
    await this.wrapper(this.adjustMargin, 10);
    //await this.wrapper(this.getRandomData, 10)
    await this.wrapper(this.bindData, 10);
    await this.wrapper(this.initiate, 10);
    await this.wrapper(this.calculateMinMaxInData, 10);
    await this.wrapper(this.removRect, 10);
    await this.wrapper(this.addScale, 10);
    await this.wrapper(this.addLines, 10);
  }

  extract() {
    this.labels = this.outerg.select('#details')
    this.circles = this.outerg.select('#Points')
    this.path_one = this.outerg.select('#chart_line')
    this.yAxis = this.outerg.select('#g_yaxis').select('path')
    this.xAxis = this.outerg.select('#g_xaxis').select('path')

    
    this.addCountdownText()
    this.addEndCircle()
    this.removeBarsLabels()
  }

  async move() {
    this.removeBarsLabels()
    await this.wrapper(this.animate, this.totalDuration, 1);
  }

  async seq(){
    await this.wrapper(this.extract, 10);
    await this.wrapper(this.bindData, 500);
    await this.wrapper(this.addScaleForAnimation, 10);
    await this.wrapper(this.addLines, 10);
  }

  stop() {
    this.runState = false
    this.runStateForLabels = false
    this.circles.selectAll('circle').interrupt()
    this.path_one.interrupt()
  }

  reset() {
    this.countdownText.attr("opacity", 0)
    this.endCircle.attr("opacity", 0)
    this.runState = true
    this.removeBarsLabels()
    setTimeout(() => {
      this.runStateForLabels = true
    }, 15000);

  }

}
