import * as d3 from 'd3';
import * as fn from './simple_funs.js';
import * as fn_txt from './gen_functions/text_functions.js'

export default class Country {
    constructor(svg, id) {
        this.id = id;
        this.svg = svg;
        this.groups = svg.select('#g_main').select('#groups');
        this.g = this.groups.select('#' + id);
        this.animationTimeouts = [];
        this.factor = 1
        this.runState = true

    }

    reset() {
        this.path.attr('stroke', 'black');
        this.svg.selectAll('circle').remove()
        this.txt.text(0 + ' millions');
    }


    getPath() {
        this.path = this.g.select('#' + this.id + 'path');
    }

    getTotalLength() {
        this.totalLength = fn.getTotalLength(this.path);
    }

    changePathCol() {
        this.path.attr('stroke', 'red');
    }

    getEnd() {
        this.end = fn.lineGetEnd(this.path);
    }

    getStart() {
        this.start = fn.lineGetStart(this.path);
    }

    addCl() {
        this.cl = fn.add_mark(this.g, this.end).attr('r', 5).attr('fill', 'red');
    }

    moveAlongThePath() {
        this.cl.call(fn.move_along_path_back, this.path, 1000);
        console.log('testing');
    }

    repeatMovementForMultipleCls() {

        const totalDuration = this.totalDuration;
        const path = this.path;
        const numberOfDots = this.num / (2 * this.factor);
        const sing_dot_movement_duration = totalDuration / (numberOfDots + 2);
        var p = 0

        const intervalId = setInterval(() => {
            if (!this.runState || p >= numberOfDots) {
                clearInterval(intervalId);
                return;
            }
            this.circle = fn.add_mark(this.g, this.end).attr('r', 3).attr('fill', 'red');
            this.circle.call(fn.move_along_path_back, path, sing_dot_movement_duration);
            p = p + 1;
        }, sing_dot_movement_duration);
    }

    changeFactor(value) {
        this.factor = value
    }


    getNumber() {
        this.txt = this.g.select('#' + this.id + 'n').select('tspan');
        const num = this.txt.text();
        const val = parseFloat(num);
        this.num = val;
    }

    numberCountUp() {
        this.numberCountUpObj = fn_txt.countUp(this.totalDuration, this.num, this.txt, ' millions')
    }

    animate() {
        this.numberCountUp()
        this.repeatMovementForMultipleCls()
    }

    stop() {
        this.runState = false
        //this.circle.interrupt()
        // this.txt.interrupt()
        this.cl.interrupt()
        this.numberCountUpObj.stopCount()
    }

    wrapper(func, time, arg) {
        return new Promise((res, rej) => {
            func.call(this, time, arg);

            setTimeout(() => {
                res('done');
            }, time);
        });
    }

    async seq(timeForSeq) {

        this.totalDuration = timeForSeq - 90

        await this.wrapper(this.getPath, 10);
        await this.wrapper(this.getTotalLength, 10);
        await this.wrapper(this.changePathCol, 10);
        await this.wrapper(this.getEnd, 10);
        await this.wrapper(this.getStart, 10);
        await this.wrapper(this.addCl, 10);
        await this.wrapper(this.getNumber, 10);
        //await this.wrapper(this.calDuration, 100);
        await this.wrapper(this.animate, this.totalDuration);


    }

    async reseq(timeForSeq) {

        this.totalDuration = timeForSeq - 80
        this.runState = true

        await this.wrapper(this.getPath, 10);
        await this.wrapper(this.getTotalLength, 10);
        await this.wrapper(this.changePathCol, 10);
        await this.wrapper(this.getEnd, 10);
        await this.wrapper(this.getStart, 10);
        await this.wrapper(this.addCl, 10);
        await this.wrapper(this.animate, this.totalDuration);


    }

}
