import * as d3 from 'd3'

export function add_circle(g, x, y, r) {
    var cla = g.append('circle').attr('cx', x).attr('cy', y).attr('r', r).attr('fill', 'black')
    return cla
}


export function add_mark(g, a) {
    var x = a[0]
    var y = a[1]
    var r = 10
    var cla = g.append('circle').attr('cx', x).attr('cy', y).attr('r', r).attr('fill', 'black')
    return cla
}


var add_circle_white = function (g, x, y, r) {
    var cla = g.append('circle').attr('cx', x).attr('cy', y).attr('r', r).attr('fill', 'black').attr('stroke', 'white')
    return cla
}


export function move_along_path(selection, path, t) {
    selection.transition().duration(t).ease(d3.easeLinear).tween('planet_path', () => move_on_path(path))

}

export function move_on_path(p) {
    var l = p.node().getTotalLength()
    var r = d3.interpolate(0, l)
    return function (t) {
        var point = p.node().getPointAtLength(r(t))
        d3.select(this).attr('cx', point.x).attr('cy', point.y)
    }
}

export function move_on_path_cl(p) {
    var l = p.node().getTotalLength()
    var r = d3.interpolate(0, l)
    return function (t) {
        var point = p.node().getPointAtLength(r(t))
        d3.select(this).attr('cx', point.x).attr('cy', point.y)
    }
}

export function move_on_path_g(p) {
    var l = p.node().getTotalLength()
    var r = d3.interpolate(0, l)
    return function (t) {
        var point = p.node().getPointAtLength(r(t))
        d3.select(this).call(transform, point.x, point.y)
        //.attr('x',point.x).attr('y',point.y)
    }
}

export function move_on_path_rect(p) {
    var l = p.node().getTotalLength()
    var r = d3.interpolate(0, l)
    return function (t) {
        var point = p.node().getPointAtLength(r(t))
        d3.select(this).attr('x', point.x).attr('y', point.y)
    }
}

function back_on_path(p) {
    var l = p.node().getTotalLength()
    var r = d3.interpolate(l, 0)
    return function (t) {
        var point = p.node().getPointAtLength(r(t))
        d3.select(this).attr('cx', point.x).attr('cy', point.y)
    }
}

export function add_simple_line(g, from, to) {
    let path = d3.path()
    path.moveTo(from[0], from[1])
    path.lineTo(to[0], to[1])
    let ele = g.append('path').attr('stroke', 'black').attr('fill', 'black').attr('d', path).attr('shape-rendering', 'crispEdges')
    return ele
}

export function  add_rect(g, a,b, w, h) {
    var ele = g.append('rect').attr('width', w).attr('height', h).attr('fill', 'grey').attr('x', a).attr('y', b)
    return ele
}


export function add_text(g, txt, x, y) {
    var ele = g.append('text').text(txt).attr('x', x).attr('y', y)
    return ele
}

export function get_txt_loc(ele) {
    var loc_one = [parseInt(ele.attr('x')) + 10, parseInt(ele.attr('y')) + 30]
    return loc_one
}


export function count_up(obj, n, t) {
    var n = n
    var f = t / n

    d3.range(n).forEach(function (i) {
        setTimeout(() => {
            var val = i + 1
            obj.text(val)
        }, f * i)
    })
}

export function count_inf_main(ele) {
    var ind = 0

    function count_inf() {
        ind = ind + 1
        ele.transition().ease(d3.easeLinear).duration(50).text(ind).on('end', count_inf)
    }

    ele.call(count_inf)

}


export function rep_mov_cl(selection, path, t) {
    selection.transition().duration(t).ease(d3.easeLinear)
        .tween('planet_path', () => move_on_path_cl(path))
        .on('end', () => (selection.call(rep_mov_cl, path, t)))
}

export function rep_mov_rect(selection, path, t) {
    selection
        .transition().duration(t)
        .ease(d3.easeLinear).tween('planet_path', () => move_on_path_rect(path))
        .on('end', () => (selection.call(rep_mov_rect, path, t)))
}

export function seq_rep_mov_cl(l, sl, n, t1, t2) {
    d3.range(n).forEach(function (i) {
        //var f=t1/n
        setTimeout(() => {
            var val = i + 1
            //console.log(l[i])
            l[i].call(rep_mov_cl, sl, t2)
        }, t1 * i)
    })
}

export function lineGetStart(ele) {
    const point = ele.node().getPointAtLength(0);
    return [point.x, point.y]
}

export function lineGetEnd(ele) {
    const l = ele.node().getTotalLength();
    const point = ele.node().getPointAtLength(l);
    return [point.x, point.y]
}


export function add_line(g, from, to) {
    var ele = g.append('line').attr('stroke', 'black')
        .attr('x1', from[0])
        .attr('y1', from[1])
        .attr('x2', to[0])
        .attr('y2', to[1])
        .attr('shape-rendering', 'auto')
        .attr('shape-rendering', 'crispEdges')
        .attr('shape-rendering', 'geometricPrecision')
    return ele
}


export function draw_path(selection, t) {
    var l = selection.node().getTotalLength()
    selection.attr('stroke-dasharray', l + '  ' + l).attr('stroke-dashoffset', l)
        .transition().duration(t).ease(d3.easeLinear)
        .attr('stroke-dashoffset', 0)

}


export function make_white(selection) {
    var l = selection.node().getTotalLength()
    selection.attr('stroke-dasharray', l + '  ' + l).attr('stroke-dashoffset', l)

}


export function anim(selection, t, attr, v) {
    selection.transition().ease(d3.easeLinear).duration(t).attr(attr, v)
}


//----------------------------------------------------------------------------------------------------

export function transform(selection, x, y) {
    selection.attr('transform', `translate(${x},${y})`)
}

export function translate(selection, x, y) {
    selection.attr('transform', `translate(${x},${y})`)
}

export function translate_with_duration(selection, x, y, t) {
    selection.transition().ease(d3.easeLinear).duration(t).attr('transform', `translate(${x},${y})`)

}

export function translate_to_point(selection, point, t) {
    var x = point[0]
    var y = point[1]
    selection.transition().ease(d3.easeLinear).duration(t).attr('transform', `translate(${x},${y})`)

}

export function move_circle_to_point(selection, p, t) {
    selection.transition().ease(d3.easeLinear).duration(t).attr('cx', p[0]).attr('cy', p[1])
}

export function change(selection, atr, val) {
    selection.attr(atr, val)
}


//rundom number generation  for specific intervals
export function sereis_gen(n, t) {
    d3.range(n).forEach(function (i) {
        setTimeout(() => {
            var val = i + 1
            console.log(i)
        }, 500 * i)
    })

}


export default add_circle;
//export {add_circle,,add_circle_white,add_simple_line,move_on_path,back_on_path,add_rect,add_text} ;
//
//
//
//
