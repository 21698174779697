import * as d3 from 'd3'
import * as fn from './simple_funs.js'

export default class Chart {

	constructor(g, rect, totalDuration) {
		this.outerg = g
		this.rect = rect
		this.innerg = g.append('g')
		this.margin = 20
		this.totalDuration = totalDuration
		this.progressBarHeight = 20
		this.fontSize = 20


	}

	getSampleData() {
		const yValues = this.data.map(item => item.y);
		this.startValue = Math.min(...yValues);
		this.endValue = Math.max(...yValues);
	}

	addStartText() {
		var y = this.innerRectHeight + 30
		this.startText = this.innerg.append('text')
		this.startText
			.attr('x', -8)
			.attr('y', y)
			.attr('fill', 'black')
			.attr('font-size', this.fontSize)
			.style('font-weight', 'bold')
			.text(this.startValue)
	}

	addEndText() {
		var y = this.innerRectHeight + 30
		var x = this.innerRectWidth - 25
		this.endText = this.innerg.append('text')
		this.endText
			.attr('x', x)
			.attr('y', y)
			.attr('fill', 'black')
			.attr('font-size', this.fontSize)
			.style('font-weight', 'bold')
			.text(this.endValue)


	}

	hideBackgroundRect() {
		this.rect
			.attr('height', 0).attr('width', 0)
	}
	hideInnerRect() {

		this.innerRect.attr('height', 0).attr('width', 0)
	}
	getBackgrondRectLocation() {
		const x = this.rect.attr('x')
		const y = this.rect.attr('y')

		this.backgroundRectX = (x === null) ? 0 : parseFloat(x)
		this.backgroundRectY = (y === null) ? 0 : parseFloat(y)

		this.backgroundRect = [this.backgroundRectX, this.backgroundRectY]

	}

	getBackgrondRectInfo() {
		const h = this.rect.attr('height')
		const w = this.rect.attr('width')
		this.backgrondRecWidth = (w === null) ? 0 : parseFloat(w)
		this.backgrondRecHeight = (h === null) ? 0 : parseFloat(h)
		console.log(h, w)

		this.rectFillColor = this.rect.attr('fill')

	}

	placeInnergToCorrectLocation() {
		const dx = this.margin / 2
		const dy = this.margin / 2
		const x = this.backgroundRectX + dx
		const y = this.backgroundRectY + dy
		this.innerg.call(fn.translate, x, y)

	}

	addInnerRect() {
		const w = this.backgrondRecWidth - this.margin
		const h = this.progressBarHeight
		this.innerRectWidth = w
		this.innerRectHeight = h
		this.innerRect = fn.add_rect(this.innerg, 0, 0, w, h).attr('fill', 'pink')

	}



	addMargin() {
		this.innerg.call(fn.translate, this.margin, this.margin)
	}



	adjustMargin() {
		this.innerg.call(fn.translate, this.margin, this.margin)
		//this.g.call(fn.translate,this.x,this.y)


	}

	getRandomData() {
		this.data = d3.range(this.dataPoints).map(d => (d3.randomInt(10, 50)())).map((d, i) => ({ 'x': i, 'y': d }))
		console.log(this.data)

	}

	get_data() {

		this.data = [
			{ 'x': 1, 'y': 2 },
			{ 'x': 2, 'y': 4 },
			{ 'x': 3, 'y': 5 },
			{ 'x': 4, 'y': 6 },
			{ 'x': 5, 'y': 10 },
			{ 'x': 6, 'y': 12 },
			{ 'x': 7, 'y': 17 },
			{ 'x': 8, 'y': 20 }
		]

	}

	addProgressBar() {
		this.progressBar = this.innerg.append('rect')
			.attr('width', this.innerRectWidth)
			.attr('height', this.innerRectHeight)
			.attr('fill', 'black')
	}

	addCountUpText() {
		var y = +this.progressBarHeight / 2 + 10
		this.countUpTxt = this.innerg.append('text')
			.attr('y', y).attr('x', 0)
			.text('0%')
			.attr('fill', 'black')
			.attr('font-size', this.fontSize)
	}

	animateProgressBar() {
		this.countUpTxt.attr('opacity', 1)
		this.progressBar
			.attr('width', 0)
			.transition()
			.ease(d3.easeLinear)
			.duration(this.totalDuration)
			.attr('width', this.innerRectWidth)
			.call(this.moveText, this.innerRectWidth, this.countUpTxt, this.progressBar);
	}

	moveText(transition, width, countUpTxt, progressBar) {
		var n = d3.interpolate(0, 2018);
		var r = d3.interpolate(0, width);

		transition.tween("progress", function () {
			return function (t) {
				var val = d3.format('.0f')(n(t));
				var p = progressBar.node().getPointAtLength(r(t));
				countUpTxt.text(val + ' millions').attr('x', p.x + 230)
			};
		});
	}




	wrapper(f, t, a) {
		return new Promise((res, rej) => {
			f.call(this, t, a)
			setTimeout(() => { res('done') }, t)
		})
	}


	async init() {

	}

	async seq() {
		var remainTime = this.totalDuration - 130
		await this.wrapper(this.getBackgrondRectLocation, 10)
		await this.wrapper(this.addMargin, 10)
		await this.wrapper(this.placeInnergToCorrectLocation, 10)
		await this.wrapper(this.getBackgrondRectInfo, 10)
		await this.wrapper(this.addInnerRect, 10)
		await this.wrapper(this.hideBackgroundRect, 10)
		await this.wrapper(this.hideInnerRect, 10)
		await this.wrapper(this.addProgressBar, 10)
		await this.wrapper(this.extract, 10)
		//await this.wrapper(this.addCountUpText, 10)
		//await this.wrapper(this.get_data, 10)
		//await this.wrapper(this.getSampleData, 10)
		//await this.wrapper(this.addStartText, 10)
		//await this.wrapper(this.addEndText, 10)
		await this.wrapper(this.animateProgressBar, remainTime)

	}

	async reseq() {

		await this.wrapper(this.animateProgressBar, this.totalDuration)
	}

	stop() {

		this.progressBar.interrupt()
	}

	changeDuration(value) {
		this.totalDuration = value
	}

	reset() {
		this.progressBar.attr('width', 0)
		this.countUpTxt.attr('opacity', 0)
	}

	extract() {
		this.countUpTxt = this.outerg.select('#countTxt').select('tspan')
		this.countUpTxt.attr('font-size', '30px')
	}
}

