export default function Description() {
    return( 
        <div>  
            <h1 style={{ color: 'black',marginTop: '20px', textAlign: 'left', marginLeft: '35px'}}>Australia Heat Change</h1>   
            <p style={{ marginLeft: '35px', marginRight: '35px', textAlign: 'left' }}>
                The average annual temperature in Australia is around 22°C. The highest 
                temperature averages 28.16°C in Cairns. The lowest temperature averages 9.67°C in 
                Melbourne. Cairns in warm weather year-round. Perth in warm climate with mild winters. 
                Brisbane's climate brings hot and pleasant winters. Sydney in warm and mild winters, while 
                Melbourne offers cooler winters.
            </p>  
        </div>     
    )
}