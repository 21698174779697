import * as d3 from 'd3'
import * as fn from './vt_gen_functions/simple_funs.js'
import * as fn_txt from './vt_gen_functions/text_functions.js'
import Rect from './Rectangle.js'
import Text from './Text.js'
import csvFile from './data.csv'


export default class Vis {

    constructor(svg, duration, div) {
        this.div = div
        this.svg = svg
        this.outerg = this.svg.select('#g_info').append('g')
        this.rect = this.svg.select('#g_info').select('rect')
        this.g = this.outerg.append('g')
        this.margin = 50
        this.totalDuration = duration
        this.countryColWidthMultiplier = 4
        this.currencyColWidthMultplier = 4
        this.dateRowHeightMultiplier = 2
        this.barWidth = 0
        this.barHeight = 0
        this.runState = true
        this.runStateForDetails = true
        this.timeoutID1 = []
        this.runStateForAll = true
    }

    fitToScreen(){
        const height = '75vh'
        const width = '100vw'
        const div = this.div
        const svg = this.svg
    
        div.style('height', height).style('width', width)
        svg.select('rect').remove()
    
        const outer_svg = div.append('svg').attr('id', 'outer_svg')
    
        outer_svg
          .style("height", height)
          .style("width", width);
    
        svg
          .attr("width", '100%')
          .attr("height", '100%');
    
        const g_bkg = svg.select('#g_bkg');
        const rect = g_bkg.select('rect')
        const g_svg = svg.select('#g_svg');
        const g_main = svg.select('#g_main');
    
        outer_svg.append(p => g_bkg.node())
        outer_svg.append(p => g_svg.node())
        svg.append(p => g_main.node())
        g_svg.append(p => svg.node())
        
    
        rect
          .style("height", height)
          .style("width", width);
      }

    changeDuration(value) {
        this.totalDuration = value
    }

    stopAll(){
        this.runStateForAll = false
        this.stop()
      }

    stop() {
        if(this.timeoutID){
            this.timeoutID.forEach(id => clearTimeout(id))
        }
        if(this.timeoutID1){
            this.timeoutID1.forEach(id => clearTimeout(id))
        }       
        this.runState = false
        this.runStateForDetails = false
    }

    async reset() {
        await this.wrapper(this.stop, 10000)
        this.g_extractRect.selectAll('path').attr('opacity', 0)
        this.g_extractTexts.selectAll('text').attr('opacity', 0)
        this.g_details.selectAll('path').attr('opacity', 0)
        this.g_details.selectAll('tspan').attr('opacity', 0)
        
        this.runState = true
        this.g_extractRect.selectAll('path').attr('opacity', 0)
        this.g_extractTexts.selectAll('text').attr('opacity', 0)
        this.g_details.selectAll('path').attr('opacity', 0)
        this.g_details.selectAll('tspan').attr('opacity', 0)
    }

    async rerun() {
        await this.wrapper(this.reset, 10000)
        await this.wrapper(this.move, this.totalDuration + 1000)
        if(this.runStateForAll){
            this.rerun()
          }
    }

    extract() {
        this.g_extractRect = this.svg.select('#g_percentageGroup')
        this.g_extractTexts = this.svg.select('#g_precentageTexts')
        this.g_details = this.svg.select('#labels')
        this.makeDetailsArray()
        this.title = this.svg.select('#g_header').select('text').select('tspan').text()

    }

    hideAll(){
        this.g_extractRect.selectAll('path').attr('opacity', 0)
        this.g_extractTexts.selectAll('text').attr('opacity', 0)
    }

    async move() {
        await this.wrapper(this.dividedIntoClasses, 10)
        await this.wrapper(this.animateColumnsRandomly, this.totalDuration, 0)
    }

    makeDetailsArray() {
        this.detailsArray = []
        for (let i = 1; i <= 6; i++) {
            var item = this.g_details.select('#label_g' + i)
            item.selectAll('path').attr('opacity', 0)
            item.selectAll('tspan').attr('opacity', 0)
            this.detailsArray.push(item)
        }
    }

    showDetails(duration) {
        var timeForOne = duration / 6
        var timeoutIDs = this.timeoutID1

        this.detailsArray.forEach((g, i) => {
            const timeoutID = setTimeout(() => {
                var paths = g.selectAll('path')
                paths.transition().duration(timeForOne).attr('opacity', 1)
                var text = g.select('text').select('tspan')
                var innerText = fn_txt.cleanText(text.text())
                text.attr('opacity', 1).text(innerText)
                this.typeTextObject = fn_txt.typeTextWithDuration(timeForOne, text)
            }, timeForOne * i);
            timeoutIDs.push(timeoutID);
        })
    }

    dividedIntoClasses() {
        var n = 1
        for (let i = 0; i < 36; i++) {
            for (let j = 0; j < 9; j++) {
                var rect = this.g_extractRect.select('#rect-' + n)
                rect.attr('class', 'col' + i)
                n += 1
            }
        }
    }

    async animateColumnsRandomly(duration, i) {
        var durationForOneColumn = duration / 36
        this.timeoutID = []
        if (i < 36 && this.runState) {
            var rects = this.g_extractRect.selectAll('.col' + i)
            await this.wrapper(this.animateOneColumnRandomly, durationForOneColumn, rects)
            i += 1
            this.animateColumnsRandomly(duration, i)
        }
    }

    animateOneColumnRandomly(durationForOneColumn, rects) {
        var timeoutIDs = this.timeoutID
        var duration = (durationForOneColumn - 450) / 9
        var g_extractTexts = this.g_extractTexts
        var shuffledRects = rects.nodes().sort(function () {
            return Math.random() - 0.5;
        });
        shuffledRects.forEach(function (rect, index) {
            const timeoutID = setTimeout(() => {
                var id = d3.select(rect).attr('id').split("-")[1]
                var text = g_extractTexts.select('#text' + id)
                text
                    .transition()
                    .duration(duration)
                    .attr("opacity", 1);

                d3.select(rect)
                    .transition()
                    .duration(duration)
                    .attr("opacity", 1);

            }, 50 * index);
            timeoutIDs.push(timeoutID);
        });

    }

    async animateTextsRandomly(duration) {
        this.g_extractTexts.each(
            function (i) {
                var t = d3.randomInt(1, duration)
                d3.select(this)
                    .transition()
                    .duration(t)
                    .attr("opacity", 1);
            })
    }

    hideRectangle() {
        this.rect.attr('opacity', 0)
    }

    getRectangelInfo() {
        this.x = parseFloat(this.rect.attr('x'))
        this.y = parseFloat(this.rect.attr('y'))
    }

    adjustOuterG() {
        this.outerg.call(fn.translate, this.x, this.y)
    }

    adjustMargin() {
        this.g.call(fn.translate, this.margin, this.margin)
    }

    hideTitle() {
        this.svg.select("#Title1").select('tspan').attr('opacity', 0)
        this.svg.select("#Title2").select('tspan').attr('opacity', 0)
        this.svg.select("#TitleRect1").attr('opacity', 0)
        this.svg.select("#TitleRect2").attr('opacity', 0)
    }

    getTitleBackgroundBox(time, i) {
        this.titleBackgroundBox = this.svg.select('#TitleRect' + i)

    }

    captureTitleText(time, i) {
        this.txtEle = this.svg.select('#Title' + i).select('tspan')
        this.txtInner = this.svg.select('#Title' + i).select('tspan').text()
    }

    calculateTitleLocation() {
        this.titlex = this.titleBackgroundBox.attr('x')
        this.titley = +this.titleBackgroundBox.attr('y') + 10
    }

    async displayHeader(headerDisplayDuration, i) {
        this.titleLines = 2

        var displayDurationForLine = (headerDisplayDuration - 30) / 2
        if (i <= this.titleLines) {
            await this.wrapper(this.getTitleBackgroundBox, 10, i)
            await this.wrapper(this.captureTitleText, 10, i)
            await this.wrapper(this.calculateTitleLocation, 10)
            await this.wrapper(this.typeOneTitleLine, displayDurationForLine)
            i += 1
            await this.displayHeader(headerDisplayDuration, i)
        }

    }

    typeOneTitleLine(headerDisplayDuration) {

        var newTxt = ' ';
        var txt = this.txtInner

        var list = [...txt];
        var durationForOneLetter = headerDisplayDuration / list.length
        this.txtEle.attr('x', this.titlex).attr('y', this.titley).text('').attr('opacity', 1)
        list.forEach((i, j) => {

            setTimeout(() => {
                newTxt = newTxt + i;
                this.txtEle.text(newTxt);
            }, durationForOneLetter * j)
        })
    }

    async bindData() {
        const data = await d3.csv(csvFile)
        data.forEach((row) => {

            row.Country = row.Country;
            row.Currency = row.Currency;
            row.Date = row.Date;
            row.Exchange_Rates = parseFloat(row.Exchange_Rates);
        });
        this.data = data

    }

    getMinMaxValues(data, targetCountry) {
        const minMaxValues = []
        this.uniqueCountries.forEach(country => {
            const countryData = data.filter(row => row.Country === targetCountry);
            const exchangeRates = countryData.map(row => parseFloat(row.Exchange_Rates));
            const minExchangeRate = d3.min(exchangeRates);
            const maxExchangeRate = d3.max(exchangeRates);
            minMaxValues.push([minExchangeRate, maxExchangeRate])
        })
        console.log(minMaxValues)
    }

    transformData() {
        this.countries = this.data.map((row) => row.Country)
        this.currencies = this.data.map((row) => row.Currency)
        this.dates = this.data.map((row) => row.Date)
        this.percentages = this.data.map((row) => row.Exchange_Rates)
    }

    getDataLength() {
        this.length = this.data.length
    }

    getCountryCount() {
        var uniqueCountriesSet = new Set(this.countries)
        this.numberOfCountries = uniqueCountriesSet.size
        this.uniqueCountries = Array.from(uniqueCountriesSet)
    }

    getDates() {
        var uniqueDatesSet = new Set(this.dates)
        this.numberOfDates = uniqueDatesSet.size
        this.uniqueDates = Array.from(uniqueDatesSet)
        this.uniqueDates.sort((a, b) => new Date(a) - new Date(b));

    }

    getCurruncies() {
        var uniqueCurrenciesSet = new Set(this.currencies)
        this.numberOfCurrencies = uniqueCurrenciesSet.size
        this.uniqueCurrencies = Array.from(uniqueCurrenciesSet)


    }

    getPrecentages() {
        this.percentageList = [];

        this.uniqueDates.forEach(date => {
            this.uniqueCountries.forEach(country => {
                const row = this.data.find(row => row.Date === date && row.Country === country);
                const percentage = row ? parseFloat(row.Exchange_Rates) : 0;

                this.percentageList.push({
                    country: country,
                    date: date,
                    percentage: percentage
                });
            });
        });

    }

    calculateMaxMinValues() {

        this.numberOfColumns = this.countryColWidthMultiplier + this.currencyColWidthMultplier + this.numberOfDates;
        this.numberOfRows = this.numberOfCountries + this.dateRowHeightMultiplier;
        this.maxHeight = parseFloat(this.rect.attr('height')) - this.margin * 2;
        this.maxWidth = parseFloat(this.rect.attr('width')) - this.margin * 2;
        const minSize = Math.min(this.maxWidth / this.numberOfColumns, this.maxHeight / this.numberOfRows);
        this.barWidth = this.barHeight = minSize;

    }

    centerHeatMap() {
        if (this.maxWidth / this.numberOfColumns < this.maxHeight / this.numberOfRows) {
            const margin = (this.numberOfRows * (this.maxHeight / this.numberOfRows - this.barWidth)) / 2
            this.g.call(fn.translate, 0, margin)
        }
        else {
            const margin = (this.numberOfColumns * (this.maxWidth / this.numberOfColumns - this.barWidth)) / 2
            this.g.call(fn.translate, margin, 0)
        }
    }

    addScale() {

        var height = this.maxHeight;
        var width = this.maxWidth;

        var ymin = 0;
        var ymax = this.numberOfRows;

        var xmax = this.numberOfColumns;


        this.xscale = d3.scaleLinear()
            .domain([0, xmax])
            .range([0, width]).nice();

        this.yscale = d3.scaleLinear()
            .domain([ymin, ymax])
            .range([0, height])
            .nice();


        // Add the X and Y axes
        this.gX = this.g.append('g')
            .call(d3.axisBottom(this.xscale))
            .call(fn.translate, 0, this.maxHeight + 5);

        this.gY = this.g.append('g')
            .call(d3.axisLeft(this.yscale.nice()))
            .call(fn.translate, -5, 0);
    }

    hideAxis() {
        this.gX.attr('opacity', 0)
        this.gY.attr('opacity', 0)
    }



    addCountryRect() {
        this.countryGroup = this.g.append('g').attr('id', 'countryRects')
        var x = this.xscale(0)
        var y = this.yscale(0)
        this.countryWidth = this.xscale(this.countryColWidthMultiplier)
        this.countryHeight = this.yscale(this.dateRowHeightMultiplier)
        var countryHead = new Rect(this.svg, this.countryGroup, 0, 0, 0, x, y, this.countryWidth, this.countryHeight)
        countryHead.addRect()
        var i = 1
        y += this.countryHeight
        this.uniqueCountries.forEach((country) => {

            var countryRow = new Rect(this.svg, this.countryGroup, 0, i, 0, x, y, this.countryWidth, this.barHeight)
            countryRow.addRect()
            y += this.barHeight
            i += 1

        })
    }

    addCountryText() {
        this.countryTextGroup = this.g.append('g').attr('id', 'countryTexts')

        var countriesHeader = new Text(this.svg, this.countryTextGroup, this.countryGroup, "Country", '0', 0, 0)
        countriesHeader.headingSeq()
        var i = 1

        this.uniqueCountries.forEach((country) => {

            var countryText = new Text(this.svg, this.countryTextGroup, this.countryGroup, country, '0', i, 0)
            countryText.headingSeq()
            i += 1

        })
    }

    addCurrencyRect() {
        this.currencyGroup = this.g.append('g').attr('id', 'currencyRects')
        var x = this.xscale(this.countryColWidthMultiplier)
        var y = this.yscale(0)

        this.currencyWidth = this.xscale(this.currencyColWidthMultplier)
        this.currencyHeight = this.yscale(this.dateRowHeightMultiplier)
        var currencyyHead = new Rect(this.svg, this.currencyGroup, 1, 0, 0, x, y, this.currencyWidth, this.currencyHeight)
        currencyyHead.addRect()
        var i = 1
        y += this.currencyHeight
        this.uniqueCurrencies.forEach((currency) => {

            var currencyRow = new Rect(this.svg, this.currencyGroup, 1, i, 0, x, y, this.currencyWidth, this.barHeight)
            currencyRow.addRect()
            y += this.barHeight
            i += 1

        })
    }

    addCurrencyText() {
        this.currencyTextGroup = this.g.append('g').attr('id', 'currencyTexts')

        var currenciesHeader = new Text(this.svg, this.currencyTextGroup, this.currencyGroup, "Currency", '1', 0, 0)
        currenciesHeader.headingSeq()
        var i = 1

        this.uniqueCurrencies.forEach((currency) => {

            var currencyText = new Text(this.svg, this.currencyTextGroup, this.currencyGroup, currency, '1', i, 0)
            currencyText.headingSeq()
            i += 1

        })
    }

    addBackDateRect() {
        this.dateBackGroup = this.g.append('g').attr('id', 'dateBackRects')
        var x = this.xscale(this.countryColWidthMultiplier + this.currencyColWidthMultplier)
        var y = this.yscale(0)

        this.dateHeight = this.yscale(this.dateRowHeightMultiplier)
        this.dateWidth = this.barWidth
        var i = 3

        this.uniqueDates.forEach((date) => {

            var dateRow = new Rect(this.svg, this.dateBackGroup, i, 1, 0, x, y, this.dateWidth, this.dateHeight)
            dateRow.addRect()
            x += this.barWidth
            i += 1

        })
    }

    addDateRect(time, dateColumnX, i) {

        var x = dateColumnX
        var y = this.yscale(0)

        var dateWidth = this.barWidth
        var dateHeight = this.yscale(this.dateRowHeightMultiplier)
        var col = i + 3
        var dateRect = new Rect(this.svg, this.datesGroup, col, '0', 0, x, y, dateWidth, dateHeight)
        dateRect.addRect()

    }

    addDateTextForBuild() {
        this.dateTextGroup = fn.add_g(this.g, "dateTexts")//this.g.append('g').attr('id', 'currencyTexts')
        var i = 3

        this.uniqueDates.forEach((currency) => {

            var currencyText = new Text(this.svg, this.dateTextGroup, this.dateBackGroup, currency, i, 1, 90)
            currencyText.dateSeq()
            i += 1

        })
    }

    addPercentageRect() {
        var j = 0
        var widthConst = this.countryColWidthMultiplier + this.currencyColWidthMultplier
        var heightConst = this.dateRowHeightMultiplier
        this.percentageGroup = fn.add_g(this.g, 'percentageGroup')
        for (let n = 0; n < this.numberOfDates; n++) {
            for (let i = 0; i < this.numberOfCountries; i++) {
                var x = this.xscale(widthConst) + n * this.barWidth
                var y = this.yscale(heightConst) + i * this.barHeight
                var col = n + 3
                var row = i + 1
                if (j < this.percentageList.length) {
                    var value = this.percentageList[j].percentage;
                    j += 1;
                }
                var percentageRect = new Rect(this.svg, this.percentageGroup, col, row, value, x, y, this.barWidth, this.barHeight)
                percentageRect.seq()
            }
        }
    }


    addPercentageAnimateRect() {
        var j = 0
        var widthConst = this.countryColWidthMultiplier + this.currencyColWidthMultplier
        var heightConst = this.dateRowHeightMultiplier
        this.percentageGroup = this.g.append('g').attr('id', 'precentageRects')
        for (let n = 0; n < this.numberOfDates; n++) {
            for (let i = 0; i < this.numberOfCountries; i++) {
                var x = this.xscale(widthConst) + n * this.barWidth
                var y = this.yscale(heightConst) + i * this.barHeight
                var col = n + 3
                var row = i + 1
                if (j < this.percentageList.length) {
                    var value = this.percentageList[j].percentage;
                    j += 1;
                }
                var percentageRect = new Rect(this.svg, this.percentageGroup, col, row, value, x, y, this.barWidth, this.barHeight)
                percentageRect.seq()
            }
        }

    }

    addPercentageText() {
        var j = 0
        this.percentageTextGroup = fn.add_g(this.g, 'precentageTexts')//this.g.append('g').attr('id', 'precentageRects')
        for (let n = 0; n < this.numberOfDates; n++) {
            for (let i = 0; i < this.numberOfCountries; i++) {
                var col = n + 3
                var row = i + 1
                if (j < this.percentageList.length) {
                    var value = this.percentageList[j].percentage;
                    j += 1;
                }
                var percentageText = new Text(this.svg, this.percentageTextGroup, this.percentageGroup, value, col, row, 0)
                percentageText.seq()
            }
        }
    }

    animatePercentageText(time, i) {
        this.percentageTextGroup = this.g.append('g').attr('id', 'percentageTexts');
        var row = 1;
        var col = i + 3;

        while (row <= this.numberOfCountries) {
            var text = this.percentageList[this.listCount].percentage;
            var percentageText = new Text(this.svg, this.percentageTextGroup, this.percentageGroup, text, col, row, 0);
            percentageText.seq();
            this.listCount += 1
            row += 1
        }
    }

    animateColumn(time, i) {
        var classNum = i + 3
        var classSelector = '.class' + classNum;

        var rectClass = this.percentageGroup.selectAll(classSelector);
        rectClass.call(fn.anim, time, 'width', this.barWidth)
    }


    addDateText(time, i) {
        var text = this.uniqueDates[i]
        var col = 3 + i
        var dateText = new Text(this.svg, this.dateTextGroup, this.datesGroup, text, col, '0', 90)
        dateText.dateSeq(time)
    }

    async animateDate(time, i) {
        if (i === 0) {
            if (this.flag) {
                return;
            }
            else {
                this.dateX = this.xscale(this.countryColWidthMultiplier + this.currencyColWidthMultplier)
                this.datesGroup = this.g.append('g').attr('id', 'dateRects')
                this.dateTextGroup = this.g.append('g').attr('id', 'dateTexts')
                this.listCount = 0
            }
        }

        if (i < this.numberOfDates) {
            if (this.flag) {
                return;
            }
            else {
                await this.wrapper(this.addDateRect, 0, this.dateX, i)
                await this.wrapper(this.addDateText, 0, i)
                i += 1
                this.dateX += this.barWidth
                await this.animateDate(0, i)
            }
        }
    }

    async animate(totalDuration, i) {
        var remainTime = (totalDuration - 400)
        if (i < this.numberOfDates) {
            if (this.flag) {
                return;
            }
            else {
                await this.wrapper(this.animateColumn, remainTime, i)
                this.wrapper(this.animatePercentageText, 100, i)
                i += 1
                this.dateX += 1
                await this.animate(totalDuration, i)
            }
        }
    }

    wrapper(f, t, a, c) {
        return new Promise((res, rej) => {
            f.call(this, t, a, c)
            setTimeout(() => { res('done') }, t)
        })
    }

    async seq() {
        await this.wrapper(this.extract, 100)
        await this.wrapper(this.hideAll, 10)
        await this.wrapper(this.move, this.totalDuration + 1000)
        if(this.runStateForAll){
            this.rerun()
          }
    }

    async build() {
        await this.wrapper(this.hideAll, 10)
        await this.wrapper(this.bindData, 10)
        await this.wrapper(this.transformData, 10)
        await this.wrapper(this.getDataLength, 10)
        await this.wrapper(this.getRectangelInfo, 10)
        await this.wrapper(this.adjustOuterG, 10)
        await this.wrapper(this.adjustMargin, 10)
        await this.wrapper(this.getCountryCount, 10)
        await this.wrapper(this.getDates, 10)
        await this.wrapper(this.getCurruncies, 10)
        await this.wrapper(this.getPrecentages, 10)
        await this.wrapper(this.calculateMaxMinValues, 10)
        //await this.wrapper(this.centerHeatMap, 10)
        await this.wrapper(this.addScale, 10)
        await this.wrapper(this.hideAxis, 10)
        await this.wrapper(this.addCountryRect, 10)
        await this.wrapper(this.addCurrencyRect, 10)
        await this.wrapper(this.addCountryText, 10)
        await this.wrapper(this.addCurrencyText, 10)
        await this.wrapper(this.addBackDateRect, 10)
        await this.wrapper(this.addDateTextForBuild, 10)
        await this.wrapper(this.addPercentageRect, 100)
        await this.wrapper(this.addPercentageText, 10)
    }

   
}