import * as React from 'react';
import PropTypes from 'prop-types';
import {
    Container, Box, Grid, Skeleton
} from '@mui/material';


const ListLoader = ({ number }) => {
    return (
        <Grid container spacing={2}>
            {[...Array(number)].map((_, index) => (
                <Grid item xs={12} md={6} lg={3} key={index}>
                    <Box sx={{ pt: 0.5 }}>
                        <Skeleton variant="rectangular" height={240} />
                        <Skeleton />
                        <Skeleton width="60%" />
                    </Box>
                </Grid>
            ))}
        </Grid>
    );
}

export default ListLoader;