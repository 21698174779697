import React from "react";
import HomePageChart from "./HomePageChart";

function TestHomePageAnimation() {
  return (
    <div>
      <HomePageChart/>
    </div>
  );
}

export default TestHomePageAnimation;
