import React from 'react';
import {
    Container, Box, Stack, Typography, Grid, Chip, Card,
    CardActions,
    CardContent,
    CardMedia,
} from '@mui/material';
import MainLayout from './Layouts/MainLayout';

import { Link } from 'react-router-dom';

import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import RedditIcon from '@mui/icons-material/Reddit';
import PinterestIcon from '@mui/icons-material/Pinterest';
import logoImg from './images/white-logo.png';
import tiktokImg from './images/tiktok-black.png';
import xImg from './images/x-black.png';
import threadsImg from './images/threads-black.png';

function Contact() {
    return (
        <div className='contact'>
            <MainLayout>
                <Container disableGutters maxWidth={false}>
                    <Stack spacing={2} mt={8} mb={8}>
                        <Box sx={{ width: { xs: '95%', md: '90%', lg: '70%' }, margin: 'auto !important' }}>
                            <Typography variant="h1" className="headerxl" gutterBottom>
                                Let's connect.
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="h2" className="header-3" gutterBottom>
                                Together, let's create something amazing!
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="body" className="body-text" gutterBottom>
                                Reach out to us on info@datagraphy.studio
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="body" className="body-text" gutterBottom>
                                Checkout our social media channels
                            </Typography>
                        </Box>
                    </Stack>

                    <Grid item lg={4} md={6} xs={12}>
                        <Stack direction="row" spacing={{lg: 2, md: 1}} sx={{ alignItems: 'center', justifyContent: 'center', mt: { xs: 2, sm: 2, md: 2, lg: 0 } }}>
                            <a className='body-link' href="https://www.youtube.com/channel/UCtdot1Qj3PdymFTgJ50oaig"><YouTubeIcon /></a>
                            <a className='body-link' href="https://web.facebook.com/profile.php?id=61558191435562"> <FacebookIcon /> </a>
                            <a className='body-link' href="https://twitter.com/datagraphyS"><img src={xImg} className='footer-social-img' /></a>
                            <a className='body-link' href="https://www.instagram.com/datagraphystudio/"><InstagramIcon /></a>
                            <a className='body-link' href="https://www.tiktok.com/@datagraphy.studio"> <img src={tiktokImg} className='footer-social-img' /></a>
                            <a className='body-link' href="https://www.linkedin.com/in/datagraphy-studio-764158283/"> <LinkedInIcon /> </a>
                            <a className='body-link' href="https://www.reddit.com/user/datagraphy_studio/"><RedditIcon /></a>
                            <a className='body-link' href="https://www.pinterest.com/datagraphystudio/_created/"><PinterestIcon /></a>
                            <a className='body-link' href="https://www.threads.net/@datagraphy_studio"><img src={threadsImg} className='footer-social-img' /></a>
                        </Stack>
                    </Grid>

                </Container>
            </MainLayout>
        </div>
    )
}

export default Contact;