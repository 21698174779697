import { useEffect, useRef } from "react";
import BuildPage from "./BuildPage.js";
import DesignPage from "./DesignPage.js";
import FinalPage from "./FinalPage.js";
import ThumbnailPage from "./ThumbnailPage.js";

export default function ChinaIndiaPopulation(props) {

	if(props.value == 0) {
		return (
			<section>
			{
				<ThumbnailPage/>
			}
			</section>
		)
	} else {
		return (
			<section>
			{
			  <FinalPage/>
			}
			</section>
		);
	}
}
