export default function Description() {
    return( 
        <div>  
            <h1 style={{ color: 'black',marginTop: '20px', textAlign: 'left', marginLeft: '35px'}}>WeWork Drop</h1>   
            <p style={{ marginLeft: '35px', marginRight: '35px', textAlign: 'left' }}>
            WeWork, the company that once revolutionized the workspace with its trendy coworking spaces, has fallen from grace. In
            November 2023, the company filed for bankruptcy, marking a dramatic end to its meteoric rise and subsequent struggles. 
            WeWork's downfall stemmed from several factors. Firstly, its business model, heavily reliant on rapid expansion and 
            long-term leases, proved unsustainable. Despite boasting a massive global footprint, the company never achieved 
            profitability. Secondly, investors grew increasingly skeptical of WeWork's financial health and the leadership practices of 
            its founder, Adam Neumann. This skepticism culminated in the disastrous failure of the company's highly anticipated IPO in 
            2019. Finally, the COVID-19 pandemic delivered a final blow. With the shift towards remote work, the demand for physical 
            office space plummeted, further crippling WeWork's core business. Although WeWork has emerged from bankruptcy through 
            restructuring, its story serves as a stark reminder of the risks associated with rapid growth without a sustainable 
            foundation. The company's fall highlights the importance of sound financial strategies, responsible leadership, and 
            adapting to changing market conditions in today's dynamic business landscape.
            </p>  
        </div>     
    )
}