import * as d3 from 'd3'
import * as fn from './simple_funs.js'


export default class Text {
    constructor(svg, g, rectGroup, text, col, row, rotation) {
        this.svg = svg
        this.g = g
        this.rectGroup = rectGroup
        this.row = row
        this.id = col + '' + row
        this.rotation = rotation
        this.text = text
        this.fontSize = 14
        this.textClass = this.row

    }

    addTextEle() {
        this.txtEle = this.g.append('text')
        this.txtEle
            .attr('id', 'text' + this.id).attr('class', 'class' + this.textClass)
            .attr('x', this.x).attr('y', this.y).attr('font-size', this.fontSize).attr('fill', 'black')

    }

    addTextLocation() {
        const rect = this.rectGroup.select('#rect' + this.id)
        this.rectWidth = rect.attr('width') / 2
        this.rectHeight = rect.attr('height') / 2
        this.getTextWidthHeight(this.text)
        

        this.x = +rect.attr('x') + this.rectWidth - this.textWidth / 2
        this.y = +rect.attr('y') + this.rectHeight + this.textHeight / 2
    }

    centerDateText(){
        this.y += 3
        this.x -= 9
    }

    getTextWidthHeight(text) {
        const textElement = this.g.append("text")
            .attr("x", 0)
            .attr("y", 0)
            .attr('font-size', this.fontSize)
            .text(text);
        this.textWidth = textElement.node().getBBox().width
        this.textHeight = textElement.node().getBBox().height
        textElement.remove()
    }


    addText() {
        this.txtEle.text(this.text)
    }

    boldText() {
        if (this.row === 0) {
            this.txtEle.style('font-weight', 'bold')
        }
    }

    hideText() {
        this.txtEle.attr('opacity', 0)
    }

    showText() {
        this.txtEle.attr('opacity', 1)
    }

    rotateText() {
        var rotationX = this.x + this.textWidth / 2 + 10 ;
        var rotationY = this.y - this.textHeight / 2 ;
        this.txtEle.attr("transform", `rotate(90 ${rotationX},${rotationY})`);
    }



    typeText(textTypeDuration) {
        var txt = this.text
        var newTxt = ''
        var list = [...txt];
        var durationForOneLetter = textTypeDuration / list.length

        list.forEach((i, j) => {

            setTimeout(() => {
                newTxt = newTxt + i;
                this.txtEle.text(newTxt);
            }, durationForOneLetter * j)
        })
    }

    wrapper(f, t, a) {
        return new Promise((res, rej) => {
            f.call(this, t, a)
            setTimeout(() => { res('done') }, t)
        })
    }

    async seq() {

    }

    async headingSeq() {
        await this.wrapper(this.addTextLocation, 100)
        await this.wrapper(this.addTextEle, 100)
        await this.wrapper(this.addText, 100)
        await this.wrapper(this.boldText, 100)


    }

    async dateSeq(dateTypeDuration) {
        var remainTime = dateTypeDuration - 40
        this.fontSize = 16
        
        await this.wrapper(this.addTextLocation, 10)
        await this.wrapper(this.addTextEle, 10)
        await this.wrapper(this.centerDateText, 10)
        await this.wrapper(this.rotateText, 10)
        await this.wrapper(this.boldText, 10)
        await this.wrapper(this.typeText, remainTime)

    }

    async seq() {
        await this.wrapper(this.addTextLocation, 10)
        await this.wrapper(this.addTextEle, 10)
        await this.wrapper(this.addText, 10)

    }
}