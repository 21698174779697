// import * as fn from "../simple_funs";
import * as d3 from "d3";
import * as fn from "./vt_gen_functions/simple_funs.js";
import * as fn_txt from "./vt_gen_functions/text_functions.js";
import Chart from "./Progressbar/Chart.js";

export default class Vis {
  constructor(svg, duration, div) {
    this.svg = svg;
    // this is wrong this should take as g
    this.g_chart = this.svg.select("#g_chart");
    this.g = this.svg.select("#g_info");
    this.g_header = this.svg.select('#g_header')
    this.g_description = this.svg.select('#g_description')
    this.rect = null;
    this.start = [100, 0];
    this.end = [500, 0];
    this.attr = [];
    this.totalDuration = duration
    this.div = div
    this.runState = true
    this.numberOfProvinces = 9
    this.runStateForAll = true
    this.initiate()
  }

  build() {
    var g = fn.add_g(this.g_chart, "progressbar")
    var rect = fn.add_rect(g, 200, 20, 500, 100)

    var chart = new Chart(g, rect, this.totalDuration)
    chart.build()
  }

  fitToScreen(){
    const height = '75vh'
    const width = '100vw'
    const div = this.div
    const svg = this.svg

    div.style('height', height).style('width', width)
    svg.select('rect').remove()

    const outer_svg = div.append('svg').attr('id', 'outer_svg')

    outer_svg
      .style("height", height)
      .style("width", width);

    svg
      .attr("width", '100%')
      .attr("height", '100%');

    const g_bkg = svg.select('#g_bkg');
    const rect = g_bkg.select('rect')
    const g_svg = svg.select('#g_svg');
    const g_main = svg.select('#g_main');

    outer_svg.append(p => g_bkg.node())
    outer_svg.append(p => g_svg.node())
    svg.append(p => g_main.node())
    g_svg.append(p => svg.node())
    

    rect
      .style("height", height)
      .style("width", width);
  }

  async move() {
    var duration = (this.totalDuration - 2500)/2
    await this.wrapper(this.typeHeader, 2000);
    await this.wrapper(this.showMapOutline, 500);
    await this.wrapper(this.animatePointers, duration);
    if (this.runState) {
      this.chart.animateProgressBar(duration)
      this.animateTimeline(duration)
      this.countDeaths(duration)
      this.changeOpacity(duration)
      this.animateBarChart(duration)
    }
  }

  showMapOutline() {
    this.g_mapOutline
      .transition()
      .duration(500)
      .attr('opacity', 1)
  }

  animateTimeline(duration) {
    var timeForOnePhase = duration / this.numberOfProvinces
    d3.range(8).forEach((i) => {
      const timeoutID = setTimeout(() => {
        var g = this.g_chart.select('#phase' + (i + 1))
        var text = g.select('#text' + (i + 1)).select('tspan')
        g.transition().duration(timeForOnePhase).attr('opacity', 1)
        this.timelineTextObj = fn_txt.typeTextWithDuration(timeForOnePhase, text)
      }, timeForOnePhase * i);
      this.timeoutIDs.push(timeoutID);

    })
  }

  getBarChartRectWidth() {
    this.rectWidthArray = []
    for (var i = 1; i <= 9; i++) {
      var width = this.barChartRect.select('#frontRect' + i).attr('width')
      this.rectWidthArray.push(width)
      this.barChartRect.select('#frontRect' + i).attr('width', 0)
    }
    console.log(this.rectWidthArray)
  }

  animateBarChart(duration) {
    for (var i = 0; i < 9; i++) {
      this.barChartRect.select('#frontRect' + (i + 1))
        .attr('opacity', 1)
        .transition()
        .ease(d3.easeLinear)
        .duration(duration)
        .attr('width', this.rectWidthArray[i])
    }
  }

  initiate() {
    var txtEle = fn.add_text(this.g, '', 0, 0)
    txtEle.attr('opacity', 0)
    this.countingObj = fn_txt.countUp(0, 0, 0, '');
    this.timeoutIDs = []
    this.timelineTextObj = fn_txt.typeTextWithDuration(0, txtEle)
    this.pointerTextObj = fn_txt.typeTextWithDuration(0, txtEle)
  }

  hideAll(){
    this.totalDeaths.text('0')
    this.pointersPaths.attr('opacity', 0)
    this.pointersTexts.attr('opacity', 0)
    this.mapGroups.attr('opacity', 0)
    this.g_mapOutline.attr('opacity', 0)
    this.barChartRect.selectAll('rect').attr('opacity', 0)
    this.g_chart.select('#barChart_backRect').selectAll('rect').attr('opacity', 0)
    this.chart.hideAll()
  }

  extract() {
    var g = this.g_chart.select('#g_progressbar')
    var rect = g.select('rect')
    rect.attr('opacity', 0)
    
    this.chart = new Chart(g, rect, this.totalDuration)
    this.chart.extract()

    var timeline = this.g_chart.select('#chart_data').selectAll('g')
    timeline.attr('opacity', 0)
    

    this.titleEle = this.g_header.select('#Title').select('tspan')
    this.title = this.titleEle.text()

    this.totalDeaths = this.g.select('#DeathNum').select('tspan')
    this.totalDeathNum = parseInt(this.totalDeaths.text().replace(/,/g, ''), 10) / 1000 - 1
    

    this.desc = this.g.select('#Description').selectAll("text");

    this.pointersPaths = this.g.select('#Pointers').selectAll('path')
    

    this.pointersTexts = this.g.select('#Pointers').selectAll('text')
    

    this.selectGroups()

    this.mapGroups = this.g.select('#BaseMap').selectAll('g')
    

    this.g_mapOutline = this.g.select('#BaseMapOutline')
    

    this.barChartRect = this.g_chart.select('#barChart_frontRect')
    
    this.getBarChartRectWidth()

    

    this.desc = this.g_description.select('text').selectAll('tspan')

  }

  typeHeader() {
    fn_txt.typeText(this.titleEle)
  }

  countDeaths(duration) {
    this.countingObj = fn_txt.countUp(duration, this.totalDeathNum, this.totalDeaths, " K");
  }

  changeOpacity(duration) {
    const gap = duration/ this.mapGroups.size();
    const totalDuration = this.totalDuration - 11000;
    const self = this;  // Store reference to 'this' to use inside the setTimeout function
    this.mapGroups.each(function (d, i) {
      const timeoutID1 = setTimeout(() => {
        const group = d3.select(this);
        var duration = totalDuration - gap * i;
        fn.anim(group, duration, 'opacity', 1);
      }, i * gap);  // Use bind to set the correct context for 'this'
      self.timeoutIDs.push(timeoutID1);
    });

  }

  selectGroups() {
    this.pointerGroups = [];

    d3.range(1, this.numberOfProvinces + 1).map((i) => {
      var pointer = this.g.select('#Pointers').select('#p' + i);
      this.pointerGroups.push(pointer)
    });
  }

  animatePointers(duration) {
    var timeForOnePointer = duration / this.numberOfProvinces
    this.timeoutIDs = [];  // Corrected variable name
    const self = this;  // Store reference to 'this' to use inside the setTimeout function
    this.pointerGroups.forEach((group, i) => {
      const timeoutID1 = setTimeout(() => {
        const path = group.select('#Line' + (i + 1)).select('path')
        const text = group.select('#Text' + (i + 1)).select('text')
        path.attr('opacity', 1)
        text.attr('opacity', 1)
        group.select('#circle' + (i + 1)).attr('opacity', 1)
        group.select('#pointer' + (i + 1)).attr('opacity', 1)
        fn.draw_path(path, timeForOnePointer);
        this.pointerTextObj = fn_txt.typeTextWithDuration(timeForOnePointer, text.select('tspan'))
      }, i * timeForOnePointer);
      self.timeoutIDs.push(timeoutID1);
    })


  }


  wrapper(f, t, a) {
    return new Promise((res, rej) => {
      f.call(this, t, a);
      setTimeout(() => {
        res("done");
      }, t);
    });
  }

  stopAll(){
    this.runStateForAll = false
    this.stop()
  }

  stop() {
    this.titleEle.select('tspan').interrupt()
    this.runState = false
    this.totalDeaths.interrupt();
    this.chart.stop()
    this.pointersPaths.interrupt()
    this.pointersTexts.interrupt()
    this.mapGroups.interrupt()
    this.g_chart.interrupt()
    this.countingObj.stopCount();
    this.timeoutIDs.forEach(id => clearTimeout(id));
    this.timelineTextObj.stop()
    this.pointerTextObj.stop()
    this.barChartRect.selectAll('rect').interrupt()
  }

  async reset() {
    await this.wrapper(this.stop, 10000);
    this.chart.reset()
    this.totalDeaths.text(0);
    this.pointersPaths.attr('opacity', 0)
    this.pointersTexts.attr('opacity', 0)
    this.mapGroups.attr('opacity', 0)
    this.g_mapOutline.attr('opacity', 0)
    this.g_chart.select('#chart_data').selectAll('g').attr('opacity', 0)
    this.barChartRect.selectAll('rect').attr('width', 0)
    this.g_chart.select('#chart_data').selectAll('g').attr('opacity', 0)
    this.runState = true
    this.g_chart.select('#chart_data').selectAll('g').attr('opacity', 0)

  }

  async rerun() {
    await this.wrapper(this.reset, 10000);
    await this.wrapper(this.move, this.totalDuration);
    if(this.runStateForAll){
      this.rerun()
    }
  }

  changeOpacityInput(value) {
    this.mapPaths.attr('opacity', value)
    this.pointersPaths.attr('opacity', value)
    this.pointersTexts.attr('opacity', value)
  }

  changeDuration(value) {
    this.totalDuration = value
  }

  async seq(){
    await this.wrapper(this.extract, 100);
    await this.wrapper(this.hideAll, 100);
    await this.wrapper(this.move, this.totalDuration);
    if(this.runStateForAll){
      this.rerun()
    }
  }
}
