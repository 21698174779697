import * as fn from './simple_funs.js'
import * as d3 from 'd3'

export function dot_up(x){
	console.log(x)
}

export 	function move_to_end(cricle,end,t){
		cricle.call(fn.move_circle_to_point_with_duration,end,t)
	}

export 	function move_along_the_path(cricle,path,t){
		cricle.call(fn.move_along_path_back,path,t)
	}

	
export 	function wrapper(f,a,b,t){ 
		return new Promise((res,rej)=>{ 
			f(a,b,t)
		setTimeout(()=>{ res('done') } , t ) }) 
	}

export 	 async  function seq(circle,end,t){
	//console.log(circle,end,path,t)
		await wrapper(move_to_end,circle,end,t)
	//	await wrapper(move_along_the_path,circle,path,t)
	}



