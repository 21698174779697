export default function Description() {
    return( 
        <div>  
            <h1 style={{ color: 'black',marginTop: '20px', textAlign: 'left', marginLeft: '35px'}}>Japan Covid</h1>   
            <p style={{ marginLeft: '35px', marginRight: '35px', textAlign: 'left' }}>
            The COVID-19 pandemic in Japan presented a multifaceted challenge to the nation, both in terms of public health and 
            socio-economic impacts. The first cases of COVID-19 in Japan were confirmed in January 2020, sparking immediate efforts to 
            contain the spread of the virus. Initially, Japan implemented measures such as travel restrictions, quarantine protocols, 
            and encouraging hygiene practices to mitigate transmission. However, as the pandemic progressed, Japan faced several waves 
            of infections, prompting the government to declare a state of emergency multiple times. These declarations led to 
            restrictions on public gatherings, the closure of non-essential businesses, and requests for citizens to stay home. 
            Despite these efforts, Japan faced criticism for its relatively low testing rates and perceived reluctance to impose strict 
            lockdown measures seen in other countries. The pandemic exacerbated existing challenges, including an aging population, 
            strained healthcare infrastructure, and economic downturns. Businesses, particularly in the tourism and hospitality sectors,
            suffered significant losses, and the government introduced stimulus packages to mitigate the financial impact on individuals
            and businesses. Japan's response to the pandemic also highlighted cultural factors influencing public behavior, such as 
            mask-wearing and social distancing, which were already ingrained in Japanese society. While these cultural practices helped 
            limit the spread of the virus to some extent, challenges remained in balancing public health concerns with economic 
            imperatives and managing the strain on healthcare resources. Overall, the Japan COVID-19 pandemic underscored the 
            complexities of responding to a global health crisis, requiring a delicate balance between protecting public health and 
            minimizing socio-economic disruptions. 
            </p>  
        </div>     
    )
}