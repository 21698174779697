import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../sass/styles.css';

function MainLayout({ children, title }) {
    return (
        <div>
            <Header />
            {children}
            <Footer />
        </div>
    )
}

export default MainLayout;