export function add_circle(g, x, y, r) {
  var cla = g
    .append("circle")
    .attr("cx", x)
    .attr("cy", y)
    .attr("r", r)
    .attr("fill", "black");
  return cla;
}

export function lineGetStart(ele) {
  const point = ele.node().getPointAtLength(0);
  return [point.x, point.y];
}

export function lineGetEnd(ele) {
  const l = ele.node().getTotalLength();
  const point = ele.node().getPointAtLength(l);
  return [point.x, point.y];
}
