import FinalPage from "./FinalPage.js";
import ThumbnailPage from "./ThumbnailPage.js";

export default function NvidiaGrowth(props) {

	if(props.value == 0) {
		return (
			<section>
			{
				<ThumbnailPage/>
			}
			</section>
		)
	} else {
		return (
			<section>
			{
			  <FinalPage/>
			}
			</section>
		);
	}
}
