export default function Description() {
    return( 
        <div>  
            <h1 style={{ color: 'black',marginTop: '20px', textAlign: 'left', marginLeft: '35px'}}>China's Economic Evolution: Annual Growth Across Time</h1>   
            <p style={{ marginLeft: '35px', marginRight: '35px', textAlign: 'left' }}>
            China's annual growth has been a beacon of economic marvel for decades, consistently showcasing robust expansion. Bolstered
            by ambitious infrastructure projects, technological innovation, and a burgeoning middle class, China's growth story has 
            captivated the world. With an intricate balance of state intervention and market forces, the nation has sustained impressive
            GDP growth rates, propelling it to the forefront of the global economy. However, in recent years, China has also been 
            navigating challenges such as trade tensions, demographic shifts, and environmental concerns, prompting a recalibration 
            of its growth strategies towards sustainability and quality-driven development. Nonetheless, China's annual growth continues
            to command attention as a vital barometer of global economic health and a testament to the country's enduring resilience and 
            dynamism.
            </p>  
        </div>     
    )
}