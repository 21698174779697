import * as d3 from 'd3';

export function add_circle(g, x, y, r) {
    return g.append('circle').attr('cx', x).attr('cy', y).attr('r', r).attr('fill', 'black');
}

export function add_mark(g, a) {
    const x = a[0];
    const y = a[1];
    const r = 10;

    return g.append('circle').attr('cx', x).attr('cy', y).attr('r', r).attr('fill', 'black');
}

const add_circle_white = function (g, x, y, r) {
    return g.append('circle').attr('cx', x).attr('cy', y).attr('r', r).attr('fill', 'black').attr('stroke', 'white');
};

export function move_along_path(selection, path, t) {
    selection.transition().duration(t).ease(d3.easeLinear).tween('planet_path', () => move_on_path(path));

}

export function move_along_path_back(selection, path, t) {
    selection.transition().duration(t).ease(d3.easeLinear).tween('planet_path', () => back_on_path(path));

}

export function rep_mov_cl(selection, path, t) {
    selection.transition().duration(t).ease(d3.easeLinear)
        .tween('planet_path', () => move_on_path_cl(path))
        .on('end', () => (selection.call(rep_mov_cl, path, t)));
}

export function rep_mov_back_on_cl(selection, path, t) {
    selection.transition().duration(t).ease(d3.easeLinear)
        .tween('planet_path', () => back_on_path(path))
        .on('end', () => (selection.call(rep_mov_back_on_cl, path, t)));
}

export function rep_mov_rect(selection, path, t) {
    selection
        .transition().duration(t)
        .ease(d3.easeLinear).tween('planet_path', () => move_on_path_rect(path))
        .on('end', () => (selection.call(rep_mov_rect, path, t)));
}

export function seq_rep_mov_cl(l, sl, n, t1, t2) {
    d3.range(n).forEach(function (i) {
        //const f=t1/n
        setTimeout(() => {
            const val = i + 1;
            //console.log(l[i])
            l[i].call(rep_mov_cl, sl, t2);
        }, t1 * i);
    });
}

//==========================================================================================================

export function move_on_path(p) {
    const l = p.node().getTotalLength();
    const r = d3.interpolate(0, l);

    return function (t) {
        const point = p.node().getPointAtLength(r(t));
        d3.select(this).attr('cx', point.x).attr('cy', point.y);
    };
}

export function move_on_path_cl(p) {
    const l = p.node().getTotalLength();
    const r = d3.interpolate(0, l);

    return function (t) {
        const point = p.node().getPointAtLength(r(t));
        d3.select(this).attr('cx', point.x).attr('cy', point.y);
    };
}

export function move_on_path_g(p) {
    const l = p.node().getTotalLength();
    const r = d3.interpolate(0, l);

    return function (t) {
        const point = p.node().getPointAtLength(r(t));
        d3.select(this).call(transform, point.x, point.y);
        //.attr('x',point.x).attr('y',point.y)
    };
}

export function move_on_path_rect(p) {
    const l = p.node().getTotalLength();
    const r = d3.interpolate(0, l);

    return function (t) {
        const point = p.node().getPointAtLength(r(t));
        d3.select(this).attr('x', point.x).attr('y', point.y);
    };
}

export function back_on_path(p) {
    const l = p.node().getTotalLength();
    const r = d3.interpolate(l, 0);

    return function (t) {
        const point = p.node().getPointAtLength(r(t));
        d3.select(this).attr('cx', point.x).attr('cy', point.y);
    };
}

export function add_simple_line(g, from, to) {
    let path = d3.path();
    path.moveTo(from[0], from[1]);
    path.lineTo(to[0], to[1]);

    return g.append('path').attr('stroke', 'black').attr('fill', 'black').attr('d', path).attr('shape-rendering', 'crispEdges');
}

function add_rect(g, p, w, h) {
    return g.append('rect').attr('width', w).attr('height', h).attr('fill', 'grey').attr('x', p[0]).attr('y', p[1]);
}

export function add_text(g, txt, x, y) {
    return g.append('text').text(txt).attr('x', x).attr('y', y);
}

export function get_txt_loc(ele) {
    return [parseInt(ele.attr('x')) + 10, parseInt(ele.attr('y')) + 30];
}

export function count_up(obj, n, t) {
    const f = t / n;

    d3.range(n).forEach(function (i) {
        setTimeout(() => {
            const val = i + 1;
            obj.text(val);
        }, f * i);
    });
}

export function count_inf_main(ele) {
    let ind = 0;

    function count_inf() {
        ind = ind + 1;
        ele.transition().ease(d3.easeLinear).duration(50).text(ind).on('end', count_inf);
    }

    ele.call(count_inf);
}

export function lineGetStart(ele) {
    const point = ele.node().getPointAtLength(0);

    return [point.x, point.y];
}

export function lineGetEnd(ele) {
    const l = ele.node().getTotalLength();
    const point = ele.node().getPointAtLength(l);

    return [point.x, point.y];
}

export function getTotalLength(path) {
    return path.node().getTotalLength();
}

export function add_line(g, from, to) {
    return g.append('line').attr('stroke', 'black')
        .attr('x1', from[0])
        .attr('y1', from[1])
        .attr('x2', to[0])
        .attr('y2', to[1])
        .attr('shape-rendering', 'auto')
        .attr('shape-rendering', 'crispEdges')
        .attr('shape-rendering', 'geometricPrecision');
}

export function draw_path(selection, t) {
    const l = selection.node().getTotalLength();

    selection.attr('stroke-dasharray', l + '  ' + l).attr('stroke-dashoffset', l)
        .transition().duration(t).ease(d3.easeLinear)
        .attr('stroke-dashoffset', 0);
}

export function make_white(selection) {
    const l = selection.node().getTotalLength();
    selection.attr('stroke-dasharray', l + '  ' + l).attr('stroke-dashoffset', l);
}

export function anim(selection, t, attr, v) {
    selection.transition().ease(d3.easeLinear).duration(t).attr(attr, v);
}

//----------------------------------------------------------------------------------------------------

export function transform(selection, x, y) {
    selection.attr('transform', `translate(${x},${y})`);
}

export function translate(selection, x, y) {
    selection.attr('transform', `translate(${x},${y})`);
}

export function translate_with_duration(selection, x, y, t) {
    selection.transition().ease(d3.easeLinear).duration(t).attr('transform', `translate(${x},${y})`);
}

export function translate_to_point(selection, point, t) {
    const x = point[0];
    const y = point[1];

    selection.transition().ease(d3.easeLinear).duration(t).attr('transform', `translate(${x},${y})`);
}

export function move_circle_to_point(selection, p, t) {
    selection.transition().ease(d3.easeLinear).duration(t).attr('cx', p[0]).attr('cy', p[1]);
}

export function change(selection, atr, val) {
    selection.attr(atr, val);
}

//random number generation  for specific intervals
export function sereis_gen(n, t) {
    d3.range(n).forEach(function (i) {
        setTimeout(() => {
            const val = i + 1;
            console.log(i);
        }, 500 * i);
    });
}

export default add_circle;

export const wrapper = ms =>
    new Promise(res => {
        setTimeout(res, ms);
    });