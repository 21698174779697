import * as d3 from 'd3'
import * as fn from '../vt_gen_functions/simple_funs'
import csvFile from './data.csv'

export default class Chart {

	constructor(g, rect, duration) {
		this.outerg = g
		this.rect = rect
		this.innerg = g.append('g')
		this.margin = 25
		this.totalDuration = duration
		this.progressBarHeight = 20
		this.fontSize = 30
		this.progressBarColour = 'black'
		this.runState = true

	}

	async bindData(){
		const data = await d3.csv(csvFile);
		this.data = data.map((d, i) => ({ "x": i + 1, "y": Number(d["y"]) }));
	}

	getSampleData() {
		const yValues = this.data.map(item => item.y);
		this.startValue = Math.min(...yValues);
		this.endValue = Math.max(...yValues);
	}

	addStartText() {
		var y = this.innerRectHeight + 30
		this.startText = this.innerg.append('text')
		this.startText
			.attr('id','startText')
			.attr('x', -8)
			.attr('y', y)
			.attr('fill', 'black')
			.attr('font-size', this.fontSize)
			.style('font-weight', 'bold')
			.text(this.startValue)
	}

	addEndText() {
		var y = this.innerRectHeight + 30
		var x = this.innerRectWidth - 25
		this.endText = this.innerg.append('text')
		this.endText
			.attr('id','endText')
			.attr('x', x)
			.attr('y', y)
			.attr('fill', 'black')
			.attr('font-size', this.fontSize)
			.style('font-weight', 'bold')
			.text(this.endValue)


	}

	hideBackgroundRect() {
		this.rect
			.attr('opacity', 0)
	}
	hideInnerRect() {
		this.innerRect.attr('opacity', 0)
	}
	getBackgrondRectLocation() {
		const x = this.rect.attr('x')
		const y = this.rect.attr('y')

		this.backgroundRectX = (x === null) ? 0 : parseFloat(x)
		this.backgroundRectY = (y === null) ? 0 : parseFloat(y)

		this.backgroundRect = [this.backgroundRectX, this.backgroundRectY]

	}

	getBackgrondRectInfo() {
		const h = this.rect.attr('height')
		const w = this.rect.attr('width')
		this.backgrondRecWidth = (w === null) ? 0 : parseFloat(w)
		this.backgrondRecHeight = (h === null) ? 0 : parseFloat(h)
		this.rectFillColor = this.rect.attr('fill')

	}

	placeInnergToCorrectLocation() {
		const dx = this.margin / 2
		const dy = this.margin / 2
		const x = this.backgroundRectX + dx
		const y = this.backgroundRectY + dy
		this.innerg.call(fn.translate, x, y)

	}

	addInnerRect() {
		const w = this.backgrondRecWidth - this.margin
		const h = this.progressBarHeight
		this.innerRectWidth = w
		this.innerRectHeight = h
		this.innerRect = fn.add_rect(this.innerg, 0, 0, w, h).attr('fill', 'pink')

	}



	addMargin() {
		this.innerg.call(fn.translate, this.margin, this.margin)
	}



	adjustMargin() {
		this.innerg.call(fn.translate, this.margin, this.margin)
		//this.g.call(fn.translate,this.x,this.y)


	}

	getRandomData() {
		this.data = d3.range(this.dataPoints).map(d => (d3.randomInt(10, 50)())).map((d, i) => ({ 'x': i, 'y': d }))
		console.log(this.data)

	}

	get_data() {

		this.data = [
			{ 'x': 1, 'y': 2 },
			{ 'x': 2, 'y': 4 },
			{ 'x': 3, 'y': 5 },
			{ 'x': 4, 'y': 6 },
			{ 'x': 5, 'y': 10 },
			{ 'x': 6, 'y': 12 },
			{ 'x': 7, 'y': 17 },
			{ 'x': 8, 'y': 20 }
		]

	}

	addProgressBar() {
		this.progressBar = this.innerg.append('rect')
			.attr('id','progressbar')
			.attr('width', this.innerRectWidth)
			.attr('height', this.innerRectHeight)
			.attr('fill', this.progressBarColour)
	}

	addCountUpText() {
		var y = +this.progressBarHeight / 2 + 10
		this.countUpTxt = this.innerg.append('text')
			.attr('id','contuptext')
			.attr('y', -(this.progressBarHeight / 4)).attr('x', 0)
			.text('0%')
			.attr('fill', 'black')
			.attr('font-size', this.fontSize)
	}

	animateProgressBar(time) {
		this.progressBar.attr('opacity', 1)
		this.progressBar
			.attr('width', 0)
			.transition()
			.ease(d3.easeLinear)
			.duration(time)
			.attr('width', this.innerRectWidth)
		
			//.call(this.moveText, this.countUpTxt, this.progressBarHeight)
			
	}

	moveText(selection, object, progressBarHeight) {
		selection.tween('chng', function () {

			//const objectY = -(progressBarHeight / 4)
			const interpolate = d3.interpolateNumber(0, 100);
			const objectX = parseFloat(object.attr('x'))
			return function (t) {				
				const x = parseFloat(d3.select(this).attr('width'))
				object.attr('x', x + objectX - 40 ).text(Math.round(interpolate(t)) + "%")//.attr('y', objectY)
			}

		})

	}

	wrapper(f, t, a) {
		return new Promise((res, rej) => {
			f.call(this, t, a)
			setTimeout(() => { res('done') }, t)
		})
	}

	async seq() {
		await this.wrapper(this.getBackgrondRectLocation, 10)
		await this.wrapper(this.addMargin, 10)
		await this.wrapper(this.placeInnergToCorrectLocation, 10)
		await this.wrapper(this.getBackgrondRectInfo, 10)
		await this.wrapper(this.addInnerRect, 10)
		await this.wrapper(this.hideBackgroundRect, 10)
		await this.wrapper(this.hideInnerRect, 10)
		await this.wrapper(this.addProgressBar, 10)
		await this.wrapper(this.addCountUpText, 10)
		await this.wrapper(this.get_data, 10)
		await this.wrapper(this.getSampleData, 10)
		await this.wrapper(this.addStartText, 10)
		await this.wrapper(this.addEndText, 10)
		await this.wrapper(this.animateProgressBar, this.totalDuration)

	}

	async build(){
		await this.wrapper(this.getBackgrondRectLocation, 10)
		await this.wrapper(this.addMargin, 10)
		await this.wrapper(this.placeInnergToCorrectLocation, 10)
		await this.wrapper(this.getBackgrondRectInfo, 10)
		await this.wrapper(this.addInnerRect, 10)
		await this.wrapper(this.hideBackgroundRect, 10)
		await this.wrapper(this.hideInnerRect, 10)
		await this.wrapper(this.addProgressBar, 10)
		//await this.wrapper(this.addCountUpText, 10)
		//await this.wrapper(this.get_data, 10)
		await this.wrapper(this.bindData, 10)
		await this.wrapper(this.getSampleData, 10)
		await this.wrapper(this.addStartText, 10)
		await this.wrapper(this.addEndText, 10)
	}

	extract(){
		this.progressBar = this.outerg.select('#progressbar')
		this.innerRectWidth = this.progressBar.attr('width')	
	}

	hideAll(){
		this.progressBar.attr('width',0)
	}

	async run(){
		await this.wrapper(this.getBackgrondRectLocation, 10)
		await this.wrapper(this.addMargin, 10)
		await this.wrapper(this.placeInnergToCorrectLocation, 10)
		await this.wrapper(this.getBackgrondRectInfo, 10)
		await this.wrapper(this.addInnerRect, 10)
		await this.wrapper(this.hideBackgroundRect, 10)
		await this.wrapper(this.hideInnerRect, 10)
		await this.wrapper(this.animateProgressBar, this.totalDuration)
	}

	async rerun(){
		this.runState = true
		await this.wrapper(this.animateProgressBar,this.totalDuration)
	}

	stop(){
		this.runState = false
		this.progressBar.interrupt()
		
	}

	changeDuration(value){
		this.totalDuration = value
	}

	reset(){
		this.hideAll()
		this.progressBar.attr('width',0)
		//this.countUpTxt.text('0%').attr('x',this.countUptxtX)
	}
}

