export default function Description() {
    return( 
        <div>  
            <h1 style={{ color: 'black',marginTop: '20px', textAlign: 'left', marginLeft: '35px'}}>The Journey of COVID-19: From Start to Finish</h1>   
            <p style={{ marginLeft: '35px', marginRight: '35px', textAlign: 'left' }}>
            In late 2019, a novel coronavirus emerged in the city of Wuhan, China, marking the beginning of a global health crisis that would soon reshape the way we live, work,
 and interact with one another. This virus, later named COVID-19, quickly spread across borders, triggering unprecedented measures to curb its transmission and
 mitigate its impact on public health.As COVID-19 swept through communities worldwide, governments implemented various strategies to contain the virus, including lockdowns, travel restrictions, and
 widespread testing. Healthcare systems faced immense strain as hospitals braced for surges in cases, while frontline workers heroically battled the virus on the
 front lines.Amidst the chaos, scientists raced to develop vaccines to provide immunity against COVID-19. In record time, multiple vaccines were developed, tested, and 
authorized for emergency use, offering hope for a brighter future. Vaccination campaigns were launched globally, aiming to inoculate populations and achieve herd
 immunity against the virus.Throughout the pandemic, individuals and communities demonstrated resilience, solidarity, and adaptability in the face of adversity. Social distancing, mask-wearing,
 and hand hygiene became commonplace practices to limit the virus's spread, while virtual communication and remote work emerged as essential tools for staying
 connected.
 As time passed and vaccination efforts intensified, the tide began to turn against COVID-19. Cases declined, and the burden on healthcare systems eased, signaling
 a gradual return to normalcy. Restrictions were gradually lifted, allowing businesses to reopen, schools to resume in-person learning, and social gatherings to resume.However, the journey towards the end of COVID-19 was not without challenges. New variants of the virus emerged, posing threats to vaccine effectiveness and 
necessitating ongoing vigilance and adaptation. Efforts to ensure equitable access to vaccines and combat vaccine hesitancy remained critical in achieving 
widespread immunity and preventing future outbreaks.Ultimately, the end of COVID-19 will be marked by a collective effort to overcome the pandemic's challenges, rebuild communities, and learn from the lessons it has taught us. While the journey may have been arduous, the resilience and solidarity demonstrated throughout the crisis have laid the foundation for a more resilient and interconnected world.As we look towards the future, let us remember the sacrifices made, the lessons learned, and the strength found in unity. Together, we can overcome adversity and emerge stronger, more compassionate, and more prepared to face whatever challenges lie ahead.            </p>  
        </div>     
    )
}