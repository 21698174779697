export default function Description() {
    return( 
        <div>  
            <h1 style={{ color: 'black',marginTop: '20px', textAlign: 'left', marginLeft: '35px'}}>Tiktok User Growth</h1>   
            <p style={{ marginLeft: '35px', marginRight: '35px', textAlign: 'left' }}>
                TikTok's journey has been one of meteoric rise, captivating users with its short-
                form video format. <br></br><br></br>
                Viral Takeoff (2018-2020): 
                <br></br>
                A surge unlike any other: Global users exploded by a 
                staggering 1,157.76%, driven by entertainment-hungry audiences during the 
                pandemic. The US market witnessed an even more dramatic rise, with a 
                1,239.29% user increase. This rapid growth was fueled by catchy trends, creator 
                diversity, and an algorithm expertly curating personalized feeds.
                <br></br><br></br>
                Shifting Gears (2021-Present):
                <br></br>
                While growth remains significant, with an 18.6% average annual increase 
                globally, the pace has slowed compared to the early years. Estimated 1.5 billion 
                monthly active users as of January 2024, with projections reaching 2 billion by 
                year-end. The US market exceeded 150 million users, representing nearly half 
                the population. The focus is shifting towards deeper engagement and 
                monetization strategies, with features like live commerce and brand 
                partnerships gaining traction.
                <br></br><br></br>
                Emerging Landscape:
                <br></br>
                Competition intensifies with similar offerings like Instagram Reels and YouTube 
                Shorts vying for user attention. User growth is expected to continue, but at a 
                more moderate pace, demanding strategic innovation from TikTok. 
                <br></br><br></br>
                Key Takeaways:
                <br></br>
                TikTok's explosive growth has established it as a social media powerhouse, 
                particularly among younger generations. The platform is transitioning from user 
                acquisition to engagement and monetization, facing competition from 
                established players. While its future growth trajectory remains to be seen, its 
                impact on the social media landscape is undeniable. 
            </p>  
        </div>     
    )
}