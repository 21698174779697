import React from 'react';
import { Container, Typography, Box, Stack } from '@mui/material';
import MainLayout from './Layouts/MainLayout';

function TermsOfService() {
    return (
        <MainLayout>
            {/* Terms of Service Page */}
            <Container disableGutters maxWidth={false} sx={{ mt: 8, mb: 8 }}>
                <Box sx={{ width: { xs: '95%', md: '90%', lg: '70%' }, margin: 'auto' }}>
                    <Stack spacing={3} sx={{ textAlign:'left' }}>

                        {/* Title Section */}
                        <Typography variant="h1"  className="header-large" gutterBottom>
                            Terms of Service
                        </Typography>
                        <Typography variant="body1">
                            Effective Date: September 2024
                        </Typography>

                        {/* Section 1 - Acceptance of Terms */}
                        <Typography variant="h2" className="header-3" gutterBottom>
                            1. Acceptance of Terms
                        </Typography>
                        <Typography variant="body1">
                            By accessing or using any part of our website or services, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service. If you do not agree with any part of these terms, please do not use our website or services.
                        </Typography>

                        {/* Section 2 - Services Provided */}
                        <Typography variant="h2" className="header-3" gutterBottom>
                            2. Services Provided
                        </Typography>
                        <Typography variant="body1">
                            Datagraphy Studio provides a platform where users can access data visualizations, purchase premium content, and request customized content. All content, including free and paid visualizations, is provided for informational purposes only and is subject to the terms outlined below.
                        </Typography>

                        {/* Section 3 - User Accounts */}
                        <Typography variant="h2" className="header-3" gutterBottom>
                            3. User Accounts
                        </Typography>
                        <Typography variant="body1">
                            To access certain features of our website, such as purchasing content or subscribing to our newsletter, you may be required to create an account. You agree to provide accurate and current information and to maintain the security of your account. You are responsible for all activity that occurs under your account and must notify us immediately of any unauthorized use.
                        </Typography>

                        {/* Section 4 - Payments and Subscriptions */}
                        <Typography variant="h2" className="header-3" gutterBottom>
                            4. Payments and Subscriptions
                        </Typography>
                        <Typography variant="body1">
                            <strong>Purchases:</strong> When buying premium content from our website, you agree to provide accurate payment information. All payments are processed through a secure third-party payment gateway. We do not store any payment information.
                        </Typography>
                        <Typography variant="body1">
                            <strong>Subscriptions:</strong> If you sign up for any subscription services, you agree to the recurring charges as specified during the subscription process. You may cancel your subscription at any time, but no refunds will be provided for the current billing period.
                        </Typography>

                        {/* Section 5 - Intellectual Property */}
                        <Typography variant="h2" className="header-3" gutterBottom>
                            5. Intellectual Property
                        </Typography>
                        <Typography variant="body1">
                            All content on this website, including data visualizations, text, graphics, logos, and other materials, is the intellectual property of Datagraphy Studio and is protected by copyright, trademark, and other applicable laws. You may not reproduce, distribute, or create derivative works of any content without prior written permission.
                        </Typography>

                        {/* Section 6 - User Conduct */}
                        <Typography variant="h2" className="header-3" gutterBottom>
                            6. User Conduct
                        </Typography>
                        <Typography variant="body1">
                            You agree not to use our website or services for any illegal, harmful, or unauthorized activities, including but not limited to:
                        </Typography>
                        <ul>
                            <li><Typography variant="body1">Violating any laws or regulations.</Typography></li>
                            <li><Typography variant="body1">Hacking, attempting to gain unauthorized access, or otherwise disrupting the operation of the website.</Typography></li>
                            <li><Typography variant="body1">Using our content or services for commercial purposes without our consent.</Typography></li>
                            <li><Typography variant="body1">Posting or transmitting any harmful, defamatory, or inappropriate material.</Typography></li>
                        </ul>
                        <Typography variant="body1">
                            We reserve the right to terminate or suspend your account or access to our services if we determine, in our sole discretion, that you have violated these terms.
                        </Typography>

                        {/* Section 7 - Custom Content */}
                        <Typography variant="h2" className="header-3" gutterBottom>
                            7. Custom Content
                        </Typography>
                        <Typography variant="body1">
                            If you request customized content, the terms and scope of the project will be defined in a separate agreement between you and Datagraphy Studio. We retain ownership of any custom visualizations created, but you may be granted a license to use them as agreed upon in the custom project terms.
                        </Typography>

                        {/* Section 8 - Third-Party Links */}
                        <Typography variant="h2" className="header-3" gutterBottom>
                            8. Third-Party Links
                        </Typography>
                        <Typography variant="body1">
                            Our website may contain links to third-party websites. These links are provided for your convenience, but we do not endorse or assume any responsibility for the content or practices of those websites. You access such websites at your own risk.
                        </Typography>

                        {/* Section 9 - Disclaimers */}
                        <Typography variant="h2" className="header-3" gutterBottom>
                            9. Disclaimers
                        </Typography>
                        <Typography variant="body1">
                            <strong>Content Accuracy:</strong> We strive to provide accurate and up-to-date data visualizations and information, but we make no guarantees regarding the completeness, accuracy, or reliability of any content on our website.
                        </Typography>
                        <Typography variant="body1">
                            <strong>Service Availability:</strong> We do not guarantee that our website or services will always be available, error-free, or free of viruses or other harmful components.
                        </Typography>

                        {/* Section 10 - Limitation of Liability */}
                        <Typography variant="h2" className="header-3" gutterBottom>
                            10. Limitation of Liability
                        </Typography>
                        <Typography variant="body1">
                            To the fullest extent permitted by law, Datagraphy Studio and its affiliates shall not be liable for any indirect, incidental, special, or consequential damages arising from your use of our website or services, including but not limited to loss of data, revenue, or business opportunities.
                        </Typography>

                        {/* Section 11 - Indemnification */}
                        <Typography variant="h2" className="header-3" gutterBottom>
                            11. Indemnification
                        </Typography>
                        <Typography variant="body1">
                            You agree to indemnify and hold Datagraphy Studio, its affiliates, and its respective directors, employees, and agents harmless from any claims, damages, liabilities, or expenses arising from your use of the website or violation of these Terms of Service.
                        </Typography>

                        {/* Section 12 - Termination */}
                        <Typography variant="h2" className="header-3" gutterBottom>
                            12. Termination
                        </Typography>
                        <Typography variant="body1">
                            We reserve the right to terminate or suspend your access to our website and services at any time, without notice, for conduct that we believe violates these terms or is harmful to other users, us, or third parties.
                        </Typography>

                        {/* Section 13 - Governing Law */}
                        <Typography variant="h2" className="header-3" gutterBottom>
                            13. Governing Law
                        </Typography>
                        <Typography variant="body1">
                            These Terms of Service shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law principles. Any legal disputes arising from these terms shall be resolved in the courts of [Your Jurisdiction].
                        </Typography>

                        {/* Section 14 - Changes to Terms */}
                        <Typography variant="h2" className="header-3" gutterBottom>
                            14. Changes to Terms
                        </Typography>
                        <Typography variant="body1">
                            We reserve the right to update or modify these Terms of Service at any time. Any changes will be posted on this page, and your continued use of our website or services after such changes constitutes your acceptance of the revised terms.
                        </Typography>

                        {/* Section 15 - Contact Us */}
                        <Typography variant="h2" className="header-3" gutterBottom>
                            15. Contact Us
                        </Typography>
                        <Typography variant="body1">
                            If you have any questions or concerns regarding these Terms of Service, please contact us at:
                        </Typography>
                        <Typography variant="body1">
                            <strong>Datagraphy Studio</strong><br />
                            <a href="mailto:info@datagraphy.studio">info@datagraphy.studio</a>
                        </Typography>
                    </Stack>
                </Box>
            </Container>
        </MainLayout>
    );
}

export default TermsOfService;
